import {getUnrealizedStockGain} from "@/assets/js/portfolio_functions";

export function isPwa() {
    return ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export function upOrDown(val) {
    return (val > 0) ? 'up' : 'down';
}

/**
 *  Checks if an object is undefined or empty
 * @param obj
 * @returns {boolean} - returns true if object is undefined or has no keys
 */

export function isEmpty(obj){
    return !obj || !Object.keys(obj).length;
}

/**
 *  Checks if an array of objects has at least one empty object
 * @param objArray
 * @returns {boolean} - true if at least on object is empty
 */
export function anyEmpty(objArray){
    for (let obj of objArray){
        if (isEmpty(obj)) return true;
    }

    return false;
}


export  function getFirstObjValue(obj, sort){
    if (isEmpty(obj)) return null;
    let keys = Object.keys(obj);
    if (sort) keys.sort();
    let firstKey = keys[0];
    return obj[firstKey].total || obj[firstKey];
}

export function getLastObjKey(obj, sort){
    let keys = Object.keys(obj);
    if (sort) keys.sort();
    return keys.pop()

}

export  function getLastObjValue(obj, sort){
    if (isEmpty(obj)) return null;
    let lastKey = getLastObjKey(obj, sort);
    // console.log('GetLastObjValue',obj, sort);
    return obj[lastKey].total || obj[lastKey];
}

export function getLastDeal(allDeals, symbol){
    if (!allDeals || !allDeals[symbol]) return null;
    return getLastObjValue(allDeals[symbol], true);
}

export function getCurrentStockAmount(allDeals, symbol){
    let deal = getLastDeal(allDeals, symbol);
    if (!deal) return 0;
    return deal.finalAmount;
}

export function extractArrColumn(arr, column) {
    return arr.map(x => x[column])
}

export function getNthLastObjValue(obj, n){
    if (!obj) return null;
    let keys = Object.keys(obj).reverse();
    let key = keys[n];
    if (typeof obj[key] == 'undefined') return 0;
    return obj[key].total || obj[key];
}

/**
 *  Transform a period like 1W 3M 1Y etc to number of working days
 *  a week is 5 working days
 * @param period
 */
function getPeriodInDays(period){
    let days = {
        P1w: 5,
        P1m: 20,
        P3m: 60,
        P6m: 120,
        P1y: 240,
        P2y: 480
    }
    return days['P'+period];
}

/**
 *  Calculates the gain and loss percentage based on stock history prices and the period
 * @param stockData
 * @param period
 * @param currentPrice - current price of the stock
 * @returns {number}
 */
export function getStockPriceDifference(stockData, period, currentPrice){
    if (!stockData) return 0;
    let days = getPeriodInDays(period);
    let initialPrice = getNthLastObjValue(stockData, days);
    if (initialPrice==0) return 100;
    let priceDiff = currentPrice-initialPrice;
    let percentage = priceDiff * 100 / initialPrice;
    return percentage;
}

export function stringCompare(a,b){
    if(a < b) { return -1; }
    if(a > b) { return 1; }
    return 0;
}

export function getClosePrice(item) {
    if (!item) return 0;
    return item.close || item.iexClose || item.latestPrice || 0;
}

export function getCompanyLogo(symbols, symbol, url){
    if (!symbols || !symbol || !symbols[symbol]) return '';
    let imgPath = symbols[symbol].logo;
    if (!imgPath) return '';
    if ( imgPath != '') return url+'rest/'+imgPath;
    return '';
}

export function isLoggedIn() {
    if (!localStorage.user) return false;
    let user = JSON.parse(localStorage.user);
    if (isEmpty(user)) return false;
    if (user.uid && +user.anonymous) return false;
    return true;
}

export function hasUsername() {
    return localStorage.hasUsername == 'true';
}

export function hasAvatar() {
    return localStorage.hasAvatar == 'true';
}

export function isDateObj(dateObj){
    return !!dateObj && typeof dateObj === 'object' && typeof dateObj.getMonth === 'function';
}

/**
 *  Checks if string is a date object or is undefined and returns current DateTime for
 * @param string
 * @returns {Date|*}
 */
export function stringToDate(string){
    if (!string) return new Date();
    if (isDateObj(string)) return string;
    return new Date(string);
}

export function timeDifference(date_1, date_2){
    const date1 = stringToDate(date_1);
    const date2 = stringToDate(date_2);
    // console.log(date1, date2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return {time: diffTime, days: diffDays};
}

export function isToday(date){
    if (!date) return false;
    if (isDateObj(date)) return date.toDateString() == new Date().toDateString();
    return new Date(date).toDateString() == new Date().toDateString();
}

export function moneyRound(amount,digits){
    digits = digits ?? 2; // default 2 digits for money,
    let multiplier = Math.pow(10,digits);
    return Math.round(amount*multiplier)/multiplier;
}

export function avatarPath(host,filename){
    // return host +'images/avatars/' + filename;
    return '/images/avatars/' + filename;
}

export function adventureImagePath(host,filename){
    // return host +'images/adventure/' + filename;
    if (filename.includes('/images/')) return filename;
    return '/images/adventure/' + filename;
}

export function isInViewport(el) {
    if (!el) return false;
    let rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= window.innerHeight &&
        rect.right <= window.innerWidth
    );
}

/**
 *  Calculates unrealized P&L for a given stock based on all the trades data available and the currentStockPrice
 * @param stockDeals
 * @param currentStockPrice
 */
// export function calculateUnrealizedStockPL(stockDeals , currentStockPrice, endDate){
//
//     getUnrealizedStockGain(stockDeals, currentStockPrice, endDate);
//     let averageBuyPrice = 0;
//     let averageSellPrice = 0;
//     let totalBuyQuantity = 0;
//     let totalSellQuantity = 0;
//     for (let date in stockDeals){
//         let deal = stockDeals[date];
//         if (deal.dealType == 'sell'){
//             totalSellQuantity += deal.amount;
//             averageSellPrice += deal.amount*deal.price;
//         }
//         else{
//             totalBuyQuantity += deal.amount;
//             averageBuyPrice += deal.amount*deal.price;
//         }
//     }
//
//     averageBuyPrice = averageBuyPrice / totalBuyQuantity;
//
//     let unrealizedPL = (currentStockPrice - averageBuyPrice) * (totalBuyQuantity-totalSellQuantity);
//     return  unrealizedPL;
// }

/**
 *  Return total unrealized P&L for all deals in portfolio;
 * @param allDeals - all deals for all stock symbols
 * @param currentStockPrices - all current prices for stock in portfolio
 * @returns {number}
 */

export function getPortfolioUnrealizedPL(allDeals, currentStockPrices){
    let unrealizedPL = {total:0};
    if( isEmpty(currentStockPrices)) return unrealizedPL;
    for (let symbol in allDeals){
        if (!getCurrentStockAmount(allDeals, symbol)) continue; // If the currently owned amount of stock is zero we skip this symbol
        let currentPrice = getClosePrice(currentStockPrices[symbol]);
        let stockDeals = allDeals[symbol];
        unrealizedPL[symbol] = moneyRound( getUnrealizedStockGain(stockDeals, currentPrice) );
        unrealizedPL.total = unrealizedPL.total + unrealizedPL[symbol];

    }
    // console.log(unrealizedPL);
    return unrealizedPL;
}


/**
 *  Returns the previous day close trading time
 * @returns {string}
 */
export function getYesterdayCloseTime(){
    return getNdaysBackCloseTime(1);
}

function setDateToCloseTime(date){
    let d = date.split('T')[0];
    return d + 'T16:00:00';
}

/**
 *  Get the date n days back at time 16:00
 *  We set the given date to Close time because if the time is 00:00 there could be unexpected results because
 *  of time saving and we will get the wrong date in some instances
 *  @n - number of days;
 *  @date - string - the date from which to start counting back
 *  @returns {string}
 */
export function getNdaysBackCloseTime(n, date){
    let d = (date) ? new Date(setDateToCloseTime(date)+'.000Z') : new Date();
    if (n>0) {
        let diff = d.getDate() - n;
        // console.log(d.getDate(), n, diff);
        d.setDate(diff);
    }
    let prevDate = d.toISOString().split('T')[0];
    return prevDate + 'T16:00:00';
}


export  function secondsToDhm(ms) {
    let seconds = Math.floor(Number(ms)/1000);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);


    var dDisplay = d > 0 ? d + "d ": "";
    var hDisplay = h > 0 ? h + "h ": "";
    var mDisplay = m > 0 ? m + "min ": "";
    var sDisplay = s > 0 ? s + "s": "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}



/**
 *  Get the close price for the date provided if the date is not a trading day find the closest close price to the left.
 */
export function getPreviousClosePrice(date, stockPrices2y){
    if (isEmpty(stockPrices2y)) return 0;
    if (!date) return getLastObjValue(stockPrices2y);
    if (!date.split('T')[1]) date = setDateToCloseTime(date); // If date string does not have time component add T16:00
    if (stockPrices2y[date]) return stockPrices2y[date];
    let dates = Object.keys(stockPrices2y);
    let result = 0;
    for (let d of dates){
        if (date > d ) {
            result = stockPrices2y[d];
            continue;
        }
        if (d >= date) return result;
    }
    return result;
}

export function getPrevCloseTime(period, date){
    if (!period) return getNdaysBackCloseTime(0, date);
    switch (period){
        case 'day':
            return getNdaysBackCloseTime(1, date);
        case '1w':
            return getNdaysBackCloseTime(7, date);
        case '1m':
            return getNdaysBackCloseTime(30, date);
        case '3m':
            return getNdaysBackCloseTime(90, date);
        case '6m':
            return getNdaysBackCloseTime(180, date);
        case '1y':
            return getNdaysBackCloseTime(365, date);
        case '2y':
            return getNdaysBackCloseTime(730, date);
    }
    throw 'Invalid period! - period provided was ' + period;
}


/**
 *  Calculate unrealized stock gain for a period of days equivalent to calculateUnrealizedStockGain() method in backend
 * @param stockDeals - all deals for a single stock
 * @param endStockPrice - the current stock price
 * @param stockPrices2y - daily stock prices for 2 years
 * @param {number} period - number of days
 * @returns {number}
 */
export function calculateStockPL(stockDeals , endStockPrice, stockPrices2y, period, date){
    period = period || 'day';
    let startTime = getPrevCloseTime(period, date);
    let endDate = getNdaysBackCloseTime(0, date);
    let startClosePrice = getPreviousClosePrice(startTime, stockPrices2y);
    // console.log(startTime, endDate, startClosePrice);
    let startUnrealizedGain = getUnrealizedStockGain(stockDeals, startClosePrice, startTime);
    let endUnrealizedGain = getUnrealizedStockGain(stockDeals, endStockPrice, endDate);
    return  endUnrealizedGain - startUnrealizedGain;
}


export function getStockPL(allDeals, currentStockPrices, allStockPrices, period, symbol){
    let currentPrice = getClosePrice(currentStockPrices[symbol]);
    let stockDeals = allDeals[symbol];
    let stockPrices = allStockPrices[symbol];
    if (isEmpty(stockPrices)) return 0;
    let stockPrices2y = allStockPrices[symbol]['r2y'];
    if (isEmpty(stockPrices2y)) return 0;

    return moneyRound( calculateStockPL(stockDeals , currentPrice, stockPrices2y, period));
}


/**
 *  Return total unrealized P&L for all deals in portfolio since last day close date;
 * @param allDeals
 * @param currentStockPrices
 * @param allStockPrices
 * @returns {{total: number}}
 */
export function getPortfolioUnrealizedPeriodPL(allDeals, currentStockPrices, allStockPrices, period){
    let unrealizedDailyPL = {total:0};
    if( anyEmpty([currentStockPrices, allStockPrices])) return unrealizedDailyPL;
    for (let symbol in allDeals){
        if (!getCurrentStockAmount(allDeals, symbol)) continue; // If the currently owned amount of stock is zero we skip this symbol
        let currentPrice = getClosePrice(currentStockPrices[symbol]);
        let stockDeals = allDeals[symbol];
        let stockPrices = allStockPrices[symbol];
        if (isEmpty(stockPrices)) continue;
        let stockPrices2y = allStockPrices[symbol]['r2y'];
        if (isEmpty(stockPrices2y)) continue;
        unrealizedDailyPL[symbol] = moneyRound( calculateStockPL(stockDeals , currentPrice, stockPrices2y, period));
        unrealizedDailyPL.total = unrealizedDailyPL.total + unrealizedDailyPL[symbol];
    }
    return unrealizedDailyPL;
}

export function getPnlPercents(pnl,value){
    // console.log('getPnlPercents', pnl, value)
    if (pnl == value) return 0;
    return pnl/(value-pnl);
}


export function isPortfolioEmpty(allDeals){
    if (isEmpty(allDeals)) return true;
    for (let symbol in allDeals){
        if (getCurrentStockAmount(allDeals, symbol) > 0) return false;
    }
    return true;
}


export function addCurrentToHistory(historyArray, currentDate, currentValue){
    let result = {};
    currentDate = currentDate || new Date().toISOString();
    result.r1mm = {...historyArray.r1mm}; // Detaching the object from VUEX store
    result.r2y = {...historyArray.r2y};  // We do not want to modify the VUEX store when adding the current price because it changes
    if (currentValue) {
        result.r1mm[currentDate] = currentValue;
        result.r2y[currentDate] = currentValue;
    }
    return result;
}

export function getFlatDeals(allDeals){
    allDeals = allDeals ?? {};
    let flatDeals = {};
    let p_deals = allDeals.portfolio_deals ?? allDeals;
    let symbols = Object.keys(p_deals);
    // console.log(symbols);
    for ( let symbol of symbols){
        // console.log(allDeals[symbol]);
        flatDeals = {...flatDeals,...p_deals[symbol]};
    }
    let extraCash = allDeals.extra_cash ?? {};
    flatDeals = {...flatDeals,...extraCash};
    // console.log(flatDeals);
    return flatDeals;
}



export function resolveObj(path, obj) {
    return path.split('.').reduce(function(prev, curr) {
        return prev ? prev[curr] : null
    }, obj || self)
}

export function setObjPathValue(obj, path, value) {
    let i;
    path = path.split('.');
    for (i = 0; i < path.length - 1; i++) {
        let key = path[i];
        if (!obj[key]) obj[key] = {};
        obj = obj[key];
    }

    obj[path[i]] = value;
}

export function stockPricesMetaKey(symbol, range){
    return 'stock_prices.'+symbol+'.r'+range;
}


export async function onBoarding(vue){

    let runs = +localStorage.getItem("onboardingRun");
    let gaEventsMap = {
        0: (runs === 0) ? 'ONBRD_1st_start' : `ONBRD_${runs+1}_start`,
        2:'ONBRD_step3',
        4:'ONBRD_step5',
        6:'ONBRD_step7',
        16:'ONBRD_done',
    }

    if (!hasUsername() || !hasAvatar() || !vue.onboarding_active) return; // If the user does not have a username or avatar we don't start the onboarding
    if (localStorage.onboarding == 'active') return; // If onboarding is already running exit, we use local storage because we se the guide_bubble values after a timeout but we need this flag right away
    let key = vue.onboarding_status.findIndex((el)=>el==0);
    // console.log('Oboarding Key: ', key);
    if (key === 0){
        localStorage.onboardingRun = runs+1;
    }

    if (key in gaEventsMap){
        vue.$FirebaseAnalytics.logEvent({name: gaEventsMap[key]});
    }

    if (key == -1) {
        localStorage.onboarding = false;
        return;
    }
    let step = vue.onboarding[key];
    // console.log( step );
    localStorage.onboarding = 'active';
    setTimeout(async ()=>{
        if (step.routeName != vue.$route.name) {
            console.log('Wrong route, expecting: ', step.routeName, 'but current route name is', vue.$route.name);
            // localStorage.onboarding = false;
            await vue.$router.push({name:step.routeName});
            // return;
            await sleep(1000);
        }
        vue.setGuideBubble(step);
    }, step.delay);
}

// detect when scrolled to bottom once per page load;
export function scrollToBottom(vue, gaEventName){
    let touchedBottom = false;

    window.onscroll = function(ev) {
        if (touchedBottom) return;
        let totalPageHeight = document.body.scrollHeight;
        let scrollPoint = window.scrollY + window.innerHeight;
        if(scrollPoint >= totalPageHeight)
        {
            vue.$FirebaseAnalytics.logEvent({name: gaEventName});
            // console.log('Function touched bottom', gaEventName);
            touchedBottom = 1;
        }
    };
}


export function getPushTitles(push_key) {
    let push_titles = {
        status_change_msg: 'Status changes',
        trading_opening:   'Trading opens',
        trading_closes:    'Trading closes',
        ranking_change:    'Ranking changes',
        too_much_cach:     'Too much stale cash',
        low_activity:      'Low activity',
        big_stock_change:  'Big stock change',
    };

    return push_titles[push_key] ?? push_key ?? '';
}

export function getPushTitleMsg(push_key) {
    let push_titles = {
        status_change_msg: 'about status changes',
        trading_opening:   'when trading opens',
        trading_closes:    'when trading closes',
        ranking_change:    'when your ranking changes',
        too_much_cach:     'when you have too much stale cash',
        low_activity:      'about low activity',
        big_stock_change:  'about big stock changes',
    };

    return push_titles[push_key] ?? push_key ?? '';
}