<template>
    <div class="email_signup">
        <div class="logo"></div>

        <h1 v-if="restorePass">Restore Password</h1>
        <h1 v-else >Sign in / Sign up</h1>

        <div v-if="resetSent">Reset password link was successfully sent to your email</div>
        <div v-if="loginError" class="text-danger" v-html="errorMsg" @click="processClicksOnMsg"></div>
        <form class="sign-form" @submit.prevent="submitForm">
<!--            <transition name="slide-fade" mode="out-in">-->
            <div class="email_signup__input_container">
                <label for="email">Email</label>
                <input @change="canVerify.email=true" id="email" type="email" v-model="form.email" :class="{error:!validEmail,wobble:attention}" required/>
            </div>
<!--            </transition>-->
            <div v-if="!restorePass"  class="email_signup__input_container">
                <label for="password">Password</label>
                <input id="password" type="password" v-model="form.password" required/>
            </div>
            <div class="email_signup__buttons">
                <app-button class="email_signup__button" :class="{processing:processing}" v-if="restorePass">Reset Password</app-button>
                <app-button class="email_signup__button" :class="{processing:processing}" v-else >Sign in / Sign up</app-button>
            </div>
        </form>
        <p class="address">
            <a href="#" @click.prevent="restorePass = false; loginError = false;" v-if="restorePass">Sign in / Sign up</a>
            <a href="#" @click.prevent="restorePass = true; loginError = false;" v-else>Forgot password?</a>
        </p>
    </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
    name:       "EmailSignUp",
    components: {AppButton},
    props: ['emailLogin'],
    data() {
        return {
            form:       {
                email:    null,
                password: null,
            },
            resetSent:  false,
            restorePass:  false,
            loginError: false,
            errorMsg: 'Something went wrong',
            processing: false,
            attention: false,
            canVerify: {
                email: false,
                pass: false,
            }
        }
    },
    computed: {
      ...mapState(['rest_api_url']),
        validEmail(){
          if (!this.canVerify.email) return true;
          if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)){
              return true;
          }
          return false;
        },
        isValid(){
          return this.validEmail && this.form.password;
        }
    },
    methods: {
        ...mapActions(['restApiRequest']),
        async submitForm() {
            this.loginError = false;
            // let url = this.rest_api_url + 'pages/authemail/sign.php';
            if (!this.isValid) {
                this.attention = true;
                setTimeout(()=>{this.attention = false;},1000);
                return;
            }

            if (this.restorePass){
                this.restorePassword();
                return;
            }

            let url = this.rest_api_url + 'pages/authemail/sign.php';
            this.processing = true;
            let data = await this.restApiRequest({apiUrl:url,params: this.form});
            this.processing = false;
            if (data.result === 'success') {
                // this.$router.replace('/');
                localStorage.unverified = (data.unverified) ? 1 : 0;
                window.location.href = '/';
            } else {
                this.loginError = true;
                this.errorMsg = data.message;
            }
            console.log(this.form);
            console.log(data);
        },
        async verifyAgain() {
            this.loginError = false;
            let url = this.rest_api_url + 'pages/authemail/verify_again.php';
            this.processing = true;
            let data = await this.restApiRequest({apiUrl:url,params: this.form});
            this.processing = false;
            if (data.result === 'success') {
                this.loginError = true;
                this.errorMsg = data.message;
                this.processing = false;
            } else {
                this.loginError = true;
                this.errorMsg = data.message;
                this.processing = false;
            }
            console.log(this.form);
            console.log(data);
        },
        processClicksOnMsg(e){
            let clickedElId = e.target.id
            if (clickedElId === 'sendAnotherEmail') {
                this.verifyAgain();
            }
        },
        async restorePassword(){
            this.loginError = false;
            console.log('restore Password', this.form.email);
            let url = this.rest_api_url + 'pages/authemail/forgot.php';
            this.processing = true;
            let data = await this.restApiRequest({ apiUrl: url, params: this.form });
            console.log(data);
            this.loginError = true;
            this.errorMsg = data.message;
            this.processing = false;
        }
    },
    watch:{
        emailLogin: function(){
            if (this.emailLogin) this.restorePass = false;
        }
    }
}
</script>

<style lang="scss">
html, body, input, button, textarea {
    font-size: 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input{
    &.error{
        border: 1px solid $highlight_color;
    }

    &.wobble {
        animation: wobble 750ms linear 1;
    }
}

input[type=text], input[type=email], input[type=password] {
    background: white;
    border: 1px solid #888888;
    border-radius: 0.3em;
    outline: none;
    font-size: 1em;
    padding: 0.75em 1em;
    width: 100%;
    max-width: 360px;
    box-sizing: border-box;
    @media screen and (min-width: 720px) {
        display: inline-block;
    }
}

.text-danger{
    color: $highlight_color;
}

.email_signup {
    max-width: 500px;
    margin: auto;


    form.sign-form {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;

        div {
            flex-grow: 1;
            margin: 10px 0;
        }


    }

    label {
        display: block;
        text-align: left;
        @media screen and (min-width: 720px) {
            display: inline-block;
            min-width: 90px;
        }
    }

    a{
        color: $base_color_dim;
    }

    &__button {
        max-width: 150px;
        margin: auto;
        justify-self: center;
    }

    &__input_container {
        text-align: left;
        min-width: 300px;
    }
}




.processing::after {
    content: '';
    /*font-weight: bold;*/
    font-size: 0.8em;
    color: #ffffff;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0, 0, 0);
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, rgba(0, 0, 0, 0.05)), color-stop(0.5, rgba(0, 0, 0, 0.05)), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(0, 0, 0, 0.05)), to(rgba(0, 0, 0, 0.05)));
    background-image: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, rgba(0, 0, 0, 0.05) 25%, rgba(0, 0, 0, 0.05) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, rgba(0, 0, 0, 0.05) 75%, rgba(0, 0, 0, 0.05));
    background-image: -moz-linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, rgba(0, 0, 0, 0.05) 25%, rgba(0, 0, 0, 0.05) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, rgba(0, 0, 0, 0.05) 75%, rgba(0, 0, 0, 0.05));
    background-image: -o-linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, rgba(0, 0, 0, 0.05) 25%, rgba(0, 0, 0, 0.05) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, rgba(0, 0, 0, 0.05) 75%, rgba(0, 0, 0, 0.05));
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, rgba(0, 0, 0, 0.05) 25%, rgba(0, 0, 0, 0.05) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, rgba(0, 0, 0, 0.05) 75%, rgba(0, 0, 0, 0.05));
    background-size: 4em 4em;
    animation: processing 750ms linear infinite;
}

</style>