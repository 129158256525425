<template>
    <div class="trades">
        <div class="trades__header">
            <div class="deal_type">Operation</div>
            <div class="price">Price</div>
            <div class="value">Amount</div>
            <div class="cost">Cost</div>
        </div>
        <div class="trades__body">
            <template v-for="(deal,date) in tradesHistory" :key="date">
                <div class="trades__date">{{ date }}</div>
                <div class="trades__row">
                    <div class="deal_type">
                        {{ deal.dealType }}
                    </div>
                    <div class="price">${{ deal.price }}</div>
                    <div class="value">{{ deal.amount }}</div>
                    <div class="cost">{{ getCost(deal) }}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {addDollarSign, financial} from "@/assets/js/filters";
import {getLastObjValue} from "@/assets/js/helper_functions";
import PortfolioTableRow from "@/components/PortfolioTableRow";
import {mapActions, mapMutations, mapState} from "vuex";
import {calculateCurrentPortfolioStockValue} from "@/assets/js/chart_functions";

export default {
    name: "TradeHistoryTable",
    methods: {
        ...mapMutations(['toggleChartInfo']),
        ...mapActions(['portfolioStockPricesUpdate']),
        financial,
        getCost(deal){
            let sign = deal.dealType == 'buy' ? -1 : 1;
            let cost = addDollarSign(financial(sign * deal.amount * deal.price, true));
            return cost;
        }
    },
    async mounted() {
    },
    computed: {
        ...mapState(['money', 'chart_symbol', 'portfolio_deals', 'stock_prices', 'current_stock_prices', 'is_loading', 'chart_range', 'chart_info']),
        tradesHistory: function () {
            return this.portfolio_deals[this.chart_symbol];
        },
    }
}
</script>

<style lang="scss">
.trades {
  &__header, &__row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: 20px;
    box-sizing: border-box;
    padding: 5px;
    border-bottom: 2px solid #ccc;
  }
  //
  //&__header {
  //  margin-top: 20px;
  //  position: sticky;
  //  top: 55px;
  //  background-color: $bg_color;
  //  z-index: 51;
  //}

  &__body {
    overflow: hidden;
    flex-grow: 1;
    padding-bottom: 50px;
  }

  &__date{
    color: $highlight_color;
    text-align: left;
    font-size: 0.8em;
    padding: 5px;
  }
}
</style>