import {createRouter, createWebHashHistory} from 'vue-router'
import Portfolio from '../views/Portfolio.vue';
import Feed from "@/views/Feed";
import Success from "@/views/Success";
import Leaderboard from "@/views/Leaderboard";
import BuyStock from "@/views/BuyStock";
import PortfolioNew from "@/views/Portfolio";
import StockDetails from "@/views/StockDetails";
import LoginPage from "@/views/LoginPage";
import {hasAvatar, hasUsername, isLoggedIn} from "@/assets/js/helper_functions";
import SearchStock from "@/views/SearchStock";
import LogoutPage from "@/views/LogoutPage";
import UserProfile from "@/views/UserProfile";
import store from '@/store';
import StaticPage from "@/views/StaticPage";
import PortfolioDetails from "@/views/PortfolioDetails";
import ChooseAvatar from "@/views/ChooseAvatar";
import UserName from "@/views/UserName";
import BuyExtraCash from "@/views/BuyExtraCash";
import BuyPremium from "@/views/BuyPremium";
import BuyResetProfile from "@/views/BuyResetProfile";

const routes = [
    {
        path:      '/',
        name:      'portfolio',
        component: Portfolio,
        meta:      {transition: 'slideUp'},
    },
    {
        path:      '/portfolio/:uid?',
        name:      'portfolio_details',
        component: PortfolioDetails,
        meta:      {transition: 'slideUp'},
    },
    {
        path:      '/feed',
        name:      'feed',
        component: Feed,
        meta:      {transition: 'slide-left'},

    },
    {
        path:      '/success',
        name:      'success',
        component: Success
    },
    {
        path:      '/leaderboard/:param',
        name:      'leaderboard',
        component: Leaderboard,
        meta:      {transition: 'slideUp'},
    },
    {
        path:      '/buystock',
        name:      'buystock',
        component: BuyStock
    },
    {
        path:      '/searchstock',
        name:      'searchstock',
        component: SearchStock,
        meta:      {transition: 'slideUp', scroll:'top'},
    },
    {
        path:      '/stockdetails/:stock?/:action?/:amount?',
        name:      'stockdetails',
        component: StockDetails,
        meta:      {transition: 'slideUp'},
    },
    {
        path:      '/login',
        name:      'login',
        component: LoginPage
    },
    {
        path:      '/logout',
        name:      'logout',
        component: LogoutPage
    },
    {
        path:      '/profile',
        name:      'profile',
        component: UserProfile
    },
    {
        path:      '/page/:slug',
        name:      'page',
        component: StaticPage
    },
    {
        path:      '/avatars',
        name:      'avatars',
        component: ChooseAvatar
    },
    {
        path:      '/username',
        name:      'username',
        component: UserName
    },
    {
        path:      '/buycash',
        name:      'buycash',
        component: BuyExtraCash
    },
    {
        path:      '/reset_profile',
        name:      'reset_profile',
        component: BuyResetProfile
    },
    {
        path:      '/buypremium/:plan?/',
        name:      'buypremium',
        component: BuyPremium
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const scrollStates = {};

const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior(to, from, savedPosition) {
        // console.log('scrollBehavior',to, from, savedPosition);
        scrollStates[from.name] = {top: window.scrollY};
        // console.log(from.name, scrollStates);
        if (to.meta.scroll == 'top') {
            return {top: 0};
        }

        if (savedPosition) {
            return savedPosition
        } else {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(scrollStates[to.name] || {top: 0});
                }, 200)
            })
        }
    },
    routes
});

/**
 *  Wait for vuex store to be loaded from localForage
 */

// const waitForStorageToBeReady = async (to, from, next) => {
//     await store.restored
//     next()
// }

router.beforeEach((to, from, next) =>{
    if (['login','username','avatars','buypremium','buycash','page'].includes(to.name)) {
        next();
        return;
    }
    // console.log('isLoggedIn', isLoggedIn());
    if (to.name !== 'login' && !isLoggedIn()) {
        next({ name: 'login' })
    }
    else if (to.name !== 'username' && !hasUsername()) {
        setTimeout(function (){
            if (hasUsername()){
                next();
            }
            else{
                next({ name: 'username' })
            }

        }, 1000);

    }
    else if (to.name !== 'avatars' && !hasAvatar()) {
        next({ name: 'avatars' })
    }
    else next();
});

/**
 *  After route change hide the tutorial bubble
 */
router.afterEach((to, from) => {
    store.commit('activeGuideBubble', false);
})

export default router
