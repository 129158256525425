<template>
    <div class="stock_details">
        <div class="stock_details__header">
            <app-close-button @click="$router.replace('/')" class="fixed"/>
            <div class="stock_logo"><img :src="companyLogo"></div>
            <h1>{{ chart_symbol }}</h1>
            <div v-if="!isPortfolio" class="company_name">{{ companyName }}</div>
        </div>
        <transition name="modal">
            <app-modal v-if="buyModal" @close="closeBuyStockModal()" class="gray">
                <buy-stock
                        :deal-type="dealType"
                        :companyLogo="companyLogo"
                        :companyName="companyName"
                        :dealAmountProp="dealAmountFromUrl"
                        @finishedDeal="returnToHome()"
                        @close="buyModal = false"
                />
            </app-modal>
        </transition>

        <transition name="modal">
            <app-modal v-if="tradingClosedMessage" @close="closeTradingClosedModal()" class="gray">
                <h2>Trading Closed</h2>
                <p>Next open time: {{nextTradingOpenTime}}</p>
<!--                <p>Trading outside stock exchange business hours is available for PRO traders only</p>-->
<!--                <buy-button-simple-->
<!--                        @click="$router.push('buypremium')"-->
<!--                        :btnClass="highlightBuy + ' green dark'"-->
<!--                        btn-text="Become PRO"-->
<!--                />-->
            </app-modal>
        </transition>

        <template v-if="!buyModal">
            <Chart :item="item"/>
            <ChartRange/>
            <app-toggle v-if="currentAmount && !isPortfolio"  :options="toggleOptions" :toggle-action="toggleChartMode" :is-active="isToggleActive" class="valueToggle"/>
            <my-stock-info
                    :hideArrow="true"
                    :plPercent="profitLossPercent"
                    :fNum="currentAmount"
                    :sNum="'('+addDollarSign(stockValue)+')'"
                    desc="Owned shares"
                    style="margin-top: 18px"

            />
            <div class="buy_container" v-if="!isPortfolio">
                <buy-button-simple
                        @click="openBuyStockModal('sell')"
                        btnClass="red"
                        :btnDisabled="!dataLoaded || !currentAmount"
                        btn-text="SELL"
                />
                <buy-button-simple
                        @click="openBuyStockModal('buy')"
                        :btnClass="highlightBuy + ' green'"
                        :btnDisabled="!dataLoaded"
                        btn-text="BUY"
                />
            </div>
            <div class="stock_info" v-if="stockInfo">
                <div class="border-right">
                <stock-details-info :label="'Open'">{{ addDollarSign(stockInfo.open) }}</stock-details-info>
                <stock-details-info :label="'High'">{{ addDollarSign(stockInfo.high) }}</stock-details-info>
                <stock-details-info :label="'Low'">{{ addDollarSign(stockInfo.low) }}</stock-details-info>
                </div>
                <div>
                <stock-details-info :label="'Close'">{{ addDollarSign(closePrice) }}</stock-details-info>
                <stock-details-info :label="'Vol'">{{ addDollarSign(stockInfo.avgTotalVolume) }}</stock-details-info>
                <stock-details-info :label="'P/E'">{{ stockInfo.peRatio }}</stock-details-info>
                </div>
            </div>
            <div v-else class="emptyMsg">
                Loading current {{ chart_symbol }} data...
            </div>
            <div class="company_info" v-if="companyInfo">
                <h2>Description</h2>
                {{ companyInfo.description }}
            </div>
            <div>
                <h2>Trade history</h2>
                <trade-history-table/>
            </div>
        </template>

    </div>
</template>

<script>
// @ is an alias to /src
import Chart from "@/components/Chart";
import ChartRange from "@/components/ChartRange";
import {mapState} from "vuex/dist/vuex.mjs";
import {
    getClosePrice,
    getCompanyLogo,
    getLastDeal,
    getPnlPercents, getStockPL, onBoarding, scrollToBottom,
} from "@/assets/js/helper_functions";
import StockDetailsInfo from "@/components/StockDetailsInfo";
import {financial, abbreviateNumber, addDollarSign} from "@/assets/js/filters";
import BuyStock from "@/views/BuyStock";
import {mapActions, mapGetters, mapMutations} from "vuex";
import TradeHistoryTable from "@/components/TradeHistoryTable";
import AppModal from "@/components/AppModal";
import AppCloseButton from "@/components/AppCloseButton";
import MyStockInfo from "@/components/MyStockInfo";
import BuyButtonSimple from "@/components/BuyButtonSimple";
import AppToggle from "@/components/AppToggle";
import AppButton from "@/App.vue";

export default {
    name:       'StockDetails',
    components: {
        AppButton,
        AppToggle,
        BuyButtonSimple,
        MyStockInfo,
        AppCloseButton,
        AppModal,
        TradeHistoryTable,
        BuyStock,
        StockDetailsInfo,
        Chart,
        ChartRange,
    },
    data() {
        return {
            buyModal:   false,
            tradingClosedMessage:   false,
            dealType:   'buy',
            dataLoaded: false,
            toggleOptions: {price:'Price',value:'Value'},
            chartMode: 'Price',
        }
    },
    computed: {
        ...mapState([
            'chart_info',
            'chart_range',
            'chart_symbol',
            'company_info',
            'current_stock_prices',
            'portfolio_deals',
            'portfolio_value',
            'profit_loss',
            'rest_api_url',
            'stock_prices',
            'symbols',
            'onboarding_status',
            'onboarding_active',
            'onboarding',
            'tutorial',
            'is_loading',
            'trading_times',
        ]),
        ...mapGetters(['user_premium']),
        isPortfolio() {
            return this.chart_symbol == 'My Portfolio'
        },
        stockInfo() {
            if (this.isPortfolio) return null;
            return this.current_stock_prices[this.chart_symbol];
        },
        closePrice: function () {
            return getClosePrice(this.stockInfo);
        },
        stockValue() {
            if (this.isPortfolio){
                return this.portfolio_value.current?.total ?? 0;
            }
            if (this.currentAmount == 0) return 0;
            return this.closePrice * this.currentAmount;
        },
        companyLogo() {
            return getCompanyLogo(this.symbols, this.chart_symbol, this.rest_api_url);
        },
        companyName() {
            return this.symbols[this.chart_symbol]?.company;
        },
        lastDeal() {
            if (this.isPortfolio) return null;
            return getLastDeal(this.portfolio_deals, this.chart_symbol);
        },
        currentAmount() {
            if (!this.lastDeal) return 0;
            return this.lastDeal.finalAmount;
        },
        profitLoss() {
            let pl = getStockPL(this.portfolio_deals, this.current_stock_prices, this.stock_prices, this.chart_range, this.chart_symbol);
            console.log('profitLoss', pl);
            return pl;
        },
        profitLossPercent: function () {
            console.log('profitLossPercent', this.profitLoss, this.stockValue);
            return getPnlPercents(this.profitLoss, this.stockValue);
        },
        item:function(){
            return {
                info:this.stockInfo,
                amount:this.currentAmount,
                value:this.stockValue,
                price:this.closePrice,
            }
        },
        companyInfo(){
            return this.company_info[this.chart_symbol] ?? '';
        },
        dealAmountFromUrl(){
            return this.$route.params.amount || 0;
        },
        highlightBuy(){
            if (!this.onboarding_active && !this.is_loading && !this.tutorial.openBuy){
                return 'button_attention'
            }
            return '';
        },
        nextTradingOpenTime(){
            let openTime = new Date(this.trading_times.nextOpen);
            // return openTime.toLocaleString("en-US", {timeZone: "America/New_York"});
            return openTime.toLocaleString("en-US");
        }
    },
    methods:  {
        abbreviateNumber,
        addDollarSign,
        financial,
        ...mapActions(['getCurrentStockData','getCompanyInfo']),
        ...mapMutations(['toggleChartInfo', 'setChartInfo', 'activeGuideBubble','setGuideBubble','setChartSymbol','tutorialDone']),
        async loadCurrentStockData() {
            if (!this.stockInfo) {
                await this.getCurrentStockData(this.chart_symbol);
            }

            if (!this.companyInfo){
                this.getCompanyInfo(this.chart_symbol);
            }

            if (this.stockInfo) {
                this.dataLoaded = true;
                return;
            } else {
                await this.loadCurrentStockData();
            }
        },
        returnToHome() {
            this.buyModal = false;
            this.tradingClosedMessage = false;
            this.$router.push('/');
        },
        toggleChartMode(){
            this.$FirebaseAnalytics.logEvent({name: 'BUY_metrics_switch'});
            this.toggleChartInfo();
        },
        isToggleActive(option){
            return this.chart_info == option;
        },
        sellBuyGAevents(dealType){
            let analyticsEvent = {
                'sell':'BUY_sell_tap',
                'buy':'BUY_buy_tap',
            }

            if (!this.trading_times.open){
                this.$FirebaseAnalytics.logEvent({name: 'BUY_non-trading_time'});
            }

            this.$FirebaseAnalytics.logEvent({name: analyticsEvent[dealType]});
        },
        openBuyStockModal(dealType){
            if (!(this.trading_times.open || this.onboarding_active || this.user_premium)){
                this.tradingClosedMessage = true;
                return;
            }
            this.buyModal=true;
            this.dealType=dealType;
            this.sellBuyGAevents(dealType);

            if (this.highlightBuy){
                this.tutorialDone('openBuy');
            }
        },
        closeBuyStockModal(){
            this.activeGuideBubble(false);
            this.buyModal = false;
            this.$router.replace('/stockdetails');
        },
        closeTradingClosedModal(){
            // this.activeGuideBubble(false);
            this.tradingClosedMessage = false;
            // this.$router.replace('/stockdetails');
        },
        setStockFromUrl(){
            let urlStock = this.$route.params.stock;
            if (!urlStock) return;
            urlStock = urlStock.toUpperCase();
            if (this.chart_symbol != urlStock){
                this.setChartSymbol(urlStock);
            }
        },
        setActionFromUrl(){
            let urlAction = this.$route.params.action;
            if (!urlAction) return;
            this.buyModal=true;
            this.dealType= urlAction;
        },
        userUrlParams(){
            this.setStockFromUrl();
            setTimeout(this.setActionFromUrl, 1000);
        }
    },
    async mounted() {
        this.userUrlParams();

        if (this.chart_symbol == 'My Portfolio') this.setChartSymbol('AAPL');
        await this.loadCurrentStockData();
        onBoarding(this);
        scrollToBottom(this, 'BUY_scroll');
    }
}
</script>

<style lang="scss">

.buy_container {
    display: flex;
    justify-content: space-evenly;
}

.stock_details {
    //height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: stretch;
    position: relative;
    padding: 25px 0 50px 0;
    //overflow-y: auto;

    &__header {
        padding: 25px 5px 15px 80px;
        margin: 0 -10px;
        position: sticky;
        top: 0;
        text-align: left;
        min-height: 60px;
        z-index: 100;
        background-color: $bg-color;
        padding-top: max(25px, var(--safe-top));

        h1 {
            margin: 0;
        }

        .company_name{
            line-height: 100%;
            font-size: 0.9em;
        }

        .stock_logo {
            position: absolute;
            left: 10px;
            height: 60px;
            width: 60px;
            //padding: 5px;
            box-sizing: border-box;
            border-radius: 50%;
        }

        @media screen and (min-width: 720px) {
            margin: 0 -40px;
            padding: 25px 5px 15px 120px;
            .stock_logo {
                left: 40px;
            }
        }
    }
    .chart_menu {
        position: relative;
    }
}
@media screen and (min-width: 720px) {
    #app .stock_details.view_container {
        padding-top: 0;
    }
}


.buy_stock_button {
    background-color: $highlight_color;
    width: 50px;
    height: 50px;
    margin: 10px;
    border-radius: 8px;
    font-size: 30px;
}

input[type=number], input[type=search] {
    background: white;
    border: 1px solid #888888;
    border-radius: 0.3em;
    outline: none;
    font-size: 1em;
    padding: 0.75em 1em;
    width: 100%;
    max-width: 160px;
    box-sizing: border-box;
    display: inline-block;

    &.error {
        border: 1px solid red;
    }

    &.wobble {
        animation: wobble 750ms linear 1;
    }

}

</style>