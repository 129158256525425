import moment from "moment";

import {
    Chart,
    // ArcElement,
    LineElement,
    // BarElement,
    PointElement,
    // BarController,
    // BubbleController,
    // DoughnutController,
    LineController,
    // PieController,
    // PolarAreaController,
    // RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    // Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
} from 'chart.js';

Chart.register(
    // ArcElement,
    LineElement,
    // BarElement,
    PointElement,
    // BarController,
    // BubbleController,
    // DoughnutController,
    LineController,
    // PieController,
    // PolarAreaController,
    // RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    // Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);

import 'chartjs-adapter-moment';
import {
    moneyRound,
    getFirstObjValue,
    getLastDeal,
    getClosePrice,
    getLastObjKey,
    isEmpty, getFlatDeals
} from "@/assets/js/helper_functions";
import {getAddedCash} from "@/assets/js/portfolio_functions";

export var myChart;

export function extractColumn(objArray, column) {
    let dataArray = [];

    for (let obj in objArray) {
        let dataPoint = column=='total' ? {x:obj, y: objArray[obj][column] || null} : {x:obj, y: objArray[obj]}
        dataArray.push(dataPoint);
    }

    // console.log('ObjArray ' + objArray);
    // console.log('Extract Column ' + column);
    // console.log(dataArray);
    return dataArray;
}



export  function randomRgb() {
    const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
    const r = randomBetween(50, 255);
    const g = randomBetween(50, 255);
    const b = randomBetween(50, 255);
    const rgb = `rgb(${r},${g},${b})`;
    return rgb;
}


export function getDataSet(chartData, ctx, label){
    label = label || 'Net Worth';
    ctx = ctx || document.getElementById('stocksChart').getContext('2d');
    let cHeight = ctx.canvas.clientHeight;
    let gradient = ctx.createLinearGradient(0, 0, 0, cHeight);
    // gradient.addColorStop(0, 'rgba(251,177,28,0.8)');
    gradient.addColorStop(0, 'rgba(251, 172, 22, 0.55)');
    // gradient.addColorStop(1, 'rgba(0,0,0,0)');
    gradient.addColorStop(1, 'rgba(44, 45, 51, 0.52)');

    let dataSets = [];
    dataSets.push({
        label:           label,
        backgroundColor: gradient,
        borderColor:     'rgb(251,177,28)',
        data:            chartData,
        pointRadius: 0,
        borderWidth: 2,
        fill:true,
    });
    return dataSets;
}


function monthFormating(value, index, values){
    if (removeFirstLastTick(value, index, values) === null) return null;
    let d = moment(value, 'MMM YYYY').toDate();
    if (d.getMonth() == 0) return d.getFullYear();
    else return moment(d).format("MMM");
}

function removeFirstLastTick(value, index, values){
    if (index == 0 || index == values.length -1) return null; // remove First and LastTick
    return value;
}

/**
 *  Tooltip callback function to add dollar sign
 * @param context
 */
function addDollarSignTooltip(context){
    var label = context.dataset.label || '';
    if (label) { label += ': ';}
    if (context.parsed.y !== null) {
        label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
    }
    return label;
}

export function getConfig(data , timeUnit){
    timeUnit = timeUnit || 'month';


    const ticksStyle =  {
        color: 'white',
        source:'data',
        font: {
            size: 12,
        },
    }


    const gridStyle = {
        display: false,
        color: 'rgba(255, 255, 255, 0.25)',
    }

    const config = {
        type:    'line',
        data,
        options: {
            events: ["mousemove", "mouseout", "click", "touchstart", "touchmove", "touchend", "mouseup"],
            animation: {
                duration: 0
            },
            layout:{
              padding:
                  {
                      top: 50,
                      left: 5,
                      right: 5,
                      bottom: 5,
                  }
            },
            interaction: {
                mode: 'index',
                axis: 'x',
                intersect: false,
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        color: 'white',
                        font:  {
                            size: 14
                        }
                    }
                },
                decimation: {
                    enabled: false,
                    algorithm: 'lttb',
                    samples: 5
                },
                tooltip: {
                    position: 'topRight',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    displayColors: false,
                    titleAlign: 'right',
                    bodyAlign: 'right',
                    bodyColor: '#ccc',
                    callbacks: {
                        label: addDollarSignTooltip,
                    }

                },
            },
            scales:  {
                x: {
                    type: 'timeseries',
                    // type: 'time',
                    // distribution: 'series',
                    offset: true,
                    time: {
                        unit: timeUnit,
                        displayFormats: {
                            quarter: 'MMM YYYY',
                            week: 'MMM DD',
                        }
                    },
                    autoSkip: true,
                    autoSkipPadding: 20,
                    grid: gridStyle,
                    ticks: ticksStyle
                },
                y: {
                    display: false,
                    grid: gridStyle,
                    ticks: ticksStyle
                }
            }
        }
    };

    // console.log(config);
    return config;
}


function smallChartConfig(data){
    const config = {
        type:    'line',
        data,
        options: {
            animation: {
                duration: 0
            },
            layout:{
                padding: 5
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
            scales:  {
                x: {
                    // type: 'time',
                    display: false,
                },
                y: {
                    display: false,
                }
            }
        }
    };
    return config;
}


export function getLabels(portfolioValue){
    return Object.keys(portfolioValue);
}

export function getChartData(cData, symbol, ctx, label){
    // if (symbol == 'My Portfolio') symbol = 'total'; // Rename the symbol to the Portfolio value data key
    let dSet = extractColumn(cData, symbol);
    const data = {
        labels:   getLabels(cData),
        datasets: getDataSet(dSet, ctx, label)
    };
    return data;
}

export function updateChartPeriod(period, myChart, prices, symbol, label)
{
    console.log('updateChartPeriod', period, myChart, prices, symbol, label);
    let d = new Date();
    d.setHours(0, 0, 0);
    let cData;
    let timeUnit = 'day';
    let ticksCallback = removeFirstLastTick;

    let range = 'r'+getChartRangeForApi(period);
    if (isEmpty(prices[range])) return; // If we don't have the data we exit the function;

    switch (period) {
        case '1w':
            d.setDate(d.getDate() - 7);
            cData = extractDateSegment(prices['r1mm'], d);
            break;
        case '1m':
            cData = extractIntervals(prices['r1mm'], 4);
            timeUnit = 'week';
            break;
        case '3m':
            d.setMonth(d.getMonth() - 3);
            cData = extractDateSegment(prices['r2y'], d);
            timeUnit = 'month';
            ticksCallback = monthFormating;
            // cData = portfolioValue3m;
            break;
        case '6m':
            d.setMonth(d.getMonth() - 6);
            cData = extractIntervals(extractDateSegment(prices['r2y'], d), 2);
            timeUnit = 'month';
            ticksCallback = monthFormating;
            break;
        case '1y':
            d.setFullYear(d.getFullYear() - 1);
            cData = extractIntervals(extractDateSegment(prices['r2y'], d), 4);
            timeUnit = 'quarter';
            break;
        case '2y':
            // d.setFullYear(d.getFullYear() - 2);
            cData = extractIntervals(prices['r2y'], 8);
            timeUnit = 'year';
            break;
    }
    updateChartData(cData, timeUnit, ticksCallback, myChart, symbol, label);
}


export function getSmallChartPeriod(period, prices)
{
    if (!prices) return {};
    let d = new Date();
    d.setHours(0, 0, 0);
    let cData;

    switch (period) {
        case '1w':
            d.setDate(d.getDate() - 7);
            cData = extractIntervals(extractDateSegment(prices['r1mm'], d), 2);
            break;
        case '1m':
            cData = extractIntervals(prices['r1mm'], 8);
            break;
        case '3m':
            d.setMonth(d.getMonth() - 3);
            cData = extractIntervals(extractDateSegment(prices['r2y'], d),2);
            break;
        case '6m':
            d.setMonth(d.getMonth() - 6);
            cData = extractIntervals(extractDateSegment(prices['r2y'], d), 4);
            break;
        case '1y':
            d.setFullYear(d.getFullYear() - 1);
            cData = extractIntervals(extractDateSegment(prices['r2y'], d), 8);
            break;
        case '2y':
            // d.setFullYear(d.getFullYear() - 2);
            cData = extractIntervals(prices['r2y'], 16);
            break;
    }
    return cData;
}


export function updateChartData(cData, unit, callback, myChart, symbol, label){
   myChart.options.scales.x.time.unit = unit;
   myChart.options.scales.x.ticks.callback = callback;
   myChart.data = getChartData(cData, symbol, null, label);
   myChart.update();
}

export function initChart(chartData, symbol){
    const tooltipPlugin = Chart.registry.getPlugin('tooltip');
    tooltipPlugin.positioners.topRight = function(elements, eventPosition) {
        /** @type {Tooltip} */
        // console.log(this);
        var tooltip = this;
        /* ... */
        return {
            // x: elements[0]._chart.width,
            x: this._chart.width,
            y: 0
        };
    };

    const ctx = document.getElementById('stocksChart').getContext('2d');
    let config = getConfig(getChartData(chartData, symbol), 'week');
    config.options.scales.x.ticks.callback = removeFirstLastTick;
    return new Chart(ctx, config);
}

export function initSmallChart(chartData, ctx, symbol){
    // let config = smallChartConfig(chartData);
    if (!ctx) return;
    let config = smallChartConfig(getChartData(chartData, symbol, ctx), 'week');
    return new Chart(ctx, config);
}

export function updateSmallChartData(period, prices, mySmallChart, symbol, ctx){
    if (!ctx) return;
    let cData = getSmallChartPeriod(period, prices);
    if (!mySmallChart) {
        console.log('Chart object undefined for ', symbol, period);
        return;
    }
    mySmallChart.data = getChartData(cData, symbol, ctx);
    if (typeof mySmallChart.update === 'function'){
        mySmallChart.update();
    }
}



export function extractDateSegment(dataObj, date){
    // let keys = Object.keys(objct);
    let filtered = {};
    let limitDate = moment(date);
    for (let key in dataObj){
        let d = moment(key);
        if (d>=limitDate) filtered[key] = dataObj[key];
    }
    return filtered;
}

export function extractIntervals(dataObj, interval){
    let count = 0;
    let filtered = {};
    if (isEmpty(dataObj)) {
        console.log('Chart data is empty', dataObj, interval);
        return filtered;
    }
    let lastKey = getLastObjKey(dataObj, false);
    let keys = Object.keys(dataObj);
    for (let key in dataObj){
        count++;
        if (count % interval == 0) {
            filtered[key] = dataObj[key];
        }
    }
    // Preserving the last element of the data - because it's the current price we added manually
    filtered[lastKey] = dataObj[lastKey];
    return filtered;
}


export function getChartRangeForApi(range){
    return ['1w','1m'].includes(range) ? '1mm' : '2y';
}

export function getCurrentNYtime(timestamp){
    return new Date(timestamp).toISOString();
}

/**
 *  Calculates the historical net value of a single stock symbol owned by the user based on the buy and sell deals made.
 * @param stock_prices - historical stock prices for a single symbol
 * @param stock_deals - all buy and sell deals for a single symbol
 */
export function calculateHistoricalStockValues(stock_prices, stock_deals){
    let stock_value = {};
    if (!stock_prices || !stock_deals) return stock_value;
    let multiplier = 0;
    let trading_dates = Object.keys(stock_prices);
    let deal_dates = Object.keys(stock_deals);
    deal_dates.sort();
    let i = 0;
    let deal_date =  deal_dates[i];
    // console.log(deal_date);
    for (let date of  trading_dates){
        while (date >= deal_date && i < deal_dates.length) {
            multiplier = stock_deals[deal_date].finalAmount;
            // console.log(i);
            i++;
            deal_date =  deal_dates[i];
        }
        stock_value[date] = moneyRound(stock_prices[date]*multiplier);
    }
    return stock_value;
}


export function getPortfolioStockValue(symbol, stock_prices, portfolio_deals, range){
    // console.log(symbol, stock_prices, portfolio_deals, range);
    let stockPrices = stock_prices[symbol]['r'+range];
    let stockDeals = portfolio_deals[symbol];
    return calculateHistoricalStockValues(stockPrices, stockDeals);
}

/**
 *  Calculates current portfolio value based on current stock prices does not includes cash , not using historical prices
 * @param current_stock_prices
 * @param portfolio_deals
 * @returns float
 */
export function calculateCurrentPortfolioStockValue(current_stock_prices, portfolio_deals){
    if (isEmpty(portfolio_deals)) return 0;
    if (!current_stock_prices) return null;
    let symbols = Object.keys(portfolio_deals);
    let value = 0;
    for (let s of symbols) {
        let deal = getLastDeal(portfolio_deals, s);
        if (!deal || !current_stock_prices[s]) continue; // We don't have deal or price info so we continue to nex symbol
        let stockCurrentPrice = getClosePrice(current_stock_prices[s]);
        let stockAmount = deal.finalAmount;
        value += stockCurrentPrice*stockAmount;
    }
    return moneyRound(value);
}



/**
 *  Calculate portfolio historic values for chart rendering
 * @param stock_prices
 * @param stock_deals
 * @returns {{}}
 */
export function calculatePortfolioHistoricValues(portfolio_stock_values, all_stocks_deals, range, money_history){
    let portfolio_total_value = {};
    let symbols = Object.keys(all_stocks_deals);
    if (!money_history) return {}; // Portfolio is empty
    portfolio_total_value = money_history;
    if (!symbols.length) return money_history; // Portfolio is empty
    // console.log('symbols' , symbols, !symbols.length);
    for (let symbol of  symbols){
        if (!portfolio_stock_values[symbol]) continue;
        // console.log(symbol, range ,portfolio_stock_values[symbol]['r'+range]);
        let stock_values = portfolio_stock_values[symbol]['r'+range];
        let prevStockPrice = getFirstObjValue(stock_values, true); // Get the earliest stock price available in the object
        for (let d in portfolio_total_value){
            portfolio_total_value[d] = (portfolio_total_value[d] ?? 0) + (stock_values[d] ?? prevStockPrice);
            if (stock_values[d]) prevStockPrice = stock_values[d]; // Update with the leftmost available stock price
        }
    }
    // console.log(portfolio_total_value);
    return portfolio_total_value;
}

export function getTradingDays(stock_prices, range){
    let period = 'r'+range;
    let symbols = Object.keys(stock_prices).filter((symbol)=> !isEmpty(stock_prices[symbol][period]));
    // console.log('Trading days symbols',symbols, period);
    if (!symbols || !symbols[0] || !stock_prices[symbols[0]]) return null;
    let trading_dates = Object.keys(stock_prices[symbols[0]]['r'+range]);
    trading_dates.sort();
    return trading_dates;
}

function initialCashChart(initialCash, stock_prices, range){
    let trading_dates = getTradingDays(stock_prices, range);
    let money = {};
    for (let date of  trading_dates){ // Initialize total with initialCapital
        money[date] = initialCash;
    }
    return money;
}

// Get cash history from flat deals array not considering various stock
export function getCashHistory(initialCash, all_stock_deals, extra_cash_deals, stock_prices, range){
    if (isEmpty(all_stock_deals) && isEmpty(extra_cash_deals)) return initialCashChart(initialCash, stock_prices, range);
    initialCash = initialCash || 100000;
    // let all_deals = {portfolio_deals:all_stock_deals, extra_cash:extra_cash_deals};
    // console.log(all_deals);
    let deals = getFlatDeals(all_stock_deals);
    // console.log('Flat deals');
    // console.log(deals);
    let dealDates = Object.keys(deals);

    dealDates.sort();
    let trading_dates = getTradingDays(stock_prices, range);
    if (!trading_dates) {
        console.log('Stock prices are empty',stock_prices);
        return {};
    }
    let money = {};
    let lastCash = initialCash;
    let i = 0;
    // console.log('dealDates');
    // console.log(dealDates);
    let deal_date =  dealDates[i]; // Get the first deal date.
    for (let date of  trading_dates){ // Initialize total with initialCapital
        while (date >= deal_date && i < dealDates.length) {
            lastCash = deals[deal_date]['cash'];
            i++;
            deal_date =  dealDates[i];
        }
        money[date] = lastCash + getAddedCash(extra_cash_deals, date);
    }
    return money
}

/**
 *  Calculate capital (money) historic values
 * @param initialCapital number - the initial capital we start the calculation from
 * @param all_deals object - with all portfolio stock buy and sell deals
 * @param portfolio_stock_values - object with historic value of each stock in portfolio
 * @param range
 * @returns {{}|null}
 */
export function calculateMoneyHistoricValues(initialCapital, all_deals, stock_prices, range){
    if (!all_deals) return null;
    let symbols = Object.keys(all_deals);
    let trading_dates = getTradingDays(stock_prices, range);

    let money = {};
    let total = {};
    if (!trading_dates) return null;
    for (let date of  trading_dates){ // Initialize total with initialCapital
        total[date] = initialCapital;
    }
    if (!symbols.length) return total; // If there are no deals everything is equal to initialCapital;
    // let trading_dates = Object.keys(stock_prices);
    // console.log('portfolio_stock_values', portfolio_stock_values);
    for (let symbol of  symbols){
        let stock_deals = all_deals[symbol]; // All deals for a single stock symbol
         let sMoneyHistory = calculateMoneyHistoryForSingleStock(trading_dates, stock_deals);
        money[symbol] = sMoneyHistory;
        for (let date of  trading_dates){
            total[date] = total[date] + sMoneyHistory[date];
        }
    }
    // console.log('MONEY:', money[symbols[0]]);
    return total;
}

function calculateMoneyHistoryForSingleStock(trading_dates, stock_deals){
    let capital = 0;
    let moneyFlow = {};
    let deal_dates = Object.keys(stock_deals); // All deal dates for a single stock symbol
    let i = 0;
    let deal_date =  deal_dates[i]; // Get the first deal date.
    for (let date of  trading_dates){
        while (date >= deal_date && i < deal_dates.length) {
            let deal = stock_deals[deal_date];
            let cost = moneyRound(deal.amount * deal.price);
            if (deal.dealType == 'buy') capital = capital - cost;
            if (deal.dealType == 'sell') capital = capital + cost;

            // console.log('capital', date, deal_date, deal.dealType, deal.amount, deal.price , cost, capital);
            i++;
            deal_date =  deal_dates[i];
        }
        moneyFlow[date] = capital;
    }
    // console.log('Money Flow',moneyFlow);
    return moneyFlow;
}

/**
 *  Returns the remains amount of cash this is faster than calculating the entire history object and taking the last value and
 *  does not need the historic stock data only the portfolio deals
 * @param allDeals Object - all stock sell and buy deals
 * @param initialCapital number - the amount of initialCash
 * @returns {number} - remaning amount of cash
 */
export function getRemainingCash(allDeals, initialCapital){
    if (!allDeals) return initialCapital;
    let deals = getFlatDeals(allDeals.portfolio_deals ?? allDeals);
    let last_deal_date = getLastObjKey(deals, true);
    return deals[last_deal_date].cash + getAddedCash(allDeals.extra_cash ?? {});
}



export function objIsChart(obj){
    if (!obj || isEmpty(obj)) return false;
    return obj instanceof Chart;
}
