export const onBoardingSteps = [
    { // 0
        active:         true,
        action(){},
        target:         'center',
        img:            'welcome.png',
        msg:            `You’ve joined an exciting universe of stock trading! Awesome! Welcome to the game!`,
        w:              280,
        h:              300,
        delay: 1000,
        routeName: 'portfolio',
        style: {'z-index':500},
    },
    { // 1
        active:         true,
        action(){},
        msg:"You have $100k of free cash balance. Trade stocks and make it a million!",
        img: "get_started.png",
        // target: "center",
        targetHighlight: true,
        targetSelector: 'div.my_stock_info b',
        w:              window.innerWidth < 400 ? 200 : 260,
        h:              window.innerWidth < 400 ? 280 : 350,
        top: true,
        left: false,
        delay: 500,
        routeName: 'portfolio',
    },
    { // 2
        active:         true,
        msg:"Let’s start! <br>Make your money work for you and buy some stocks!",
        img: "discovery.png",
        targetSelector: "div.search_stock_button",
        targetAttention: true,
        // targetParentHighlight: true,
        action(){
            document.querySelector("div.search_stock_button")?.click();
        },
        w:              window.innerWidth < 400 ? 200 : 260,
        h:              window.innerWidth < 400 ? 280 : 350,
        top: true,
        left: true,
        delay: 500,
        routeName: 'portfolio',
        guideStyle: {'z-index':100},
    },
    { // 3
        active:         true,
        action(){
            let stockButton = document.querySelector("div.search_results > div:nth-child(2)");
            if (stockButton) stockButton.click();
            else {
                window.location.hash = '/stockdetails';
            }
        },
        msg:"Type a company name or a ticker, or pick one of the preselected stocks.",
        img: window.innerWidth < 400 ? null : "07_storefront.png",
        targetAttention: true,
        targetSelector: ".search_stock__container , div.search_results > div:nth-child(2)",
        w:              window.innerWidth < 400 ? 240 : 260,
        h:              window.innerWidth < 400 ? 100 : 350,
        top: false,
        center: true,
        delay: 1500,
        routeName: 'searchstock',
        guideStyle: {'z-index':100}

    },
    { // 4
        active:         true,
        action(){
            document.querySelector(".buy_button_simple.green")?.click();
        },
        msg: "Decide if you want to buy this share and act!",
        img: "team_meeting.png",
        targetAttention: true,
        targetSelector: ".buy_button_simple.green",
        w:              window.innerWidth < 400 ? 200 : 260,
        h:              window.innerWidth < 400 ? 280 : 350,
        top: true,
        center: true,
        delay: 1000,
        routeName: 'stockdetails',
        guideStyle: {'z-index':100 },
    },
    { // 5
        active:         true,
        action(){
            document.querySelector(".plus_stock_btn")?.click();
        },
        msg: "Select the number of shares you want to buy",
        img: "checkout.png",
        targetSelector: ".plus_stock_btn",
        targetAttention: true,
        w:              window.innerWidth < 400 ? 200 : 260,
        h:              window.innerWidth < 400 ? 250 : 350,
        top: true,
        center: true,
        delay: 1000,
        routeName: 'stockdetails',
        guideStyle: {},
    },
    { // 6
        active:         true,
        action(){},
        msg:"Press and hold to complete the trade!",
        img: '',
        targetAttention: true,
        targetSelector: ".buy_button.green",
        w:              260,
        h:              50,
        top: true,
        center: true,
        delay: 1500,
        routeName: 'stockdetails',
    },
    { // 7
        active:         true,
        action(){
            document.querySelector("#stock_table > div.body > div:nth-child(2)")?.click();
        },
        msg:`The stocks you own are listed here. 
             <br>Tap any stock to see its performance details.`,
        // img: "growth.png",
        targetSelector: "#stock_table > div.body > div:nth-child(2)",
        targetAttention: true,
        w:              window.innerWidth < 400 ? 260 : 360,
        h:              window.innerWidth < 400 ? 140 : 150,
        top: false,
        center: true,
        delay: 2000,
        routeName: 'portfolio',
        guideStyle: {'z-index':100 },
    },
    { // 8
        active:         true,
        action(){

        },
        msg:`Track your gains regularly. 
             <br>Rebalance your portfolio once in a while to take profits and buy new undervalued stocks!`,
        img: "growth.png",
        target: "center",
        w:              window.innerWidth < 400 ? 260 : 300,
        h:              window.innerWidth < 400 ? 380 : 400,
        top: true,
        delay: 2500,
        routeName: 'stockdetails',
    },
    { // 9
        active:         true,
        action(){
            document.querySelector(".stock_details__header .close_button")?.click();
        },
        msg:"Return to your portfolio.",
        img: "",
        // targetSelector: ".portfolio.menu_button",
        targetSelector: ".stock_details__header .close_button",
        targetAttention: true,
        w:              window.innerWidth < 400 ? 200 : 260,
        h:              window.innerWidth < 400 ? 50 : 50,
        top: false,
        left: true,
        delay: 1500,
        routeName: 'stockdetails',
        guideStyle: {'z-index':500},
    },
    { // 10
        active:         true,
        action(){},
        msg:"Now you know the techniques. Here's a piece of advice about strategy",
        img: "strategy.png",
        target: 'center',
        w:              280,
        h:              320,
        delay: 500,
        routeName: 'portfolio',
        style: {'z-index':500},
    },
    { // 11
        active:         true,
        action(){},
        msg:"<b>Advice 1.</b> Buy low, sell high. Do not keep lots of cash. Make your money work for you.",
        img: "Black-Friday.png",
        target: 'center',
        w:              280,
        h:              380,
        delay: 500,
        routeName: 'portfolio',
        style: {'z-index':500},
    },
    { // 12
        active:         true,
        action(){},
        msg:"<b>Advice 2.</b> Allocate 30% of your total balance to trades during the day. Catch opportunities. Test ideas, follow trends, outsmart the market!",
        img: "Medical-research.png",
        target: 'center',
        w:              280,
        h:              380,
        delay: 500,
        routeName: 'portfolio',
        style: {'z-index':500},
    },
    { // 13
        active:         true,
        action(){},
        msg:`<b>Advice 3.</b> Don’t panic. Rebalance your position at highs and average your position at lows.`,
        img: "Projection.png",
        target: 'center',
        w:              280,
        h:              380,
        delay: 500,
        routeName: 'portfolio',
        style: {'z-index':500},
    },
    { // 14
        active:         true,
        action(){},
        msg:`<b>Rebalancing</b> is taking profits from selling overpriced stocks and investing in undervalued stocks <br><br>
             <b>Averaging</b> is buying additional amounts of the stock at the low point`,
        img: "",
        target: 'center',
        w:              280,
        h:              300,
        delay: 500,
        routeName: 'portfolio',
        style: {'z-index':500},
    },
    { // 15
        active:         true,
        // action(){ document.querySelector("div.center_donut")?.click(); },
        action(){},
        msg:`Your progress is tracked here. Green means things look great! Red means trouble.`,
        img: "Click-Here.png",
        targetSelector: 'div.center_donut .donut__chart',
        targetAttention: true,
        w:              280,
        h:              380,
        top: false,
        center: true,
        delay: 1500,
        routeName: 'portfolio',
        guideStyle: {'z-index':500},
    },
    { // 16
        active:         true,
        msg: `Buy more stocks and diversify your portfolio.<br> Good luck!`,
        img: "",
        targetSelector: "div.search_stock_button",
        targetAttention: true,
        // targetParentHighlight: true,
        action(){
            document.querySelector("div.search_stock_button")?.click();
        },
        w:              window.innerWidth < 400 ? 200 : 260,
        h:              window.innerWidth < 400 ? 100 : 100,
        top: true,
        left: true,
        delay: 1500,
        routeName: 'portfolio',
        guideStyle: {'z-index':100},
    },
]