<template>
    <div class="loading__container">
        <div class="loading__message">
            <slot>Loading</slot>
        </div>
        <div class="loading__ring"><div></div><div></div><div></div><div></div></div>
    </div>
</template>

<script>
export default {
    name: "AppLoadingAnimation",
    props: {
        extraClass:   String,
    },
}
</script>

<style lang="scss">

.loading {
    &__container {
        top: 0;
        left: 0;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 1200;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(28, 30, 46, 0.75);
        pointer-events: none;

        &.transparent{
            background-color: transparent;
        }

        &.big{
            .loading__ring{
                $size:320px;
                $width: 20px;
                width: $size;
                height: $size;
                div {
                    width: $size - 2 * $width;
                    height: $size - 2 * $width;
                    margin: $width;
                    border-width: $width;
                    border-color: #aaa transparent transparent transparent;
                }
            }
        }
    }

    //background-color: #f00;
    &__message {
        font-size: 1.5em;
        text-align: center;
        //background-color: red;
        max-width: 200px;
        animation: opacity_animate 3.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }

    &__ring{
        $size: 240px;
        $width: 10px;
        display: block;
        position: absolute;
        width: $size;
        height: $size;

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: $size - 2 * $width;
            height: $size - 2 * $width;
            margin: $width;
            border: $width solid #fff;
            border-radius: 50%;
            animation: lds-ring 3.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #fff transparent transparent transparent;
        }

        div:nth-child(1) {
            animation-delay: -0.45s;
        }

        div:nth-child(2) {
            animation-delay: -0.3s;
        }

        div:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@keyframes opacity_animate {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}


.loading-enter-active,
.loading-leave-active {
    transition: opacity 2.2s;
}

.loading-enter-from,
.loading-leave-to {
    opacity: 0;
}

.loading-leave-from,
.loading-enter-to {
    //z-index: auto;
    //opacity: 1;
}


</style>