<template>
    <div class="body tos">
        <div>
            <h2 class="title">Support page gain4.fun</h2>
            <div class="subtitle"></div>
        </div>

        <div class="heading_1">
            <strong>Does gain4.fun offers real-life stock trading?</strong>
        </div>

        <div class="body_text">
            No. But it provides real stock tickers and quotes and simulates the trading process that is often referred
            to as paper trading.
            That helps you to understand the stock trading process without taking actual risks
        </div>

        <div class="heading_1">
            <strong>How do I get support?</strong>
        </div>

        <div class="body_text">
            Write us a message to <b><a href="mailto:support@gain4.fun">support@gain4.fun</a></b>. We’ll do our best to help you promptly
        </div>

        <div class="heading_1">
            <strong>I’ve found a bug. Where can I report it?</strong>
        </div>

        <div class="body_text">
            Use the same support email <b><a href="mailto:support@gain4.fun">support@gain4.fun</a></b>. We’ll address the issue asap
        </div>

        <div class="heading_1">
            <strong>Can I trade outside the stock exchange trading hours?</strong>
        </div>

        <div class="body_text">
            In real life when the stock exchanges are closed you can still buy and sell stocks OTC (over-the-counter). That trade requires higher qualification and is linked to higher risk. In gain4.fun we simulate OTC trading outside stock exchange opening hours for premium users only
        </div>

    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'StaticTos',
}
</script>


<style lang="scss">
.tos {
    text-align: left;
    font-family: Roboto, Arial, Helvetica;
    font-size: 0.9em;

    h3 {
        margin: 35px 0 5px;
    }


    .body {
        background: transparent;
    }

    .title {
        font-size: 26px;
        color: #fff;
    }

    .subtitle {
        
        color: #999;
        font-size: 14px;
    }

    .heading_1 {
        margin: 35px 0 5px;
        font-size: 19px;
        color: #fff;
    }

    .heading_2 {
        margin: 25px 0 5px;
        font-size: 17px;
        color: #fff;
    }

    .body_text {
        color: #ddd;
        font-size: 14px;
        
    }

    .link {
        color: #3030F1;
        font-size: 14px;
        
        word-break: break-word;
    }


    ul {
        list-style-type: square;
    }

    ul > li > ul {
        list-style-type: circle;
    }

    ul > li > ul > li > ul {
        list-style-type: square;
    }

    ol li {
        
    }
}


</style>