var newbie, ga;

export const auth = {
    signIn: function (service, domain) {
        // if(!newbie){
        //     ga('send', 'event', {eventCategory: 'Authorization', eventAction: 'Signed In'});
        //     app.analytics.write('Authorization', 'Signed In');
        // }

        var oauthUri = domain + "/oauth/" + service + "/";

        // if(platform === 'Android') {
        //     if (typeof AndroidFunction.signIn === 'function') {
        //         try {
        //             AndroidFunction.signIn(oauthUri, app.helpers.newKey());
        //             return;
        //         } catch (error) {
        //         }
        //     }
        // }


        window.location = oauthUri;

    },
    // do some UI modifications on signin result
    signedIn: function (signed_in) {
        if (signed_in && !anonymous) {

            globals.user.authorized = true;

            $('.non-auth-only').hide();

            app.ui.ribbon.init();

            app.bridges.ios.fire('signedIn', {'email' : globals.user.data.email, 'uid' : globals.user.data.uid, 'plan' : globals.user.data.plan});
            app.bridges.android.signed(globals.user.data.email, globals.user.data.uid, globals.user.data.plan);

            if(newbie){
                gtag('event', 'conversion', {'send_to': 'AW-1065221042/--XUCP_a8NwBELL39_sD'});
                ga('send', 'event', {eventCategory: 'Authorization',eventAction: 'Signed Up'});
                app.analytics.write('Authorization', 'Signed Up');
                newbie = false;
            }

            if($.cookie("check_in_started")){
                if(is_premium == false){
                    app.premium.offer.open('check_in_started');
                    setTimeout(function () {
                        $('#button-'+$.cookie("check_in_started")).trigger('click');
                        $.removeCookie('check_in_started', { path: '/' });
                    }, 750);
                    return;
                }
                $.removeCookie('check_in_started', { path: '/' });
            }
        }
        else{
            app.ui.ribbon.destroy();

            $('.premium').addClass('visible');

            //$('.icon-share').appendTo('#workspace');
            //$('.icon-new').appendTo('#workspace');
            $('.non-auth-only').show();
            $('.auth-only').remove();
            $('.st-menu.sidebar > ul li #log-in').parent().remove();
            $('.st-menu.sidebar > ul').append('<li><a href="#" id="log-in">Log in</a></li>');

            app.auth.initSigninBtn();
            app.bridges.ios.fire('signedOut', {});
            app.bridges.android.ready();
            app.bridges.ios.ready();
            app.ui.splashView.remove();

            setTimeout(function () {
                globals.ui.tabbar.height = $('#menu-bar').height();
            }, 500)

        }
    },
    // user clicked Sign Out button
    // signOut: function () {
    //     // confirm first
    //     $.confirm({
    //         title: 'Log Out',
    //         content: 'Do you really want to log out?',
    //         theme: 'modern',
    //         useBootstrap: false,
    //         animateFromElement: false,
    //         boxWidth: '80%',
    //         backgroundDismiss: true,
    //         buttons: {
    //             no: {
    //                 keys: ['N'],
    //                 text: 'No',
    //                 action: function () {
    //                 }
    //             },
    //             yes: {
    //                 keys: ['y'],
    //                 text: 'Yes',
    //                 action: function () {
    //                     $.post("/ajax/signout.php", function (data) {
    //                         user_id = data.id;
    //                         token = data.token;
    //
    //                         app.stream.init();
    //                     }, 'json'); // kill the session
    //                     app.ui.menus.cell.toggle.itself(false); // close the menu if open
    //
    //                     // set up some globals
    //                     globals.user.authorized = false;
    //                     anonymous = true;
    //                     globals.project.all = {}; // remove all boards in the memory
    //                     globals.ui.tabbar.height = $('#menu-bar').height();
    //
    //                     is_premium = false; // no premium flag since the user is not signed in
    //                     $('#navbar .icon-premium').remove(); // remove premium icon from the navigation bar
    //
    //                     $('.non-auth-only').show();
    //                     //$('.icon-share').appendTo('#workspace');
    //                     //$('.icon-new').appendTo('#workspace');
    //                     app.auth.signedIn(false);
    //
    //                     app.tutorial.show('Signed Out');
    //                     ga('send', 'event', {eventCategory: 'Authorization', eventAction: 'Signed Out',});
    //                     app.analytics.write('Authorization', 'Signed Out');
    //
    //                 }
    //             },
    //         }
    //     });
    // },
    // display "Log in to save changes" button once user makes any modifications in the current project
    showSaveButton: function () {
        if($('#save-changes').length){
            return;
        }
        $('#projects-ribbon .unauth-view').html('<a href="#" class="btn-save-changes" id="save-changes">Log in to save changes</a>');

        $('#save-changes').unbind().click(function (e) {
            e.preventDefault();
            app.auth.popup('In order to save your boards in the Tradernet Cloud, please log in', true);
        });
    },
    // set the action on the Sign In button
    initSigninBtn: function () {
        $('#log-in').unbind().click(function (e) {
            e.preventDefault();
            app.auth.popup('', true);
        });
    },
    connProblemConfigIsShowing: false,
        confirmPopupInstance: null,
        showConnectionProblemConfirm: function(content_text, services, progressCheckInterval, conProblemTimeout) {
        if (!app.auth.connProblemConfigIsShowing) {
            app.auth.connProblemConfigIsShowing = true;

            if (app.auth.confirmPopupInstance) {
                return;
            }

            app.auth.confirmPopupInstance = $.confirm({
                title: 'Need to upload your photos before log in.',
                content: 'Some of your photos are not yet uploaded. Some of those may be lost if you proceed to login now. Or you can wait for the photos to upload and have them safe after you login.',
                theme: 'modern',
                useBootstrap: false,
                animateFromElement: false,
                boxWidth: '80%',
                onClose: function () {
                    app.auth.confirmPopupInstance = null;
                    app.auth.connProblemConfigIsShowing = false;
                },
                buttons: {
                    no: {
                        keys: ['N'],
                        text: 'Wait for upload',
                        action: function () {
                        }
                    },
                    yes: {
                        keys: ['y'],
                        text: 'Proceed to login',
                        action: function () {
                            app.ui.progress.finish();
                            app.auth.popup(content_text, services, true);

                            clearInterval(progressCheckInterval);
                            clearTimeout(conProblemTimeout);
                        }
                    }
                }
            });
        }
    },

    // shows log in popup
    popup: function (content_text, services) {

        // while user is anonymous there are no request for updating preview.
        // so, before sign in we updating preview of anonymous board
        app.project.updateCurrentPreviewOnBackend();

        if(services === true){


            $.confirm({
                title: 'Log In',
                content: content_text,
                theme: 'modern auth'+(!content_text.length ? ' no-content' : ''),
                useBootstrap: false,
                animateFromElement: false,
                boxWidth: '80%',
                closeIcon: true,
                columnClass: 'plotboard',
                buttons: buttonsCollection
            });
        }
        else {
            $.confirm({
                title: 'Please log in first',
                content: content_text,
                theme: 'modern single-button left-aligned',
                boxWidth: '80%',
                closeIcon: true,
                animateFromElement: false,
                buttons: {
                    yes: {
                        text: 'One click log in',
                        action: function () {
                            setTimeout(function () {
                                $('#log-in').trigger('click');

                                ga('send', 'event', {
                                    eventCategory: 'Authorization',
                                    eventAction: 'One Click Sign In Pushed'
                                });
                                app.analytics.write('Authorization', 'One Click Sign In Pushed');

                            }, 100);
                        }
                    },
                }
            });
        }
    },

    buttonsCollection :{
        google: {
            keys: ['g'],
            text: 'Sign in with Google',
            btnClass: 'btn-google',
            action: function () {
                // ga('send', 'event', {eventCategory: 'Authorization', eventAction: 'Select auth way', eventLabel: 'google' });
                // app.analytics.write('Authorization', 'Google click');
                auth.signIn('google');
            }
        },
        facebook: {
            keys: ['f'],
            text: 'Sign in with Facebook',
            btnClass: 'btn-facebook',
            action: function () {
                // ga('send', 'event', {eventCategory: 'Authorization', eventAction: 'Select auth way', eventLabel: 'facebook'});
                // app.analytics.write('Authorization', 'Facebook click');
                auth.signIn('facebook');
            }
        },
        email: {
            keys: ['e'],
            text: 'Sign in with Email',
            btnClass: 'btn-email',
            action: function () {
                // ga('send', 'event', {eventCategory: 'Authorization', eventAction: 'Select auth way', eventLabel: 'email'});
                // app.analytics.write('Authorization', 'Email click');

                // $('body').unbind('auth_refresh').on('auth_refresh', function () {
                //     document.location.reload();
                // });


            }
        },
        apple:{
            keys: ['a'],
            text: 'Sign in with Apple',
            btnClass: 'btn-apple',
            action: function () {
                app.auth.signIn('apple');
            }
    }
}
};