<template>
    <label class="switch">
        <input type="checkbox" v-model="isChecked" @change="toggleSwitch(toggleActionParams)">
        <span class="slider round"></span>
    </label>
</template>

<script>
// @ is an alias to /src
import AppToggleOption from "@/components/AppToggleOption";

export default {
    name:       'AppSwitch',
    props:      {
        actionParams: Object,
        toggleAction: Function,
        isActive:     Boolean,
    },
    data(){
        return{
            isChecked: this.isActive,
        }
    },
    computed: {
        toggleActionParams(){
            return {...this.actionParams, checked:this.isChecked }
        }
    },
    methods:{
        async toggleSwitch(params){
            await this.toggleAction(params);
            this.isChecked=this.isActive;
        }
    },
    mounted() {
        // console.log(this.isActive, this.isChecked);
    }
}
</script>

<style lang="scss">

$swWidth: 40px;
$swHeight: $swWidth * 0.5;
.switch {
    position: relative;
    display: inline-block;
    width: $swWidth;
    height: $swHeight + 4px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $bg_color_lighter;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: $swHeight - 4px;
    width: $swHeight - 4px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: $highlight_color;
}

input:focus + .slider {
    box-shadow: 0 0 2px $highlight_color;
}

input:checked + .slider:before {
    -webkit-transform: translateX($swHeight - 4px);
    -ms-transform: translateX($swHeight - 4px);
    transform: translateX($swHeight - 4px);
}

/* Rounded sliders */
.slider.round {
    border-radius: $swHeight + 4px;
}

.slider.round:before {
    border-radius: 50%;
}

</style>