<template>
    <div class="bottom_menu">
        <MenuButton target="portfolio" label="My portfolio"/>
<!--        <MenuButton target="feed" label="My feed"/>-->
<!--        <MenuButton target="success" label="My success"/>-->
        <MenuButton target="leaderboard" label="Leaderboard"/>
    </div>
</template>

<script>
import MenuButton from "@/components/AppMenuButton";

export default {
    name:       "AppMenu",
    components: {
        MenuButton
    }

}
</script>

<style lang="scss">
.bottom_menu {
    background-color: $bg_color_lighter;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 100%;
    padding: 5px 0 10px 0;
    padding-bottom: env(safe-area-inset-bottom);
    transform: translate3d(0, 0, 0);
    z-index: 1000;
    @media screen and (min-width: 1024px) {
        max-width: 100px;
        left: 50%;
        flex-direction: column;
        transform: translateX(-510px);
        padding: 10px 0 5px 0;
        height: auto;
        gap: 10px;
        top: 0;
    }

    @media (hover: none) and (pointer: coarse) and (max-width: 500px) and (max-height: 630px) {
        display: none;
    }
}
</style>