<template>
    <div class="portfolio_details">
        <div class="portfolio_details__header">
            <app-close-button @click="$router.replace('/')" class="fixed"/>
            <user-avatar/>
            <div>
            <h1>{{ chart_symbol }}</h1>
            <div class="company_name">{{ user_name }} {{user_id}}</div>
            </div>
        </div>


        <Chart :item="item"/>
        <ChartRange/>
        <my-stock-info
                :hideArrow="true"
                :plPercent="profitLossPercent"
                :fNum="addDollarSign(portfolioValue)"
                :sNum="'('+addDollarSign(unrealizedGainPeriod.total)+')'"
                desc="Total Balance"
                style="margin-top: 18px"

        />
        <div>Life data based on current stock prices</div>
        <div>(updated every 15 min)</div>
        <div class="stock_info" v-if="portfolioStats">
            <div class="border-right">
                <stock-details-info :label="'Stock Value'">{{ addDollarSign(totalStockValue) }}</stock-details-info>
                <stock-details-info :label="'Total Gain'">{{ addDollarSign(totalGain) }}</stock-details-info>
                <stock-details-info :label="'Unrealized Gain'">{{ addDollarSign(totalUnrealizedGain) }}</stock-details-info>
                <stock-details-info :label="'Realized Gain'">{{ addDollarSign(totalRealizedGain) }}</stock-details-info>
            </div>
            <div>
                <stock-details-info :label="'Cash'">{{ addDollarSign(cash) }}</stock-details-info>
                <stock-details-info :label="'Gain '+chart_range">{{ addDollarSign(totalGainPeriod) }}</stock-details-info>
                <stock-details-info :label="'Unrealized Gain '+chart_range">{{ addDollarSign(unrealizedGainPeriod.total) }}</stock-details-info>
                <stock-details-info :label="'Realized Gain '+chart_range">{{ addDollarSign(realizedGainPeriod) }}</stock-details-info>
            </div>
        </div>
        <br>
        <div>Ranking data</div>
        <div>Updated on {{ portfolioStats.last_changes }}</div>
        <div><b>Status:</b> {{ capitalize(activity_status.name)}}</div>
        <div>{{ activity_status.msg }}</div>
        <buy-button-simple
                v-if="showNotifyMe"
                @click="notifyMe()"
                :icon="['fas','bell']"
                btn-text="Notify Me"
                btnClass="green small"
        />

        <div class="stock_info" v-if="portfolioStats">
            <div class="border-right">
                <stock-details-info :label="'Stock Value'">{{ addDollarSign(portfolioStats.stock_value) }}</stock-details-info>
                <stock-details-info :label="'Prof Week'">{{ moneyRound(portfolioStats.profitability_week) }}%</stock-details-info>
                <stock-details-info :label="'Prof Year'">{{ moneyRound(portfolioStats.profitability_year) }}%</stock-details-info>
                <stock-details-info :label="'Profitable Weeks'">{{ portfolioStats.profitable_weeks }}</stock-details-info>
                <stock-details-info :label="'Total Weeks'">{{ portfolioStats.total_weeks }}</stock-details-info>
            </div>
            <div>
                <stock-details-info :label="'Cash'">{{ addDollarSign(portfolioStats.money) }}</stock-details-info>
                <stock-details-info :label="'Gain Week'">{{ addDollarSign(portfolioStats.gain_week) }}</stock-details-info>
                <stock-details-info :label="'Gain Year'">{{ addDollarSign(portfolioStats.gain_year) }}</stock-details-info>
                <stock-details-info :label="'P&L Week'">{{ addDollarSign(portfolioStats.unrealized_gain_week) }}</stock-details-info>
                <stock-details-info :label="'P&L Year'">{{ addDollarSign(portfolioStats.unrealized_gain_year) }}</stock-details-info>
            </div>
        </div>
        <div v-else-if="!isPortfolio" class="emptyMsg">
            Loading current {{ chart_symbol }} data...
        </div>
        <template v-if="!isPortfolio">
            <h2>Trade history</h2>
            <trade-history-table/>
        </template>

    </div>
</template>

<script>
// @ is an alias to /src
import Chart from "@/components/Chart";
import ChartRange from "@/components/ChartRange";
import {
    getPnlPercents, getPortfolioUnrealizedPeriodPL, getPrevCloseTime,
    moneyRound
} from "@/assets/js/helper_functions";
import StockDetailsInfo from "@/components/StockDetailsInfo";
import {financial, abbreviateNumber, addDollarSign, capitalize} from "@/assets/js/filters";
import {mapActions, mapState, mapGetters, mapMutations} from "vuex";
import TradeHistoryTable from "@/components/TradeHistoryTable";
import AppCloseButton from "@/components/AppCloseButton";
import MyStockInfo from "@/components/MyStockInfo";
import UserAvatar from "@/components/UserAvatar";
import {
    getCurrentCash,
    getTotalGain,
    getTotalStocksValue,
    getUnrealizedTotalGain
} from "@/assets/js/portfolio_functions";
import AppButton from "@/components/AppButton";
import BuyButtonSimple from "@/components/BuyButtonSimple";

export default {
    name:       'PortfolioDetails',
    components: {
        AppButton,
        BuyButtonSimple,
        UserAvatar,
        MyStockInfo,
        AppCloseButton,
        TradeHistoryTable,
        StockDetailsInfo,
        Chart,
        ChartRange,
    },
    data() {
        return {
            buyModal:   false,
            dealType:   'buy',
            dataLoaded: false,
            toggleOptions: {price:'Price',value:'Value'},
            chartMode: 'Price',
        }
    },
    computed: {
        ...mapGetters(['user_stats','user_name','user_id','activity_status','push_options', 'allDeals','noPushNotify']),
        ...mapState(['chart_symbol', 'chart_range', 'current_stock_prices', 'symbols', 'rest_api_url', 'portfolio_value', 'portfolio_deals', 'extra_cash', 'profit_loss', 'stock_prices','chart_info','money', 'initialCapital']),
        isPortfolio() {
            return this.chart_symbol == 'My Portfolio'
        },
        portfolioStats() {
            return this.user_stats;
        },
        closePrice: function () {
            return null;
        },
        portfolioValue() {
            return this.portfolio_value.current?.total ?? 0;

        },
        profitLossPercent: function () {
            return getPnlPercents(this.unrealizedGainPeriod, this.portfolioValue);
        },
        cash(){
          return getCurrentCash(this.allDeals, this.initialCapital);
        },
        totalStockValue(){
            return   getTotalStocksValue(this.portfolio_deals, this.current_stock_prices);
        },
        totalGain(){
            return getTotalGain(this.allDeals, this.current_stock_prices, false, this.initialCapital);
        },
        totalUnrealizedGain(){
            return getUnrealizedTotalGain(this.portfolio_deals, this.current_stock_prices);
        },
        totalRealizedGain(){
          return this.totalGain - this.totalUnrealizedGain;
        },
        unrealizedGainPeriod() {
            let pl = getPortfolioUnrealizedPeriodPL(this.portfolio_deals, this.current_stock_prices, this.stock_prices, this.chart_range);
            return pl;
        },
        totalGainPeriod() {
            let endDate = getPrevCloseTime(this.chart_range);
            let prevTotalGain = getTotalGain(this.allDeals, this.stock_prices, endDate, this.initialCapital);
            return this.totalGain - prevTotalGain;
        },
        realizedGainPeriod() {
            let endDate = getPrevCloseTime(this.chart_range);
            let prevTotalGain = getTotalGain(this.allDeals, this.stock_prices, endDate, this.initialCapital);
            return this.totalGainPeriod - this.unrealizedGainPeriod.total;
        },
        item:function(){
            return {
                info:this.portfolioStats,
                amount:this.portfolioValue,
                value:this.portfolioValue,
                price:this.closePrice,
            }
        },
        showNotifyMe(){
            if (this.noPushNotify) return false;
            return this.push_options.status_change_msg==0
        }
    },
    methods:  {
        abbreviateNumber,
        addDollarSign,
        financial,
        moneyRound,
        capitalize,
        ...mapActions(['getCurrentStockData', 'setChartRange','getCurrentPortfolioValue','getFcmToken']),
        ...mapMutations(['toggleChartInfo', 'setChartInfo', 'activeGuideBubble','setChartSymbol']),
        async loadCurrentStockData() {
            this.setChartInfo('Value');

            if (!this.portfolioStats) {
                await this.getCurrentStockData(this.chart_symbol);
            }
            if (this.portfolioStats) {
                this.dataLoaded = true;
                return;
            } else {
                await this.loadCurrentStockData();
            }
        },
        returnToHome() {
            this.buyModal = false;
            this.$router.push('/');
        },
        toggleChartMode(){
            this.toggleChartInfo();
        },
        isToggleActive(option){
            return this.chart_info == option;
        },
        closeBuyStockModal(){
            this.activeGuideBubble(false);
            this.buyModal = false;
        },
        async notifyMe(){
            await this.getFcmToken({action:'subscribeToFCMtopic', extra: {topic:'status_change_msg'}});
            // Notification.requestPermission().then((result) => {
            //     if (result === 'granted') {
            //         const options = {
            //             body: 'Hello how are you',
            //             icon: ''
            //         }
            //         let note = new Notification('This is a test notification', options);
            //     }
            // });
        }
    },
    async created() {
        this.setChartSymbol('My Portfolio');

    },
    async mounted() {
        await this.loadCurrentStockData();
        await this.getCurrentPortfolioValue();
    },
}
</script>

<style lang="scss">

.buy_container {
    display: flex;
    justify-content: space-evenly;
}

.portfolio_details {
    //height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: stretch;
    position: relative;
    padding: 25px 0 50px 0;
    //overflow-y: auto;

    &__header {
        display: flex;
        padding: 10px;
        position: sticky;
        top: 0;
        text-align: left;
        min-height: 60px;
        z-index: 100;
        background-color: $bg-color;
        padding-top: max(10px, var(--safe-top));

        .avatar{
            $size:65px;
            width: $size;
            height: $size;
            margin: 0 10px 0 0;

            &__photo{
                width: $size - 10px;
                height: $size - 10px;
            }
        }

        h1 {
            margin: 0;
        }

        .user_name{
            line-height: 100%;
            font-size: 0.9em;
        }
    }
}

.buy_stock_button {
    background-color: $highlight_color;
    width: 50px;
    height: 50px;
    margin: 10px;
    border-radius: 8px;
    font-size: 30px;
}

input[type=number], input[type=search] {
    background: white;
    border: 1px solid #888888;
    border-radius: 0.3em;
    outline: none;
    font-size: 1em;
    padding: 0.75em 1em;
    width: 100%;
    max-width: 160px;
    box-sizing: border-box;
    display: inline-block;

    &.error {
        border: 1px solid red;
    }

    &.wobble {
        animation: wobble 750ms linear 1;
    }

}

</style>