<template>
    <div class="backdrop">
        <div class="popup">

            <app-close-button @click="$emit('close')"/>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import AppCloseButton from "@/components/AppCloseButton";

export default {
    name:       "AppModal",
    components: {AppCloseButton},
    created() {
        document.body.classList.add("no_scroll");
    },
    unmounted() {
        document.body.classList.remove("no_scroll");
    }
}
</script>

<style lang="scss">
.no_scroll {
    overflow: hidden;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: $bg_color;
    border-radius: 14px;
    z-index: 150;
    box-sizing: border-box;
    //padding: 50px 10px 20px;
    padding: 0;
    border: 1px solid $bg_color_lighter;
    max-width: 640px;
    max-height: 95%;
    overflow: hidden;
  display: flex;
  flex-direction: column;
}

.gray{
    .popup{
        background-color: $bg_color_lighter;
    }
}

.backdrop {
    position: fixed;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.3s ease;
    .popup{
        transition: transform 0.3s ease;
    }
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
    .popup{
        transform: translate(-50%, 150%);
    }
}

.modal-leave-from,
.modal-enter-to {
    .popup{
        transform: translate(-50%, -50%);
    }
}

</style>