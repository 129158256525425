import {moneyRound} from "@/assets/js/helper_functions";

export function financial(value, forceFractions){
    if (typeof value !== 'number') return value;
    // if (value < 99 || forceFractions) return value.toFixed(2).toLocaleString('en-GB');
    if (value < 99 || forceFractions) return value.toLocaleString('en-GB');
    return Math.round(value).toLocaleString('en-GB'); // Format the number with spaces between thousands
}

export function capitalize(string){
    if (!string) return '';
    return string.charAt(0).toUpperCase() + (string.substring(1) ?? '');
}

export function abbreviateNumber(value) {
    let newValue = value;
    if (value >= 1000)
    {
        let suffixes = ["", "k", "m", "b","t"];
        let stringNum = ''+Math.floor(value);
        let suffixNum = Math.floor( (stringNum.length-1)/3 );
        let shortValue = '';
        // for (let precision = 4; precision >= 1; precision--) {
        //     shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
        //     let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
        //     if (dotLessShortValue.length <= 4) { break; }
        // }
        shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(3));
        if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
    }
    else {
        newValue = moneyRound(value);
    }
    return newValue;
}

export function addDollarSign(value) {
    if (moneyRound(value) < 0) {
        return '-$'+ abbreviateNumber( Math.abs(value) );
    }
    return '$' + abbreviateNumber( value );
}


export function numberToOrder($num) {
    let specialCases = {
        1: '1st',
        2: '2nd',
        3: '3rd'
    }
    return specialCases[$num] ?? ''+$num+'th';
}