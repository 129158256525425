<template>
    <div class="backdrop ontop" @click="closeInfo();">
        <div class="info_popup">
            <app-close-button v-if="!isLocked" @click="closeInfo()"/>
            <div :class="iconClass"><i></i></div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import AppCloseButton from "@/components/AppCloseButton";
import {mapMutations} from "vuex";

export default {
    name:       "AppInfoWindow",
    components: {AppCloseButton},
    props:      {
        iconClass: String,
        timeout: Number,
    },
    computed:{
        isLocked(){
            return this.timeout === 0;
        }
    },
    methods:    {
        ...mapMutations(['setInfoMsg']),
        closeInfo() {
            if (this.isLocked) return;
            this.setInfoMsg({msg:null});
        },
        autoClose() {
            if (this.isLocked) return;
            setTimeout(() => {
                this.closeInfo();
            }, this.timeout);
        }
    },
    async created() {
        localStorage.onboarding = false; // when a message pops up it closes the onboarding bubble
        this.autoClose();
    },
}
</script>

<style lang="scss">
.no_scroll {
    overflow: hidden;
}

.backdrop.ontop{
    z-index: 150;
}

.info_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 94%;
    transform: translate(-50%, -50%);
    background-color: $bg_color_lighter;
    border-radius: 14px;
    z-index: 150;
    box-sizing: border-box;
    padding: 25px 10px;
    border: 1px solid $bg_color_lighter;
    max-width: 640px;
    max-height: 95%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-size: 1.1em;

    h2 {
        //color: $highlight_color;
    }

    .buy {
        color: $up_color;
    }

    .sell {
        color: $down_color;
    }

    .big {
        font-size: 2em;
    }

    .success {
        text-align: center;

        i {
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('~@/assets/checkbox.png');
            background-color: transparent;
            background-size: contain;
            width: 60px;
            height: 60px;
            display: inline-block;
        }
    }
}

.info-enter-active,
.info-leave-active {
    transition: opacity 0.1s ease;

    .info_popup {
        transition: all 0.5s ease 0.2s;
    }
}

.info-enter-from,
.info-leave-to {
    opacity: 0;

    .info_popup {
        opacity: 0;
        //transform: translate(-50%, -100%);
    }
}

.info-leave-from,
.info-enter-to {
    .info_popup {
        //transform: translate(-50%, -50%);
    }
}

</style>