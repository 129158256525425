<template>
    <div class="avatar">
        <div class="avatar__bg" :class="frame_color">
            <div class="avatar__photo">
<!--                <img src="~@/assets/portrait_placeholder.png" alt="">-->
                <img :src="avatar_path" alt="">
            </div>
        </div>
        <div v-if="user_premium" class="avatar__protrader">Pro</div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {avatarPath} from "@/assets/js/helper_functions";

export default {
    name: "UserAvatar",
    computed:{
        ...mapState(['rest_api_url']),
        ...mapGetters(['activity_status','user_avatar','user_premium']),
        frame_color(){
            return 'rank'+this.activity_status.num;
        },
        avatar_path(){
            return this.user_avatar ? avatarPath(this.rest_api_url, this.user_avatar) : "/img/profile_placeholder_big.svg";
        },
    }
}
</script>

<style lang="scss">

.avatar{
    $size: 130px;
    width: $size;
    height: $size;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;

    &__bg{
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
        height:$size;

    }

    &__photo{
        width: floor($size * 0.85);
        height: floor($size * 0.85);
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid $bg_color;
        img{
            width: 100%;
            height: auto;
        }
    }

    &__protrader{
        position: absolute;
        right: 2px;
        bottom: $size * 0.5;
        background-color: $bg_color_lighter;
        color: #fff;
        border-radius: 8px;
        padding: 1px 6px;
        font-size: 12px;
        border: 2px solid $highlight_color;
    }
}

.rank5 {
    $c1: #E1A28C;
    $c2: #DC7754;
    $c3: #DC5C30;
    $c4: #E94209;
    background: linear-gradient(to right, $c1 0%, $c1 25%, $c2 25%, $c2 50%, $c3 50%, $c3 75%, $c4 75%, $c4 100%);
}

.rank4 {
    $c1: #FFE6D2;
    $c2: #FFD0AC;
    $c3: #FEA561;
    $c4: #FF7C17;
    background: linear-gradient(to right, $c1 0%, $c1 25%, $c2 25%, $c2 50%, $c3 50%, $c3 75%, $c4 75%, $c4 100%);
}

.rank3 {
    $c1: #E9E0BA;
    $c2: #E2D7AA;
    $c3: #E2C75A;
    $c4: #F1C101;
    background: linear-gradient(to right, $c1 0%, $c1 25%, $c2 25%, $c2 50%, $c3 50%, $c3 75%, $c4 75%, $c4 100%);
}

.rank2 {
    $c1: #E5F3C9;
    $c2: #CBE19E;
    $c3: #A2CF47;
    $c4: #8ACE01;
    background: linear-gradient(to right, $c1 0%, $c1 25%, $c2 25%, $c2 50%, $c3 50%, $c3 75%, $c4 75%, $c4 100%);
}

.rank1 {
    $c1: #B8EFBC;
    $c2: #86E195;
    $c3: #5BB967;
    $c4: #00B507;
    background: linear-gradient(to right, $c1 0%, $c1 25%, $c2 25%, $c2 50%, $c3 50%, $c3 75%, $c4 75%, $c4 100%);
}

</style>