<template>
    <div class="buy_button_container">
        <button :class="['buy_button_simple', btnClass, btnDisabled ? 'disabled': '']">
            <div class="buy_button__margins">
                <span>{{ btnText }}</span>
                <div :class="{'active':active}"><span>{{ btnText }}</span></div>
            </div>
        </button>
    </div>
</template>

<script>

export default {
    name:  "BuyButtonSimple",
    props: {
        btnClass:    String,
        btnText:     String,
        btnDisabled: Boolean,
    },
    data() {
        return {
            active: false,
            timer:  null,
        }
    },
    methods:  {
        delayedAction() {
            if (this.btnDisabled) {
                console.log('buttonDisabled');
                this.$emit('buttonDisabled');
                return;
            }
            console.log('Started Timeout', this.active, this.timer);
            if (this.active) return;
            let timeDelay = 800;
            this.active = true;
            let action = () => {
                if (this.active) this.$emit('buyAction')
            };
            this.timer = setTimeout(function () {
                    action();
                }
                , timeDelay);


        },
    },
    computed: {
        hasIcon() {
            return this.icon && this.icon != [];
        }
    }
}
</script>

<style lang="scss">

.buy_button_container{
    z-index: 50;
}

.buy_button_simple {
    $width: 156px;
    border: none;
    appearance: none;
    position: relative;
    background-color: $bg_color_lighter;
    color: $base_color;
    min-height: 48px;
    min-width: $width;
    //max-width: 156px;
    padding: 8px 12px;
    flex-grow: 1;
    margin: 10px 0;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box !important;
    //overflow: hidden;
    border: none;
    transition: all 0.5s ease;
    cursor: pointer;
    font-size: 20px;

    &.small {
        font-size: 16px;
        min-height: 40px;
    }

    &.wide {
        min-width: 300px;
    }

    &.wider {
        min-width: 200px;
    }

    span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        white-space: nowrap;
    }

    .buy_button__margins>div {
        position: absolute;
        width: 8px;
        left: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        transition: width 0.3s cubic-bezier(.47, 1.64, .41, .8) 0.2s;

        > span {
            display: none;
        }

        &.active {
            transition: width 0.8s linear;
            width: 100%;

            > span {
                display: block;
                left: $width * 0.5
            }
        }

    }

    &.dark{
        background-color: $bg_color;
    }

    &.red .buy_button__margins>div {
        background-color: $down_color;
        color: $base_color;
    }

    &.green .buy_button__margins>div {
        background-color: $up_color;
        color: $base_color;
    }

    &.yellow .buy_button__margins>div {
        background-color: $highlight_color;
        color: $base_color;
    }

    &.disabled {
        background-color: #555;
        color: #aaa;

        .buy_button__margins>div {
            transition: width 0.5s ease;
            width: 0;
        }
    }
}

</style>