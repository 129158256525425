<template>
    <button class="button" :class="btnClass">
        <fa-icon v-if="hasIcon" :icon="icon"/>
        <span><slot>Button</slot></span>
    </button>
</template>

<script>
export default {
    name:     "AppButton",
    props:    {
        btnClass: String,
        icon:     Array,
    },
    computed: {
        hasIcon() {
            return this.icon && this.icon != [];
        }
    }
}
</script>

<style lang="scss">
.button {
    border: none;
    appearance: none;
    position: relative;
    background-color: $base_color;
    min-height: 44px;
    min-width: 44px;
    padding: 8px 12px;
    flex-grow: 1;
    margin: 10px;
    border-radius: 4px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box !important;
    transition: all 0.5s ease;
    box-shadow: 1px 1px 6px #00000057;

    &.hasIcon {
        justify-content: left;
    }

    &.red {
        background-color: $down_color;
        color: $base_color;
        max-width: 200px;
    }

    &.green {
        background-color: $up_color;
        color: $base_color;
        max-width: 200px;
    }

    &.big{
        font-size: 1.3em;
        font-weight: bold;
        border-radius: 6px;
    }

    svg {
        padding-right: 15px;
        path{
            transition: all 0.5s ease;
        }
    }

    &:disabled, [disabled] {
        border: none;
        background-color: #555;
        color: #aaa;
        svg {
            path{
                fill: #aaa;
            }
        }
    }
}

.btn-google,
.btn-facebook,
.btn-apple,
.btn-email {
    color: white;
    //margin: 1em !important;
    font-weight: 500 !important;
    text-transform: none !important;
    font-size: 19px;
    min-width: 240px;
    justify-content: left;
}

.btn-google {
    background-color: $color-google;
}

.btn-facebook {
    background-color: $color-facebook;
}

.btn-apple {
    background-color: $color-apple;
}

.btn-email {
    color: $bg_color;
}

</style>