<template>
    <div class="donut">
        <div class="donut__chart" :style="{ width: size+'px', height: size+'px'}">
            <svg width="100%" height="100%" viewBox="0 0 42 42" class="donut__svg">
                <circle class="donut__hole" cx="21" cy="21" :r="radius" fill="#fff"></circle>
                <circle class="donut__segment" cx="21" cy="21"
                        fill="transparent"
                        stroke-linecap="round"
                        transform="rotate(-90 21 21)"
                        :r="strokeRadius"
                        :stroke="strokeColor"
                        :stroke-width="strokeWidth"
                        :stroke-dasharray="dashLength"
                        :stroke-dashoffset="full"
                >
                </circle>
            </svg>
            <span :style="{ fontSize: size/3.5 + 'px' }">{{ val }}</span>
        </div>
        <div class="donut__title" :style="{ fontSize: size/3.5 + 'px' }">{{ title }}</div>
    </div>


</template>

<script>
import * as ch from "@/assets/js/chart_functions.js";
import moment from "moment";
import {mapMutations, mapActions, mapState} from "vuex/dist/vuex.mjs";
import {getLastObjValue} from "@/assets/js/helper_functions";
import {
    getChartRangeForApi,
    initSmallChart,
    getSmallChartPeriod,
    updateDoughnutChartData
} from "@/assets/js/chart_functions.js";
import AppLoadingMsg from "@/components/AppLoadingMsg";


export default {
    name:  'ChartDoughnut',
    props: {
        max:   Number,
        val:   Number,
        size:  Number,
        title: String,
        ranking: Boolean,
    },
    data() {
        return {
            radius:      20,
            strokeWidth: 2,
            chartData:   null,
        }
    },
    methods:  {
        ...mapActions(['getPortfolioValue', 'getStockPrices']),
    },
    computed: {
        ...mapState(['chart_range']),
        strokeRadius() {
            return this.radius - this.strokeWidth / 2 + 0.1;
        },
        dashLength() {
            return 2 * this.strokeRadius * Math.PI;
        },
        chartValue(){
            return this.ranking ?   1 + this.max - this.val : this.val;
        },
        full() {
            // console.log(this.title, this.chartValue);
            return this.dashLength - (this.chartValue * this.dashLength) / this.max;
        },
        percent() {
            return (this.chartValue * 100) / this.max;
        },
        strokeColor() {
            let p = this.percent;
            if (p < 20) return '#E94209';
            if (p < 40) return '#FF7C17';
            if (p < 60) return '#F1C101';
            if (p < 80) return '#8ACE01';
            return '#00B507';
        }

    },
    async mounted() {

    },
}

</script>

<style lang="scss">


.donut {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    &__chart{
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        z-index: 50;
    }

    span {
        z-index: 20;
    }

    &__svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__hole {
        fill: $bg_color_lighter;
    }

    &__segment {

    }

    &__title {
        padding-top: 10px;
    }
}


</style>