<script>
    import BuyButtonSimple from "@/components/BuyButtonSimple.vue";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "UserProfile",
        props: {
            type: String,
        },
        components: {BuyButtonSimple},
        methods:{
            ...mapActions(['manageSubs']),
            getMoreCash() {
                this.$FirebaseAnalytics.logEvent({name: 'USER_get_cash'});
                this.$router.replace('/buycash');
            },
            becomePro() {
                this.$FirebaseAnalytics.logEvent({name: 'USER_become_pro'});
                this.$router.replace('/buypremium');
            },
            manageSubscription() {
                this.$FirebaseAnalytics.logEvent({name: 'USER_manage_subscription'});
                this.manageSubs();
            },
        },
        computed:{
            ...mapGetters(['productProTrader']),
            isSubscribed(){
                return this.productProTrader?.owned;
            }
        },
    }
</script>

<template>
    <div class="product_card">
        <div class="extra_cash" v-if="type=='extraCash'">
            <img src="/images/adventure/07_storefront.png" alt="">
            <h2>Buy $100 000 game cash</h2>
            <buy-button-simple @click="getMoreCash()"
                               btnClass="green small"
                               btn-text="Get more cash"
            />
        </div>
        <div class="pro_trader" v-if="type=='proTrader'">
            <img src="/images/adventure/success.png" alt="">
            <h2>Become a Pro Trader</h2>
            <ul class="product_card__descr">
                <li>Trade premium stocks</li>
                <li>Get a PRO avatar</li>
                <li>After-hours trading</li>
            </ul>
            <buy-button-simple @click="becomePro()"
                               btnClass="green small"
                               btn-text="Become Pro"
                               v-if="!isSubscribed"
            />
           <buy-button-simple @click="manageSubscription()"
                               btnClass="yellow small wider"
                               btn-text="Manage Subscription"
                               v-if="isSubscribed"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">

.product_card {
    background-color: $highlight_color;
    background: linear-gradient(360deg, black, #2c2d33, #2c2d33);
    border-radius: 14px;
    box-sizing: border-box;
    padding: 15px;
    min-width: 190px;
    max-width: 320px;
    align-items: center;
    justify-content: center;


    font-size: 1em;
    //white-space: nowrap;
    font-weight: 500;
    z-index: 40;
    transition: all 1s ease;
    box-shadow: $shadow_main;
    margin: 15px;

    img{
        width: 100%;
        height: auto;
        max-width: 90%;
        max-height: 65%;
        min-height: 40%;
        object-fit: cover;
        object-position: bottom;
    }

    &__descr{
        text-align: left;
    }

    .pro_trader, .extra_cash{
        display: flex;
        flex-direction: column;
    }
}
</style>