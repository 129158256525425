<template>
    <div class="static-page">
        <app-close-button @click="handleBack('/')"/>
        <component :is="page"></component>
    </div>
</template>

<script>
// @ is an alias to /src
import AppCloseButton from "@/components/AppCloseButton";
import {capitalize} from "@/assets/js/filters";
import StaticTos from "@/components/StaticTos";
import StaticPrivacy from "@/components/StaticPrivacy";
import StaticSupport from "@/components/StaticSupport.vue";

export default {
    name:       'StaticPage',
    components: {StaticPrivacy, StaticTos, AppCloseButton, StaticSupport},
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fromRoute = from;
        })
    },
    computed:{
      page(){
          return 'Static'+capitalize(this.$route.params.slug);
      }
    },
    methods:{
        handleBack (fallback) {
            if (!this.fromRoute) {
                this.$router.push(fallback);
            } else {
                this.$router.back();
            }
        },
    }
}
</script>

<style lang="scss">
.static-page{
    text-align: left;
}
</style>