<template>
  <div class="loadingMsg">
    <div class="message"><slot>Loading</slot></div>
  </div>
</template>

<script>
export default {
    name: "AppLoadingMsg"
}
</script>

<style lang="scss">

.loadingMsg{
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: #f00;
  .message {
    font-size: 1.2em;
    text-align: center;
    //background-color: red;
  }
}

</style>