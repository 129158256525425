/* eslint-disable no-console */

import { Workbox } from "workbox-window";
let wb;
if ("serviceWorker" in navigator && location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
  wb = new Workbox(`${process.env.BASE_URL}firebase-messaging-sw.js`);
  wb.addEventListener("controlling", () => {
    window.location.reload();
  });
  wb.register();
} else {
  wb = null;
}
export default wb;