<template>
    <div class="search_stock_view">
        <transition name="modal">
            <app-modal v-if="showModal" @close="showModal = false">
                <stock-details/>
            </app-modal>
        </transition>
        <div class="search_stock">
            <app-close-button @click="$router.replace('/')" />
            <label for="search_symbol">Search Stocks</label>
            <div class="search_stock__container">
                <fa-icon icon="search"/>
                <input id="search_symbol"
                       type="search"
                       ref="search"
                       :value="searchDebounce"
                       @input='evt=>searchDebounce=evt.target.value'
                       autocomplete="off"
                       placeholder="Search...">
            </div>
        </div>
        <div class="search_results" v-if="searchResults.length">
            <div class="header flex">
                <div class="stock" @click="setSort('symbol')">
                    <fa-icon v-if="sortBy=='symbol'" :icon="sortIcon"/>
                    Name
                </div>
                <div class="value" @click="setSort('volume')">
                    <fa-icon v-if="sortBy=='volume'" :icon="sortIcon"/>
                    Volume
                </div>
            </div>
            <template v-for="item in searchResults" :key="item.symbol">
                <search-result-row :item="item"></search-result-row>
            </template>
        </div>
        <div v-else class="emptyMsg">
            Nothing Found
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import {mapMutations, mapState} from "vuex";
import SearchResultRow from "@/components/SearchResultRow";
import {extractArrColumn, getClosePrice, stringCompare} from "@/assets/js/helper_functions";
import StockDetails from "@/views/StockDetails";
import AppModal from "@/components/AppModal";
import AppCloseButton from "@/components/AppCloseButton";
import _debounce from 'lodash/debounce';

export default {
    name: 'BuyStock',
    data() {
        return {
            searchWord:    '',
            showModal:     false,
            sortBy:        'default',
            sortDirection: 'asc',
        }
    },
    components: {
        AppCloseButton,
        StockDetails,
        SearchResultRow,
        AppModal,
    },
    methods:    {
        ...mapMutations(['setStockSearch']),
        setSort(sortBy, direction) {
            if (this.sortBy == sortBy) {
                if (this.sortDirection == 'desc') this.sortBy = 'default';
                console.log(this.sortBy);
                this.toggleSortDirection();
            } else this.sortBy = sortBy;
        },
        toggleSortDirection() {
            if (this.sortDirection == 'asc') this.sortDirection = 'desc';
            else this.sortDirection = 'asc';
        },
    },
    computed:   {
        ...mapState(['symbols']),
        directionMultiplier() {
            return (this.sortDirection == 'asc') ? 1 : -1;
        },
        sortIcon() {
            return (this.sortDirection == 'asc') ? 'sort-up' : 'sort-down';
        },
        liquidSymbols: function () {
            let arr = Object.values(this.symbols ?? {}).filter(s => s.liquid == true && !s.pro);
            return arr;
            // return extractArrColumn(arr, 'symbol');
        },
        searchDebounce: {
            get() {
                return this.searchWord.toLowerCase();
            },
            set: _debounce(function(newValue) {
                this.searchWord = newValue.trim().toLowerCase();
                this.setStockSearch(this.searchWord);
                console.log('debounce',this.searchWord);
            }, 300),
        },
        searchResults: function () {
            let result;
            if (this.searchWord == '') {
                result = this.liquidSymbols;
            } else {
                console.log('Show search results');
                let startWithRegex = new RegExp('^' + this.searchWord, 'i');
                let findInMiddleRegex = new RegExp(this.searchWord, 'i');
                result = this.liquidSymbols.filter(s => startWithRegex.test(s.symbol));
                if (result.length < 50) {
                    let result2 = this.liquidSymbols.filter(s => startWithRegex.test(s.company));

                    result = [...new Set([...result, ...result2.slice(0, 50)])];
                }

                if (result.length < 50) {
                    let result3 = this.liquidSymbols.filter(s => findInMiddleRegex.test(s.symbol) || findInMiddleRegex.test(s.company));

                    result = [...new Set([...result, ...result3.slice(0, 50)])];
                }
            }
            let sortBy = this.sortBy;
            let direction = this.directionMultiplier;
            // console.log(direction);

            result.sort(function (a, b) {
                if (sortBy == 'symbol') return stringCompare(a.symbol, b.symbol) * direction;
                if (sortBy == 'volume') {
                    return (a.vol - b.vol) * direction;
                }
            });
            return result.slice(0, 50);
        },
    },
    mounted() {
        setTimeout(()=>{
            this.$refs?.search.focus();
        }, 1200); // We delay the focus on search input because the virtual keyboard breaks the scrolling if appears too soon.
    }
}
</script>

<style lang="scss">
.search_stock_view{
    position: relative;
}

.search_stock {
    background-color: $bg_color;
    padding: 7px 0 5px;
    text-align: left;
    position: sticky;
    top: 0;
    padding-top: max(7px, var(--safe-top));

    &__container{
        position: relative;
        border-radius: 3em;
    }

    label {
        display: block;
        font-size: 1.75em;
        font-weight: 600;
        padding: 10px 5px 15px;
    }

    #search_symbol {
        background-color: $bg_color_lighter;
        color: $base_color;
        border: none;
        margin: 0;
        padding: 14px 10px 10px 40px;
    }

    .fa-search, .fa-magnifying-glass{
        position: absolute;
        left: 11px;
        top: 11px;
        font-size: 20px;
        color: #777;
    }
}

.search_results {
    padding: 10px 0 100px 0;

    .header{
        border-bottom: 1px solid #ccc;

        .value{
            text-align: right;
        }
    }

    .row {
        //border-bottom: 1px solid $base_color_dim;
        //border-bottom: 1px solid $bg_color_lighter;
        border-bottom: none;
        display: flex;
        justify-items: stretch;
        align-items: center;
        height: 62px;

        &.text-danger {
            background-color: #530000;
        }
    }
}

#search_symbol {
    width: 100%;
    max-width: none;
}

</style>