<template>
    <div @click="openRoute()" :class="[isActive?'active':'', target, 'menu_button']"><i
            :class="['icon-'+target]"></i>{{ label }}
    </div>
</template>

<script>
import router from "@/router";

export default {
    name:     "MenuButton",
    props:    {
        target: String,
        label:  String,
    },
    computed: {
        isActive() {
            return this.$route.name == this.target;
        },
        route() {
            let route = {name: this.target};
            if (this.target == 'leaderboard') {
                route.params = {param: 'Overall'};
            }
            return route;
        },
    },

    methods: {
        openRoute() {
            let eventsMap = {
                'portfolio': 'MENU_portfolio',
                'leaderboard': 'MENU_leaderboard',
            };
            this.$FirebaseAnalytics.logEvent({name: eventsMap[this.target]});
            this.$router.push(this.route);
        }
    },
}
</script>

<style lang="scss">

.menu_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #fff;
    flex-grow: 1;
    width: 25%;
    cursor: pointer;
    font-size: 11px;
    font-weight: 500;
    position: relative;

    &.button_attention{
        border-radius: 15px;
    }

    &.active {
        color: $highlight_color;

        &.portfolio {
            i {
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('~@/assets/portfolio.svg');
                background-color: transparent;
            }
        }
    }

    i {
        display: block;
        width: 24px;
        height: 24px;
        background-size: 90% 90%;
        font-size: 24px;
    }

    &.portfolio {
        i {
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('~@/assets/portfolio_white.svg');
            background-color: transparent;
        }
    }
    @media screen and (min-width: 1024px) {
        width: 100%;
        max-height: 75px;
        padding: 10px 0;
        i{
            $iconSize: 32px;
            width: $iconSize;
            height: $iconSize;
            background-size: 90% 90%;
            font-size: $iconSize;
        }
    }

}

</style>