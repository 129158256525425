<template>
  <div class="exit-tutorial-btn">
      <buy-button-simple
                         btnClass="yellow small"
                         btn-text="Exit tutorial"
      />
  </div>
</template>

<script>
import BuyButtonSimple from "@/components/BuyButtonSimple";
export default {
    name: "ExitTutorialButton",
    components: {BuyButtonSimple}
}
</script>

<style>
.exit-tutorial-btn{
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 1000;
}
</style>