<template>
    <div class="buy_cash_view">

        <div class="buy_cash_view__header">
            <app-close-button @click="closeWindow()" class="fixed"/>
            <h1>{{ productExtraCash?.name ?? 'Buy Cash' }}</h1>
        </div>
        <div v-if="canBuyPlatform">
            <div class="buy_cash_info">
                    <span>{{ productExtraCash?.description ?? 'Buy $100 000 game cash' }}</span>
                    <b>{{totalCost}}</b>
            </div>
            <div class="buy_cash_view__buttons">
                <buy-button-simple
                        v-if="!transactionPending"
                        @click="buyCash()"
                        btnClass="green gray"
                        :btnDisabled="dealAmount == 0"
                        btn-text="Buy"
                />
                <div v-if="transactionPending">
                    <p>Processing payment...</p>
                    <progress></progress>
                </div>
            </div>
        </div>
        <div  v-if="canBuyPlatform==false">
            <cant-buy-platform />
            <div v-if="is_local_dev" @click="testBuyExtraCache" class="button green">Test Buy Extra Cache</div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import {mapMutations, mapState} from "vuex/dist/vuex.mjs";
import {mapActions, mapGetters} from "vuex";
import {financial, capitalize, abbreviateNumber, addDollarSign} from "@/assets/js/filters";
import BuyButton from "@/components/BuyButton";
import AppCloseButton from "@/components/AppCloseButton";
import CantBuyPlatform from "@/components/CantBuyPlatform.vue";
import BuyButtonSimple from "@/components/BuyButtonSimple.vue";

export default {
    name:  'BuyExtraCash',
    data() {
        return {
            ranges:     ['1w', '1m', '3m', '6m', '1y', '2y'],
            dealAmount: 100000,
            price: 'price not defined',
        }
    },
    components: {
        BuyButtonSimple,
        CantBuyPlatform,
        AppCloseButton,
        BuyButton,
    },
    methods:    {
        capitalize,
        financial,
        abbreviateNumber,
        addDollarSign,
        ...mapActions(['purchaseExtraCache','addExtraCashToUser']),
        ...mapMutations(['setInfoMsg','setGuideBubble','tutorialDone','nextOnboardingStep','setLoadingState']),
        async buyCash() {
            console.log('Extra Cash Checkout');
            await this.purchaseExtraCache();
        },
        async testBuyExtraCache(){
            let transaction = {
                nativePurchase: {
                    orderId:          "TEST-CACHE-PURCHASE-668",
                    packageName:      "app.gain4.fun",
                    productId:        "test_gain4fun_buy_cash",
                    purchaseTime:     1685267852392,
                    purchaseToken:    "hiejbkdebhbaaklagkfghdkb.AO-J1OwYAH6FiAgfoFjM_Qbyu3Bzgjwjj_ZdBFhWeUPK_A1LJsjSJSOp_XfRlPmcFcDPNq5bK7NOlm3BTf26NDlB6oQHxSzLLg",
                    quantity:         1,
                    receipt:          `{test data}`,
                    signature:        "URSpEMpO1rwctBBDHyuGSLjXEmeEg0MRtUrYlaauJAckHKGWTxO/YxUc1KU4d/C7KGPAPAfHVr9o/33yDUrPdxs3+VUXT9Hm/97pxEdpNL7Z/kbOUr8xnviyEykh7ahJcCiVTu8Z+ikF+IqpvNArCqh5/aOFI760Sa7JcO06UwfBh93LVds5yzJZltv219by0sxaJc5Q8XIbzvXFPelFpMZXXXlLOXHlMza3Nc63JxREGU+yjlZQzfPBQ6OeNI3qgwX/hBuOae5vLQh0WOG8okBhqPWdL4AIx6yEcLJqa7cn6hFeHonoW1s3XYGTz8CUf/89qmjNrtQeQxnZUUMOzw==",
                },
                platform:       "android-playstore",
                purchaseId:     "hiejbkdebhbaaklagkfghdkb.AO-J1OwYAH6FiAgfoFjM_Qbyu3Bzgjwjj_ZdBFhWeUPK_A1LJsjSJSOp_XfRlPmcFcDPNq5bK7NOlm3BTf26NDlB6oQHxSzLLg",
                state:          "approved",
            };
            this.addExtraCashToUser({transaction, productType:'consumable'})
        },
        closeWindow(){
            let url = localStorage.redirect ?? '/';
            localStorage.removeItem('redirect');
            this.$router.replace(url);
        },
    },
    computed:   {
        ...mapState(['is_loading', 'money','is_local_dev']),
        ...mapState({
            transactionPending: state=>state.iap.transactionPending,
        }),
        ...mapGetters(['canBuyPlatform','productExtraCash']),
        totalCost() {
            return this.productExtraCash?.pricing?.price ?? 'price not found';
        },

    },
    async mounted() {
        console.log('transactionPending',this.transactionPending);
    },
    watch: {
        dealAmount: function () {
            if (this.dealAmount > 0) {
                console.log('dealAmount changed');
            }
        },
    }
}
</script>

<style lang="scss">

.buy_cash_view {
    padding: 20px 15px;
    text-align: left;
    max-width: 460px;
    @media screen and (min-width: 520px){
        margin: auto;
    }

    &__buttons{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        width: 100%;
    }

    &__header {
        background-color: transparent;
        padding: 30px 5px 5px 5px;
        position: relative;
        font-size: 14px;

        h1 {
            margin: 0;
            text-align: center;
        }
    }

    label {
        min-width: 80px;
        display: inline-block;
        font-size: 1em;
        height: 24px;
    }

    input {
        min-width: 180px;
        flex-grow: 1;
    }

    .stock_cost {
        font-weight: 600;
        font-size: 1.2em;
    }

    .cash_row{
        position: relative;
        i.button_attention{
            position: relative;
            background-color: $bg_color_lighter;
            border-radius: 5px;
            padding: 0 5px;
            transition: all 0.5s;
        }
    }

    .amount_input {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        button {
            padding: 3px 12px;
            margin: 3px;
            min-width: 40px;
            flex-grow: 0;
            font-size: 1.2em;
            display: flex;
            justify-content: center;
            background-color: $bg_color;
            color: #fff;
            border: none;
            svg{
                display: block;
                padding: 0;
            }

            &.max{
                font-size: 0.8em;
            }

            &:disabled, [disabled] {
                border: none;
                background-color: #555;
                color: #aaa;
            }
        }

        input#stock_amount {
            flex-grow: 4;
            background-color: $bg_color;
            color: #fff;
            border: none;
            max-width: 100px;
            min-width: 0;
            font-size: 1.2em;
            font-weight: bold;
            line-height: 100%;
            height: 44px;
        }
    }

    .buy_cash_info {

        background-color: $bg_color;
        color: $base_color_dim;
        font-size: 1.3em;
        //margin: 0 -20px;
        padding: 10px 20px;
        position: relative;
        height: 70px;
        max-height: 70px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        max-width: 100%;

        b {
            display: inline-block;
            color: $base_color;
            margin-right: 10px;
            font-weight: 600;
        }

        > div {
            display: flex;
            justify-content: flex-start;
            height: 30px;
        }

        .descr {
            font-size: 0.7em;
            margin-top: -1px;
        }
    }
}



</style>