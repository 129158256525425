<template>
  <div class="success">
    <h1>My Success</h1>
  </div>
</template>

<script>
// @ is an alias to /src
import AppMenu from "@/components/AppMenu";
export default {
  name: 'Success',
  components: {
      AppMenu,
  }
}
</script>
