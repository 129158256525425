<template>
    <div v-if="showItem" class="leaders__row" :class="{'text-danger':hasError, 'active': item.own}">
        <div class="avatar"><img :src="avatarPhoto"></div>
        <div class="info">
            <div class="leaders__badge" v-if="hasBadge">{{ badge }}  - gain {{moneyRound(item.profitability*100,1)}}%</div>
            <div class="leaders__name">{{ item.name }}</div>
        </div>
        <div class="leaders__gain"><b>{{ gainString }}</b></div>
    </div>
</template>

<script>
import {addDollarSign, financial, numberToOrder} from "@/assets/js/filters";
import {
    getStockPriceDifference,
    getClosePrice,
    getCompanyLogo,
    getPnlPercents, moneyRound
} from "@/assets/js/helper_functions";
import {mapState} from "vuex/dist/vuex.mjs";


export default {
    name:       "LeaderTableRow",
    components: {

    },
    props:      {
        item: Object,
        tableMode: String,
    },
    methods:    {
        financial,
        addDollarSign,
        moneyRound,
    },
    computed:   {
        ...mapState([ 'is_loading','rest_api_url']),
        isActive() {
            return false;
        },
        gainString: function () {
            return addDollarSign(this.item?.gain);
        },
        hasError(){
            return false;
        },
        avatarPhoto(){
            console.log('avatar: ',this.item?.photo);
            let photo = this.item?.photo;
            if (!photo) return "/img/profile_placeholder.svg";
            return this.rest_api_url+'/images/avatars/'+photo;
        },
        showItem(){
            return 1;
        },
        hasBadge(){
            return this.item?.rank < 4 || 1;
        },
        badge(){
            return numberToOrder(this.item?.rank)+' place'
        }
    }
}
</script>

<style lang="scss">

.leaders {
    &__row {
        //border-bottom: 1px solid $base_color_dim;
        //border-bottom: 1px solid $base_color_dim;
        display: flex;
        justify-items: stretch;
        align-items: center;
        padding: 10px;

        &.text-danger{
            background-color: #530000;
        }

        &.active {
            background-color: $bg-color_lighter;

            .symbol {
                color: $highlight_color;
            }
        }

        .info{
            display: flex;
            flex-flow: column;
            flex-grow: 2;
            justify-content: center;
            align-items: flex-start;
            padding-left: 20px;
        ;
        }

        .avatar {
            position: relative;
            $d:54px;
            width: $d;
            height: $d;
            max-width: $d;
            min-width: $d;
            border-radius: $d*0.5;
            margin: 0;
            box-shadow: 0 1px 1px #fff7;
            img{
                width: 155%;
                height: 145%;
                position: absolute;
                top: 0;
                object-fit: cover;
                //display: none;
            }

        }

    }

    &__gain {
        color: #fff;
        b{
            font-size: 12px;
            $h: 22px;
            display: block;
            width: $h*3;
            height: $h;
            line-height: $h;
            border-radius: $h*0.5;
            background-color: $bg_color_lighter;
            font-weight: 500;
            white-space: nowrap;
        }
    }

    &__name {
        font-size: 1.1em;
        color: #fff;
        font-weight: 500;
        white-space: nowrap;
        max-width: 170px;
        overflow: hidden;
        @media screen and (min-width: 400px){
            max-width: 220px;
        }
    }

    &__badge {
        font-weight: normal;
        font-size: 12px;
    }
}



</style>