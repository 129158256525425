<template>
    <div class="guide" @click="this.bubble.action" ref="bg" :style="guideStyle">
        <div class="guide__popup" :style="msgStyle" ref="bubble">
            <app-close-button v-if="showClose" @click="setInfoMsg(null)"/>
            <img :src="imagePath" v-if="bubble.img" alt="">
            <div v-html="bubble.msg"></div>
        </div>
        <div class="guide__circle" :style="circleStyle"></div>
<!--        <div class="guide__bg"></div>-->
    </div>
</template>

<script>
import AppCloseButton from "@/components/AppCloseButton";
import {mapMutations, mapState} from "vuex";
import {adventureImagePath, isInViewport} from "@/assets/js/helper_functions";

export default {
    name:       "AppGuideBubble",
    components: {AppCloseButton},
    props:      {
        target: Object, // Coordinates {X,Y} for the target object center
        closeAction: Function,
    },
    data() {
        return {
            distance: 20,
            showClose: false,
        }
    },
    computed: {
        ...mapState({
            bubble: 'guide_bubble',
            host: 'rest_api_url',
        }),
        imagePath(){
            return adventureImagePath(this.host, this.bubble.img);
        },
        guideStyle(){
            let s = this.bubble.guideStyle ?? {};
            let viewContainer = document.querySelector('.view_container').getBoundingClientRect();
            s.height = viewContainer.height+'px';
            return s;
        },
        msgStyle() {
            // let s = {};
            let s = this.bubble.style ?? {};
            let x, y, d, bottom, right, rest;
            let delta = document.getElementById('app').getBoundingClientRect();
            let w = this.bubble.w ?? 100;
            let h = this.bubble.h ?? 200;
            d = this.distance;
            s.width = w+'px';
            s.height = h+'px';

            if (this.bubble.img){
                s.paddingTop = h*0.6+'px';
            }

            if (this.bubble.target == 'center'){
                // s.top = window.scrollY + (window.innerHeight - h)/2 + 'px';
                s.top = (window.innerHeight - w)/2 +'px';
                s.left = '50%';
                s.transform = 'translateX(-50%)';
                return s;
            }

            // ({x, y, bottom, right, ...rest} = this.bubble.target);
            // console.log(this.bubble.targetSelector);
            let target = this.bubble.targetSelector ? document.querySelector(this.bubble.targetSelector)?.getBoundingClientRect() : this.bubble.target;
            ({x, y, bottom, right, ...rest} = target);
            // console.log({x, y, bottom, right});
            // x = this.bubble.target.x;
            // y = this.bubble.target.y;

            if (this.bubble.top) {
                s.top = window.scrollY + y - d - h + 'px';
            } else {
                s.top = window.scrollY + bottom + d + 'px';
            }

            if (this.bubble.left) {
                console.log(x, d, w, delta.x);
                s.left = x - d - w - delta.x+ 'px';
            }
            else if (this.bubble.center){
                // s.left = (right + x- w)/2 - delta.x+ 'px';
                s.left = '50%';
                s.transform = 'translateX(-50%)';
            }
            else{
                s.left = right + d - delta.x + 'px';
            }

            // s.left = x + d + 'px';
            // s.left = '10px';
            console.log(s);
            return s;
        },
        circleStyle() {
            let s = {};

            if (this.bubble.target == 'center'){
                s.display = 'none';
                return s;
            }

            let x, y, d, bottom, right, rest;
            let h = 10;
            let w = 10;
            let delta = document.getElementById('app').getBoundingClientRect();
            let target = this.bubble.targetSelector ? document.querySelector(this.bubble.targetSelector)?.getBoundingClientRect() : this.bubble.target;
            ({x, y, bottom, right, ...rest} = target);
            d = this.distance / 2;
            if (this.bubble.top) {
                s.top = window.scrollY + y - d - h/2 + 'px';
            } else {
                s.top = window.scrollY + bottom + d - h/2 + 'px';
            }

            if (this.bubble.left) {
                s.left = x - d - w/2 - delta.x + 'px';
            }
            else if (this.bubble.center){
                s.left = (right + x- w)/2 - delta.x+ 'px';
            }
            else {
                s.left = right + d - delta.x + 'px';
            }
            return s;
        },
        targetEl(){
          return document.querySelector(this.bubble.targetSelector);
        },
    },
    methods:  {
        // ...mapMutations(['setInfoMsg']),
        bgStyle(){
            let s = {};
            s.top = window.scrollY;
            return s;
        },
        scrollToCenter(){ // Scrolls to center to make the bubble and active element visible if necessary
            setTimeout(() => {
                let bubble = document.querySelector('.guide__popup');
                let targetEl = this.bubble.targetSelector ? this.targetEl : bubble;
                // console.log(bubble,targetEl);
                if (isInViewport(bubble) && isInViewport(targetEl)) return; // If elements are already inside viewport not scrolling;
                bubble.scrollIntoView({
                    behavior: "smooth",
                    block:    "center",
                    inline:   "nearest"
                });
            }, 1000);
        },
        scrollToView(){ // Scrolls to center to make the bubble and active element visible if necessary
            let bubble = document.querySelector('.guide__popup');
            let targetEl = this.bubble.targetSelector ? this.targetEl : bubble;
            // console.log(bubble,targetEl);
            let scrollToPosition = this.bubble.top ? 'end' : 'start';
            targetEl.scrollIntoView({
                behavior: "smooth",
                block:    scrollToPosition,
                inline:   "nearest"
            });
        },
    },
    async created() {
        // let closeInfo = this.setInfoMsg;
        // setTimeout(() => {
        //     closeInfo(null);
        // }, 7000);
    },
    async mounted() {
        // console.log('Showing bubble');
        this.scrollToCenter();
        // this.scrollToView();
        if (!this.bubble.targetAttention) this.$refs.bg.classList.add('dark');
        if (this.bubble.targetSelector) {
            if (this.bubble.targetAttention){
                this.targetEl.classList.add("button_attention");
            }

            if (this.bubble.targetHighlight)
            {
                this.targetEl.classList.add("guide__highlight");
            }
            if (this.bubble.targetParentHighlight)
            {
                this.targetEl.parentElement.classList.add("guide__highlight");
            }
        }
    },
    async beforeUnmount(){
        console.log('Hide Guide bubble');
        if (this.bubble.targetSelector) {
            document.querySelector('.guide__highlight')?.classList.remove("guide__highlight");
            document.querySelector(this.bubble.targetSelector)?.classList.remove("button_attention");
            this.$refs.bg.classList.remove('dark');
        }
    }
}
</script>

<style lang="scss">

.guide {
    $bg: #F6A815;
    position: absolute;
    width: 100%;
    max-width: 100%;
    min-height: 100px;
    height: 100%;
    top: 0;
    left:0;
    overflow: hidden;

    &.dark{
        background-color: #00000099;
        z-index: 100;
    }


    //&__bg{
    //    position: fixed;
    //    background-color: #00000099;
    //    z-index: 100;
    //    width: 100%;
    //    max-width: 100%;
    //    min-height: 100px;
    //    height: 100%;
    //    top: 0;
    //    left:0;
    //}


    &__popup {
        position: absolute;
        //transform: translate(-50%, -50%); // Cannot use translate because the element inside
        background-color: $bg;
        background: linear-gradient(5deg, #b65308, $bg,  $bg);
        border-radius: 14px;
        box-sizing: border-box;
        padding: 15px;
        min-width: 190px;
        max-width: 300px;
        max-height: 95%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        font-size: 1em;
        //white-space: nowrap;
        font-weight: 500;
        z-index: 140;
        transition: all 1s ease;
        box-shadow: $shadow_main;

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            max-width: 100%;
            max-height: 65%;
            min-height: 50%;
            object-fit: cover;
            object-position: bottom;
        }

        >div{
            z-index: 102;
        }
    }

    &__circle {
        background-color: $bg;
        //background-color: red;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        position: absolute;
        z-index: 140;
        transition: all 0.8s ease;
    }

    &__highlight{
        z-index: 101;
    }
}

.bounce-5 {
    animation-name: bounce-5;
    animation-timing-function: ease;
}

</style>