<template>
    <div class="buy_stock_view">
        <div class="header buy_view">
            <div class="stock_logo"><img :src="companyLogo"></div>
            <h1>{{ chart_symbol }}</h1>
            <div class="company_name">{{ companyName }}</div>
        </div>
        <div class="stock_info" style="margin: 10px 0 15px">
            <div class="border-right">
            <stock-details-info :label="'Owned'">{{ heldStockAmount }}</stock-details-info>
            <stock-details-info :label="'Worth'">{{ addDollarSign(heldStockWorth) }}</stock-details-info>
            </div>
            <div>
            <stock-details-info :label="'Cash'" class="cash_row">{{ addDollarSign(money) }}</stock-details-info>
            </div>
        </div>
        <div class="buy_stock_info">
            <div>
                <b>${{ financial(stockPrice, true) }}</b>
                <div v-if="stockPriceGain != 0" :class="{'stock_change':true,[upDown]:true}">{{ addDollarSign(stockPriceGain) }}</div>
            </div>
            <div class="descr">
                Current Price
            </div>
        </div>

        <div  class="flex"><label for="stock_amount">Amount</label></div>
        <div class="amount_input">
<!--            <app-button @click="zeroAmount()"><fa-icon icon="redo"/></app-button>-->
            <app-button @click="zeroAmount()" :disabled="dealAmount <= 0"><app-icon-reset /></app-button>
            <button class="button red minus_stock_btn" @click="decreaseAmount()" :disabled="dealAmount <= 0">
                <fa-icon icon="minus"/>
            </button>
            <input id="stock_amount" type="number" v-model="dealAmount" :class="{'wobble':zeroAmountAnimation}">
            <button class="button green plus_stock_btn" :class="{'button_attention':dealAmount <= 0}" @click="increaseAmount()">
                <fa-icon icon="plus"/>
            </button>
            <app-button @click="useAllAmount()" class="max">MAX</app-button>
        </div>

        <div class="flex">
            <label>Total</label>
            <div class="stock_cost">${{ financial(totalCost, true) }}</div>
        </div>
        <div class="flex center">
<!--            <app-button @click="$emit('close')">Cancel</app-button>-->
            <buy-button
                    ref="buyBtn"
                    @buyAction="finishDeal()"
                    @buttonDisabled="zeroAmountWarning()"

                    :class="{[buttonColor]:true, button_attention:(dealAmount > 0 && !notEnough)}"
                    :btnDisabled="dealAmount == 0 || notEnough"
                    :btn-text="dealType.toUpperCase()"
            />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import AppButton from "@/components/AppButton";
import {mapMutations, mapState} from "vuex/dist/vuex.mjs";
import {mapActions} from "vuex";
import {getClosePrice, getLastDeal, moneyRound, onBoarding, upOrDown} from "@/assets/js/helper_functions";
import {financial, capitalize, abbreviateNumber, addDollarSign} from "@/assets/js/filters";
import {popups} from "@/assets/js/popupsData";
import StockDetailsInfo from "@/components/StockDetailsInfo";
import BuyButton from "@/components/BuyButton";
import AppIconReset from "@/components/AppIconReset";

export default {
    name:  'BuyStock',
    props: {
        symbol:   String,
        dealType: String,
        dealAmountProp: Number,
        companyLogo: String,
        companyName: String,
    },
    data() {
        return {
            ranges:     ['1w', '1m', '3m', '6m', '1y', '2y'],
            dealAmount: this.dealAmountProp ?? 0,
            zeroAmountAnimation: false,
        }
    },
    components: {
        AppIconReset,
        BuyButton,
        StockDetailsInfo,
        AppButton,
    },
    methods:    {
        capitalize,
        financial,
        abbreviateNumber,
        addDollarSign,
        ...mapActions(['getCurrentStockData', 'createNewDeal']),
        ...mapMutations(['addMoney', 'removeMoney','setInfoMsg','setGuideBubble','tutorialDone','nextOnboardingStep']),
        async finishDeal() {
            console.log('Finish Deal');
            this.$FirebaseAnalytics.logEvent({name: 'BUY_transaction_complete'});
            let deal = {
                dealType:    this.dealType,
                price:       this.stockPrice,
                amount:      this.dealAmount,
                finalAmount: this.finalAmount
            };
            console.log(this.stockSymbol, deal);
            this.confirmationMessage(deal);
            await this.createNewDeal({symbol: this.stockSymbol, deal: deal});
            this.updateMoney();
            this.tutorialDone('buy');
            if (!this.onboarding_status[6]) this.nextOnboardingStep();
            this.$emit('finishedDeal');
        },
        increaseAmount() {
            this.$FirebaseAnalytics.logEvent({name: 'BUY_plus-minus'});
            this.dealAmount = +this.dealAmount + 1;
        },
        decreaseAmount() {
            this.$FirebaseAnalytics.logEvent({name: 'BUY_plus-minus'});
            if (this.dealAmount == 0) this.dealAmount = 0;
            else this.dealAmount = +this.dealAmount - 1;
        },
        updateMoney() {
            console.log('updateMoney', this.totalCost);
            if (this.dealType == 'sell') this.addMoney(this.totalCost);
            if (this.dealType == 'buy') this.removeMoney(this.totalCost);
        },
        confirmationMessage(){
            //
            let action = this.dealType == 'buy' ? 'bought' : 'sold';
            let cost = addDollarSign(this.totalCost);
            let msgAmount = `<b class="${this.dealType}">${this.dealAmount}</b>`;
            let msg = `<h2>Transaction successful.</h2>
                        You’ve ${action} ${msgAmount} shares of <b>${this.stockSymbol}</b> for <b>${cost}</b>.
                        <p>${this.companyName}</p>`;
            this.setInfoMsg({msg});
        },
        useAllAmount() {
            this.$FirebaseAnalytics.logEvent({name: 'BUY_max'});
            this.dealAmount = this.maxPossibleAmount;
        },
       zeroAmount() {
            this.dealAmount = 0;
        },
        async zeroAmountWarning(){
            console.log('Zero amount warning');
            this.zeroAmountAnimation = true;
            console.log('Zero amount warning',this.zeroAmountAnimation);
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.zeroAmountAnimation = false;
            console.log('Zero amount warning',this.zeroAmountAnimation);
        },
    },
    computed:   {
        ...mapState(['chart_symbol', 'portfolio_value', 'stock_prices', 'current_stock_prices', 'is_loading', 'chart_range', 'portfolio_deals', 'money',
                     'tutorial', 'onboarding_status', 'onboarding_active', 'onboarding']),
        stockSymbol() {
            if (this.chart_symbol == 'My Portfolio') return 'AAPL';
            return this.chart_symbol;
        },
        totalCost() {
            return this.dealAmount * this.stockPrice;
        },
        stockPrice() {
            // return getClosePrice(this.stockData);
            let price = 0;
            if (this.dealType == 'sell') price = this.stockData.iexBidPrice;
            else price = this.stockData.iexAskPrice;
            return price || getClosePrice(this.stockData);
        },
        stockPriceGain(){
           return this.stockData.change;
        },
        upDown() {
            return upOrDown(this.stockPriceGain);
        },
        lastDeal() {
            return getLastDeal(this.portfolio_deals, this.stockSymbol);
        },
        heldStockAmount() {
            if (!this.lastDeal) return 0;
            return +this.lastDeal.finalAmount;
        },
        heldStockWorth(){
            return this.heldStockAmount * this.stockPrice;
        },
        finalAmount() {
            let final;
            if (this.dealType == 'sell') final = +this.heldStockAmount - (+this.dealAmount);
            if (this.dealType == 'buy') final = +this.heldStockAmount + (+this.dealAmount);
            return final;
        },
        stockData() {
            return this.current_stock_prices[this.stockSymbol];
        },
        maxPossibleAmount() {
            let amount = this.heldStockAmount;
            if (this.dealType == 'buy') {
                amount = Math.floor(this.money / this.stockPrice);
            }

            if (this.dealType == 'sell') {
                amount = this.heldStockAmount;
            }
            return amount;
        },
        buttonColor() {
            if (this.dealType == 'buy') return 'green';
            return 'red';
        },
        notEnough(){
            return this.dealAmount > this.maxPossibleAmount;
        }
    },
    async mounted() {
        // this.increaseAmount();
        if (!this.stockData) {
            await this.getCurrentStockData(this.stockSymbol);
        }
    },
    watch: {
        dealAmount: function () {
            console.log('dealAmount changed');
            if (this.dealAmount > 0) {
                if (!this.onboarding_status[5]) {
                    this.nextOnboardingStep();
                    onBoarding(this);
                } else if (this.notEnough && this.dealType == 'buy') {
                    this.setGuideBubble(popups.notEnoughCash);
                    localStorage.redirect = `/stockdetails/${this.stockSymbol}/${this.dealType}/${this.dealAmount}`;
                }
            }
        }
    }
}
</script>

<style lang="scss">

.buy_container {
    //display: flex;
    //flex-flow: column;

}

.buy_stock_view {
    //height: 100%;
    //display: flex;
    //flex-flow: column;
    //justify-content: stretch;
    padding: 20px 15px;
    text-align: left;
    max-width: 460px;
    @media screen and (min-width: 520px){
        margin: auto;
    }


    .header.buy_view{
        background-color: transparent;
        padding: 5px 5px 5px 80px;
        position: relative;
        font-size: 14px;

        .stock_logo {
            position: absolute;
            left: 15px;
            height: 50px;
            width: 50px;
            //padding: 5px;
            box-sizing: border-box;
            border-radius: 50%;
            display: flex;
        }

        h1 {
            margin: 0;
        }

        .company_name{
            line-height: 100%;
            font-size: 0.9em;
        }
    }

    > div {
        padding: 5px;

        > div {
            display: inline-block;
        }
    }

    label {
        min-width: 80px;
        display: inline-block;
        font-size: 1em;
        height: 24px;
    }

    input {
        min-width: 180px;
        flex-grow: 1;
    }

    .stock_cost {
        font-weight: 600;
        font-size: 1.2em;
    }

    .cash_row{
        position: relative;
        i.button_attention{
            position: relative;
            background-color: $bg_color_lighter;
            border-radius: 5px;
            padding: 0 5px;
            transition: all 0.5s;
        }
    }

    .amount_input {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        button {
            padding: 3px 12px;
            margin: 3px;
            min-width: 40px;
            flex-grow: 0;
            font-size: 1.2em;
            display: flex;
            justify-content: center;
            background-color: $bg_color;
            color: #fff;
            border: none;
            svg{
                display: block;
                padding: 0;
            }

            &.max{
                font-size: 0.8em;
            }

            &:disabled, [disabled] {
                border: none;
                background-color: #555;
                color: #aaa;
            }
        }

        input#stock_amount {
            flex-grow: 4;
            background-color: $bg_color;
            color: #fff;
            border: none;
            max-width: 100px;
            min-width: 0;
            font-size: 1.2em;
            font-weight: bold;
            line-height: 100%;
            height: 44px;
        }
    }
    .flex{
        justify-content: space-between;
        padding: 12px 15px 4px;
        button{
            //max-width: 100px;
        }
        &.center{
            justify-content: center;
        }
    }

    .buy_stock_info {

        background-color: $bg_color;
        color: $base_color_dim;
        font-size: 1.3em;
        margin: 0 -20px;
        padding: 10px 40px;
        position: relative;
        height: 70px;
        max-height: 70px;
        box-sizing: border-box;

        b {
            display: inline-block;
            color: $base_color;
            margin-right: 10px;
            font-weight: 600;
        }

        .stock_change {
            font-size: 0.7em;
            margin: 5px;
        }

        > div {
            display: flex;
            justify-content: flex-start;
            height: 30px;
        }

        .descr {
            font-size: 0.7em;
            margin-top: -1px;
        }

        .arrow {
            position: absolute;
            right: 22px;
            top: 26px;
            color: #fff;
        }

        @media screen and (min-width: 720px) {
            margin: 0 -150px;
            padding: 10px 160px;
            .arrow{
                right: 50px;
            }
        }

    }
}



</style>