<template>
    <div class="my_stock_info">
        <div>
            <b>{{ fNum }}</b> {{ sNum }}
            <template v-if="plPercent">
                <div :class="{'stock_change':true,[upDown]:true}">{{ financial(plPercent) }}%
                </div>
            </template>
        </div>
        <div class="descr">
            {{ desc }}
        </div>
        <template v-if="!hideArrow" >
            <fa-icon icon="arrow-right" class="arrow" />
        </template>
    </div>
</template>

<script>
import {financial} from "@/assets/js/filters";
import {upOrDown} from "@/assets/js/helper_functions";

export default {
    name:     "MyStockInfo",
    props:    {
        plPercent: Number,
        fNum:      [String, Number],
        sNum:      String,
        desc:      String,
        hideArrow: Boolean,
    },
    computed: {
        upDown() {
            // if (!this.plPercent) return '';
            return upOrDown(this.plPercent);
        }
    },
    methods:  {
        financial,
    },
    created() {
        // console.log('hideArrow', this.hideArrow, !this.hideArrow);
    }
}
</script>

<style lang="scss">
.my_stock_info {

    background-color: $bg_color_lighter;
    color: $base_color_dim;
    font-size: 1.3em;
    margin: 20px -10px;
    padding: 10px 20px;
    position: relative;
    height: 70px;
    max-height: 70px;
    box-sizing: border-box;

    b {
        display: inline-block;
        color: $base_color;
        margin-right: 10px;
        font-weight: 600;
    }

    .stock_change {
        font-size: 0.7em;
        margin: 5px;
    }

    > div {
        display: flex;
        justify-content: flex-start;
        height: 30px;
    }

    .descr {
        font-size: 0.7em;
        margin-top: -3px;
    }

    .arrow {
        position: absolute;
        right: 22px;
        top: 26px;
        color: #fff;
    }

    @media screen and (min-width: 720px) {
        margin: 20px -40px;
        padding: 10px 50px;
        .arrow{
            right: 50px;
        }
    }

}
</style>