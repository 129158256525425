<template>
    <div @click="openDetailsPage" class="row">
        <div class="stock_logo"><img :src="companyLogo"></div>
        <div class="stock">
            <div class="flex">
                <div class="symbol">
                    {{ item.symbol }}
                    <span v-if="item.pro && !userHasPremium" class="stock__pro">PRO</span>
                </div>
                <div class="volume">
                    {{ addDollarSign(item.vol) }}
                </div>
            </div>
            <div class="company_name">{{ item.company }}</div>
        </div>
    </div>
</template>

<script>
import {addDollarSign, financial} from "@/assets/js/filters";
import {getCompanyLogo} from "@/assets/js/helper_functions";
import {mapState} from "vuex/dist/vuex.mjs";
import {mapGetters, mapMutations} from "vuex";
import {popups} from "@/assets/js/popupsData";

export default {
    name: "SearchResultRow",
    components: {},
    props: {
        item: Object,
    },
    methods: {
        ...mapMutations(['setGuideBubble']),
        financial,
        addDollarSign,
        async openDetailsPage() {
            if (this.hasError) return;

            if (this.stockSearch == '') this.$FirebaseAnalytics.logEvent({name: 'BUY_search_default'});
            else this.$FirebaseAnalytics.logEvent({name: 'BUY_search_custom'});

            if (this.item.pro && !this.userHasPremium) {
                let msg = popups.onlyProSymbol;
                this.setGuideBubble(msg);
                localStorage.redirect = `/searchstock`;
                return;
            }
            this.$store.commit('setChartSymbol', this.item.symbol);
        // @click="$router.push('stockdetails')"
            await this.$router.push('stockdetails');
        }
    },
    computed: {
        ...mapState(['chart_symbol', 'stock_prices', 'current_stock_prices', 'is_loading', 'symbols', 'rest_api_url', 'stockSearch']),
        ...mapGetters(['user_premium']),
        isActive() {
            return this.symbol == this.$store.state.chart_symbol;
        },
        userHasPremium(){
            return this.user_premium;
        },
        companyLogo() {
            return getCompanyLogo(this.symbols, this.item.symbol, this.rest_api_url);
        },
    }
}
</script>

<style lang="scss">
.flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stock{
    &__pro{
        display: inline-block;
        background-color: $highlight_color;
        color: #fff;
        font-size: 10px;
        padding: 0 5px;
        border-radius: 5px;
        margin-left: 10px;
    }
}
</style>