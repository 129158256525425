<template>
    <div v-if="showItem" @click="openDetailsPage" class="row" :class="{'text-danger':hasError, 'active': isActive}">
        <div class="stock_logo"><img :src="companyLogo"></div>
        <div class="stock">
            <div class="company_name">{{ item.companyName }}</div>
            <div class="symbol">
                {{ item.symbol }}
                <i v-if="!isPortfolio">x{{stockAmount}}</i>
            </div>
        </div>
        <div class="value">
            <div class="value__amount">{{ addDollarSign(getValue) }}</div>
            <div class="stock_change"
                 v-if="getGainPercent!=0"
                 :class="[(getGainPercent > 0) ? 'up' : 'down']"
            >{{ getGainPercent }}%
            </div>
        </div>
        <div class="chart">
            <ChartSmall :symbol="item.symbol"/>
        </div>
    </div>
</template>

<script>
import {addDollarSign, financial} from "@/assets/js/filters";
import {
    getStockPriceDifference,
    getClosePrice,
    getCompanyLogo,
    getPnlPercents, moneyRound
} from "@/assets/js/helper_functions";
import {mapState} from "vuex/dist/vuex.mjs";
import ChartSmall from "@/components/ChartSmall";
import {mapMutations} from "vuex";

export default {
    name:       "PortfolioTableRow",
    components: {
        ChartSmall,
    },
    props:      {
        item: Object,
        tableMode: String,
    },
    methods:    {
        ...mapMutations(['setChartInfo','setChartSymbol']),
        financial,
        addDollarSign,
        moneyRound,
        openDetailsPage() {
            if (this.hasError) return;
            // this.$router.push('stockdetails');
            this.setChartInfo('Price');
            this.setChartSymbol(this.item.symbol);
        }
    },
    computed:   {
        ...mapState(['chart_symbol', 'portfolio_value', 'stock_prices', 'stock_value', 'current_stock_prices', 'is_loading', 'chart_range', 'chart_info', 'symbols','rest_api_url','portfolio_deals']),
        closePrice: function () {
            return getClosePrice(this.item);
        },
        isActive() {
            return this.item.symbol == this.$store.state.chart_symbol;
        },
        getDisplayPrice: function () {
            return financial(this.closePrice)
        },
        getValue:        function () {
            if (this.showPrice) return this.closePrice;
            else if( this.tableMode == 'Gain') return this.item.profitLoss;
            return this.item.value;
        },
        showPrice(){
            return this.chart_info == 'Price';
        },
        isPortfolio:     function () {
            return this.item.symbol == 'My Portfolio'
        },
        getGainPercent:  function () {
            // let stockData = this.stock_prices[this.item.symbol]?.r2y;
            let p;
            if (this.showPrice && !this.isPortfolio){
                p = getStockPriceDifference(this.stock_prices[this.item.symbol]?.r2y, this.chart_range, this.getValue);
            }
            p = getPnlPercents(this.item.profitLoss, this.item.value);
            return moneyRound(p,1);
        },
        hasError(){
            // console.log(this.item);
            return this.item.api_error;
        },
        companyLogo(){
            return getCompanyLogo(this.symbols, this.item.symbol, this.rest_api_url);
        },
        stockAmount(){
            return this.item.amount;
        },
        showItem(){
            return this.stockAmount > 0;
        }
    }
}
</script>

<style lang="scss">

.stock_change {
    font-size: 0.8em;

    &.up {
        color: $up_color;
        &::before {
            content: '▴ ';
        }
    }

    &.down {
        color: $down_color;
        &::before {
            content: '▾ ';
        }
    }
}


#stock_table, .search_results {
    .row {
        //border-bottom: 1px solid $base_color_dim;
        border-bottom: 1px solid $base_color_dim;
        display: flex;
        justify-items: stretch;

        &.text-danger{
            background-color: #530000;
        }

        .stock {

            .company_name {
                font-size: 0.75em;
                color: $base_color_dim;
                //line-height: 105%;
                white-space: nowrap;
                overflow: hidden;
            }

            .symbol {
                font-weight: bold;
                i{
                    display: inline-block;
                    font-weight: normal;
                    font-style: normal;
                    font-size: 0.95em;
                    padding-left: 5px;
                }
            }
        }

        &.active {
            background-color: $bg-color_lighter;

            .symbol {
                color: $highlight_color;
            }
        }

    }

    .stock, .value, .price, .chart {
        width: 70px;
        padding: 10px;
        box-sizing: border-box;
        //border: 1px solid #de0b0b;
        flex-grow: 1;
        font-size: 0.85em;
    }

    .value{
        &__amount{
            font-weight: 500;
        }
    }

    .stock {
        //width: 180px;
        text-align: left;
        flex-grow: 4;
    }

    .header {
        padding-left: 38px;
        font-size: 1.2em;
        font-weight: 400;
        height: 32px;
    }
}

.stock_logo {
    width: 38px;
    height: 38px;
    background-color: #ffffff;
    border-radius: 25px;
    overflow: hidden;
    vertical-align: middle;
    display: flex;
    align-items: center;

    img{
        width: 100%;
        height: auto;
    }

}

</style>