<template>
    <div class="resetProfileView">
        <div class="header buy_view">
            <app-close-button @click="closeWindow()" class="fixed"/>
            <h1>Reset Profile</h1>
        </div>
        <div class="buy_cash_info">
                <span>Restart while maintaining purchased cache</span>
                <b>$10</b>
        </div>
        <div class="flex center">
<!--            <app-button @click="$emit('close')">Cancel</app-button>-->
            <buy-button
                    ref="buyBtn"
                    @buyAction="resetProfile()"
                    @buttonDisabled="zeroAmountWarning()"

                    :class="{['green']:true}"
                    :btnDisabled="dealAmount == 0"
                    btn-text="Buy"
            />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import {mapMutations, mapState} from "vuex/dist/vuex.mjs";
import {mapActions} from "vuex";
import {getClosePrice, getLastDeal, moneyRound, onBoarding, upOrDown} from "@/assets/js/helper_functions";
import {financial, capitalize, abbreviateNumber, addDollarSign} from "@/assets/js/filters";
import {popups} from "@/assets/js/popupsData";
import BuyButton from "@/components/BuyButton";
import AppCloseButton from "@/components/AppCloseButton";

export default {
    name:  'BuyResetProfile',
    data() {
        return {
            ranges:     ['1w', '1m', '3m', '6m', '1y', '2y'],
            dealAmount: 10000,
            zeroAmountAnimation: false,
        }
    },
    components: {
        AppCloseButton,
        BuyButton,
    },
    methods:    {
        capitalize,
        financial,
        abbreviateNumber,
        addDollarSign,
        ...mapActions(['loadUserPortfolio']),
        ...mapMutations(['addMoney', 'removeMoney','setInfoMsg','setGuideBubble','tutorialDone','nextOnboardingStep','setLoadingState']),
        async resetProfile() {
            //TODO add reset profile functionality

        },
        confirmationMessage(){
            //
            let cost = addDollarSign(this.totalCost);
            let msgAmount = `<b class="buy">${this.dealAmount}</b>`;
            let msg = `<h2>Transaction successful.</h2>
                        You’ve purchased ${msgAmount} cash for <b>${cost}</b>.`;
            this.setInfoMsg({msg});
        },
        closeWindow(){
            let url = localStorage.redirect ?? '/';
            localStorage.removeItem('redirect');
            this.$router.replace(url);
        },
    },
    computed:   {
        ...mapState(['is_loading', 'money']),
        totalCost() {
            return 10;
        },
    },
    async mounted() {

    },
    watch: {
        dealAmount: function () {
            if (this.dealAmount > 0) {
                console.log('dealAmount changed');
            }
        }
    }
}
</script>

<style lang="scss">

.resetProfileView {
    padding: 20px 15px;
    text-align: left;
    max-width: 460px;
    @media screen and (min-width: 520px){
        margin: auto;
    }


    .header {
        background-color: transparent;
        padding: 5px 5px 5px 80px;
        position: relative;
        font-size: 14px;

        h1 {
            margin: 0;
        }

        .company_name{
            line-height: 100%;
            font-size: 0.9em;
        }
    }

    > div {
        padding: 5px;

        > div {
            display: inline-block;
        }
    }

    label {
        min-width: 80px;
        display: inline-block;
        font-size: 1em;
        height: 24px;
    }

    input {
        min-width: 180px;
        flex-grow: 1;
    }

    .stock_cost {
        font-weight: 600;
        font-size: 1.2em;
    }

    .cash_row{
        position: relative;
        i.button_attention{
            position: relative;
            background-color: $bg_color_lighter;
            border-radius: 5px;
            padding: 0 5px;
            transition: all 0.5s;
        }
    }

    .amount_input {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        button {
            padding: 3px 12px;
            margin: 3px;
            min-width: 40px;
            flex-grow: 0;
            font-size: 1.2em;
            display: flex;
            justify-content: center;
            background-color: $bg_color;
            color: #fff;
            border: none;
            svg{
                display: block;
                padding: 0;
            }

            &.max{
                font-size: 0.8em;
            }

            &:disabled, [disabled] {
                border: none;
                background-color: #555;
                color: #aaa;
            }
        }

        input#stock_amount {
            flex-grow: 4;
            background-color: $bg_color;
            color: #fff;
            border: none;
            max-width: 100px;
            min-width: 0;
            font-size: 1.2em;
            font-weight: bold;
            line-height: 100%;
            height: 44px;
        }
    }
    .flex{
        justify-content: space-between;
        padding: 12px 15px 4px;
        button{
            //max-width: 100px;
        }
        &.center{
            justify-content: center;
        }
    }

    .buy_cash_info {

        background-color: $bg_color;
        color: $base_color_dim;
        font-size: 1.3em;
        //margin: 0 -20px;
        padding: 10px 20px;
        position: relative;
        height: 70px;
        max-height: 70px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        max-width: 100%;

        b {
            display: inline-block;
            color: $base_color;
            margin-right: 10px;
            font-weight: 600;
        }

        > div {
            display: flex;
            justify-content: flex-start;
            height: 30px;
        }

        .descr {
            font-size: 0.7em;
            margin-top: -1px;
        }
    }
}



</style>