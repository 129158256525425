<template>
    <div class="avatars">
        <template v-for="(avatarType, filename) in avatars" :key="filename">
            <div class="avatar__container" v-if="avatarType=='free'">
                <div class="avatar__type">{{avatarType}}</div>
                <div class="avatar" @click="updateUserAvatar(filename, avatarType)">
                    <div class="avatar__photo">
                        <img :src="avatar_path(filename)" />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {avatarPath} from "@/assets/js/helper_functions";
import router from "@/router";
import {popups} from "@/assets/js/popupsData";
export default {
    name: "AvatarsList",
    computed:{
      ...mapState(['rest_api_url','onboarding_status']),
      ...mapGetters(['user_premium']),
    },
    data() {
        return {
            avatars:     {
                "Female_French.png": "free",
                "Israel_Male.png": "free",
                "African_American_Female.png": "pro",
                "African_American_Male.png": "pro",
                "Asian_Female.png": "pro",
                "Asian_Male.png": "pro",
                "Caucasian_Female.png": "pro",
                "Caucasian_Male.png": "pro",
                "Dione.png": "pro",
                "Female_Blonde.png": "pro",
                "Female_Egyptian.png": "pro",
                "Female_Iran.png": "pro",
                "Female_Japanese.png": "pro",
                "Female_Portrtait.png": "pro",
                "Female_Spanish.png": "pro",
                "Girl.png": "pro",
                "Indian_Female.png": "pro",
                "Indian_Male.png": "pro",
                "Israel_Female.png": "pro",
                "Liz.png": "pro",
                "Russian_Female.png": "pro",
                "Russian_Male.png": "pro",
                "Male_Blonde.png": "pro",
                "Male_Egyptian.png": "pro",
                "Male_French.png": "pro",
                "Male_Iran.png": "pro",
                "Male_Japanese.png": "pro",
                "Male_Spanish.png": "pro",
                "Male_portrait.png": "pro",
                "Mariness.png": "pro",
                "Mikee.png": "pro",
                "Muslim_Man.png": "elite",
                "Muslim_Woman.png": "elite",
                "Myanmar_Female.png": "elite",
                "Myanmar_Male.png": "elite",
                "Paul.png": "elite",
                "Ukraine_Female.png": "elite",
                "Ukraine_Male.png": "elite",
                "Zimbabwe_Female.png": "elite",
                "Zimbabwe_Male.png": "elite",
                "Andy.png": "elite"
            }
        }
    },
    methods: {
        ...mapActions(['updateAvatar']),
        ...mapMutations(['setGuideBubble']),
        // avatarPath(path){
        //     return this.rest_api_url+'images/avatars/'+path;
        // }
        avatar_path(filename){
            return avatarPath(this.rest_api_url, filename);
        },
        async updateUserAvatar(avatar, type){
            if (type == 'pro' && this.user_premium < 1) {
                let msg = popups.onlyProAvatar;
                // guide.img = this.avatar_path(avatar);
                this.setGuideBubble(msg);
                localStorage.redirect = `/avatars`;
                return;
            }
            if (type == 'elite' && this.user_premium < 2) {
                // todo show bubble that this avatar is for Elite traders only
            }

            await this.updateAvatar(avatar);
            // if (this.onboarding_status[0] == 0) window.location.href = '/';

            // On no pro subscription branch redirect to profile
                this.$router.push('profile');
        }
    }
}
</script>

<style lang="scss">
.avatars {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .avatar {
        $size: 130px;
        width: $size;
        height: $size;
        position: relative;

        &__photo
        {
            width: $size - 10;
            height: $size - 10;
        }

        &__type
        {
            position: absolute;
            z-index: 100;
            bottom: 5px;
            left: 5px;
            background-color: #000;
            border-radius: 5px;
            padding: 2px 10px;
        }

        &__container
        {
            display: inline-block;
            position: relative;
        }
    }
}

</style>