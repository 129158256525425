<template>
    <div class="choose_avatars">
        <div class="choose_avatars__header">
            <app-close-button v-if="user_avatar" @click="$router.replace('/')" class="fixed"/>
            <h1>Choose Your Avatar</h1>
        </div>
        <avatars-list />
    </div>
</template>

<script>
import AvatarsList from "@/components/AvatarsList";
import AppCloseButton from "@/components/AppCloseButton";
import {mapGetters} from "vuex";
export default {
    name: "ChooseAvatar",
    components:{
        AvatarsList,
        AppCloseButton,
    },
    computed:{
        ...mapGetters(['user_avatar']),
    }
}
</script>

<style lang="scss">
.choose_avatars{
    &__header {
        position: sticky;
        top: 0;
        background-color: #171821;
        padding: 10px 0 0 0;
        z-index: 125;
    }
}
</style>