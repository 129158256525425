import {FirebaseAuthentication} from "@capacitor-firebase/authentication";
import {Capacitor} from "@capacitor/core";
import {PushNotifications} from "@capacitor/push-notifications";
import {FCM} from "@capacitor-community/fcm";
import {getToken, isSupported} from "firebase/messaging";
import { get, set, entries, clear } from 'idb-keyval';
import {getPushTitleMsg, getPushTitles} from "@/assets/js/helper_functions";

export const userStore = {
    state:     {
        signed_in: false,
        user:        {},
        fcm_token: null,
        leaderboard: [],
        notification_settings: {
            status_change_msg: 0,
            trading_opening: 0,
            trading_closes: 0,
            ranking_change: 1,
            too_much_cach: 1,
            low_activity: 1,
            big_stock_change: 1,
        },
    },
    mutations: {
        setUserData(state, userData) {
            localStorage.setItem('user', JSON.stringify(userData));
            localStorage.hasUsername = !!userData.username;
            localStorage.hasAvatar = !!userData.avatar;
            state.user = userData;
        },
        setLeaderboardData(state, usersData) {
            state.leaderboard = usersData;
        },
        setSignedIn(state, val) {
            state.signed_in = val;
        },
        setFCMtoken(state, val) {
            state.fcm_token = val;
            localStorage['fcm_token'] = val;
            set('userStore.fcm_token', val);
        },
        setNotificationOption(state, {key, val}) {
            state.notification_settings[key] = val;
            set('userStore.notification_settings', {...state.notification_settings});
        },
        setAllNotificationOptions(state, {val}) {
            console.log('setAllNotificationOptions', val);
            state.notification_settings = val;
            set('userStore.notification_settings', {...state.notification_settings});
        },
        disableAllNotificationOptions(state) {
            state.notification_settings = {
                status_change_msg: 0,
                trading_opening: 0,
                trading_closes: 0,
                ranking_change: 0,
                too_much_cach: 0,
                low_activity: 0,
                big_stock_change: 0
            };
            set('userStore.notification_settings', {...state.notification_settings});
        },
        setAvatar(state, val) {
            localStorage.hasAvatar = !!val;
            state.user.avatar = val;
        },
        setUsername(state, val) {
            state.user.username = val;
        },
    },
    actions:   {
        async getCurrentUser({commit}){
            const result = await FirebaseAuthentication.getCurrentUser();
            if (!result) {
                commit('setSignedIn', false);
                console.log(result);
                return result;
            }
            else{
                commit('setSignedIn', true);
                console.log(result);
                return result.user;
            }
        },

       /**
        *  Sends the user token to server for verification
        *  On the server the token is verified
        *  The user data is added to DB if user does not exist
        *  A php login session is created
        */
        async signInRequest({dispatch, commit}, token) {
            let params = {
                'mode': 'signInUser',
                'token': token,
            }

            let data = await dispatch('userApiRequest', params);
            // commit('setUserData', data)
            return data;
        },
        async loadUserData({dispatch, commit}) {
            let params = {
                'mode': 'getUserData',
            }

            let data = await dispatch('userApiRequest', params);
            commit('setUserData', data)
            return data;
        },
        async signOutRequest({state, dispatch, rootState, commit}) {
            let apiUrl = rootState.rest_api_url + 'ajax/signout.php';
            // let extra = {credentials: 'include'};
            let params = {
                'mode': 'logOut',
            };
            let response = await dispatch('restApiRequest', {apiUrl, params});
            await clear();
            console.log('Log Out response: ', response);
            return await FirebaseAuthentication.signOut();
        },
        async getLeaderboardData({dispatch, commit}) {
            console.log('Getting leaderboard data');
            let params = {
                'mode': 'getLeaderboardData',
            }

            let data = await dispatch('userApiRequest', params);
            console.log(data);
            commit('setLeaderboardData', data)
            return data;
        },
        async  getFcmToken({state, dispatch, commit, rootState}, {action, extra}) {
            console.log('Getting FCM token');
            let token = null;
            if (Capacitor.getPlatform() !== 'web') {
                let permStatus = await PushNotifications.checkPermissions();
                if (permStatus.receive === 'prompt') {
                    permStatus = await PushNotifications.requestPermissions();
                }
                console.log('Waiting for Native Push permissions');
                if (permStatus.receive === "granted") {
                    console.log('Permission GRANTED - Waiting for the token');
                    await PushNotifications.addListener('registration', async ({ value }) => {
                        token = value // Push token for Android
                        console.log('Registration callback token', token);
                        // Get FCM token instead the APN one returned by Capacitor
                        if (Capacitor.getPlatform() === 'ios') {
                            console.log('Wait for IOS device FCM token');
                            const { token: fcm_token } = await FCM.getToken()
                            token = fcm_token
                            console.log('ios Token', fcm_token)
                        }
                        // Work with FCM_TOKEN
                        let response = dispatch(action, {token,...extra});
                        console.log('Action inside Registration dispatched');
                        return response;
                    });

                    PushNotifications.addListener('registrationError', (error) => {
                        alert('Error on registration: ' + JSON.stringify(error));
                    });

                    await PushNotifications.register();
                } else {
                    // No permission for push granted
                    if (action != 'getUserSubscriptionsByToken') {
                        alert('No Permission for Notifications!');
                    }
                    else {
                        console.log('No Permission for Notifications!');
                    }
                    commit('disableAllNotificationOptions');
                    return null;
                }
            }
            else{
                let vapidKey = 'BLZT8XzWDxNHZXdHvfY3OYYVGoEUjnNsfouhRlNNkUdyN-IOK0B4d117tWCkR3hyzpTNmSaKhLJnwq8NHdTp-fM';
                let serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
                // console.log('Messaging', this.$messaging);
                // console.log('Waiting for Service worker registration', serviceWorkerRegistration);
                if (!("Notification" in window) || !rootState.firebaseIsSupported) {
                    console.log("This browser does not support desktop notification");
                    return null;
                }

                let permission =  Notification.permission;

                if (permission == "denied") {
                    if (action != 'getUserSubscriptionsByToken') {
                        alert('No Permission for Notifications!');
                    }
                    else {
                        console.log("Notifications permissions denied");
                    }
                    commit('disableAllNotificationOptions');
                    return null;
                }

                // Otherwise, we need to ask the user for permission
                if (permission !== "granted") {
                    permission = await Notification.requestPermission();
                    if (permission == "denied") {
                        console.log("Notifications permissions denied");
                        commit('disableAllNotificationOptions');
                        return null;
                    }
                }

                if (permission == "granted") {
                    try {
                        token = await getToken(this.$messaging, {vapidKey, serviceWorkerRegistration});
                        let response = dispatch(action, {token, ...extra});
                        return response;
                    } catch (err) {
                        console.log('An error occurred while retrieving token. ', err);
                    }
                }
            }
            return token;
        },
        async subscribeToFCMtopic({dispatch, commit}, {token, topic}){
            console.log('subscribing to FCM topic',topic);

            let params = {
                'mode': 'subscribeTopic',
                'fcmToken': token,
                'topic': topic,
            }

            let data = await dispatch('userApiRequest', params);
            console.log(data);
            if (data.result == 'success') {
                commit('setFCMtoken', token);
                commit('setNotificationOption',{key:topic,val: 1});
                new Notification('You will receive notifications ' + getPushTitleMsg(topic), {body:''});
            }
        },
        async unsubscribeFromFCMtopic({dispatch, commit}, {token, topic}){
            console.log('unsubscribing from FCM topic',topic);
            let params = {
                'mode': 'unsubscribeTopic',
                'fcmToken': token,
                'topic': topic,
            }
            let data = await dispatch('userApiRequest', params);
            console.log(data);
            if (data.result == 'success') {
                commit('setFCMtoken', token);
                commit('setNotificationOption',{key:topic,val: 0});
            }
        },
        // Also used for initialising the push notification topics for user
        async getUserSubscriptionsByToken({dispatch, commit}, {token}){
            console.log('Get User Subscription by Token');
            let params = {
                'mode': 'getUserSubscriptionsByToken',
                'fcmToken': token,
            }
            let data = await dispatch('userApiRequest', params);
            console.log(data);
            if (data.result == 'success') {
                commit('setFCMtoken', token);
                commit('setAllNotificationOptions',{val: data.options});
            }
        },
        async updateAvatar({dispatch, commit}, newAvatar){
            let params = {
                'mode': 'setAvatar',
                'avatar': newAvatar,
            }
            console.log(newAvatar);

            let data = await dispatch('userApiRequest', params);
            commit('setAvatar', newAvatar);
            return data;
        },
        async updateUsername({dispatch, commit}, newUsername){
            let params = {
                'mode': 'setUsername',
                'username': newUsername,
            }
            console.log('NEW USER NAME:', newUsername);

            let data = await dispatch('userApiRequest', params);
            commit('setUsername', newUsername);
            return data;
        },
        async resetProfileRequest({dispatch, commit}){
            let params = {
                'mode': 'resetProfile',
            }
            console.log('Reset profile request');

            let data = await dispatch('userApiRequest', params);
            return data;
        },
        async deleteProfileRequest({dispatch, commit}){
            let params = {
                'mode': 'deleteProfile',
            }
            console.log('Delete profile request');

            let data = await dispatch('userApiRequest', params);
            await clear();
            return data;
        },

    },
    getters:   {
        user_id(state) {
            return state.user?.uid;
        },
        user_token(state) {
            return state.user?.token;
        },
        user_premium(state) {
            return state.user?.premium;
        },
        user_email(state) {
            // console.log('user_email',state.user.email)
            return state.user?.email;
        },
        user_lang(state) {
            // console.log('user_lang', state.user.lang);
            return state.user?.lang;
        },
        is_logged_in(state) {
            // console.log('is_logged_in',state.user.uid,!+state.user.anonymous);
            return state.user?.uid && !+state.user?.anonymous;
        },
        rank_week(state) {
            return +state.user?.week_rank;
        },
        rank_overall(state) {
            return +state.user?.overall_rank;
        },
        rank_gain(state) {
            return +state.user?.gain_rank;
        },
        total_users(state){
            return +state.user?.tUsrs;
        },
        user_stats(state){
            return state.user;
        },
        user_name(state){
            if (state.user?.username) return state.user?.username;
            let email = state.user?.email;
            return email ? email.split('@')[0] : 'Trader';
        },
        activity_status(state){
            let usage = +state.user?.usage_week;
            let deals = +state.user?.deals_week;
            if (deals>=10 && usage>=7) return { num:1, name:'guru trader', msg: 'Trade today and keep your Guru Trader status'};
            if (deals < 10 && usage>= 7) return { num:2, name:'master trader', msg: `Make ${10-deals} additional trades now to get Guru status!`};
            if (usage>= 5) return { num:3, name:'confident trader', msg: `${7-usage} Days to trade to reach the next status`};
            if (usage>= 1) return { num:4, name:'rising trader', msg: `Trade for ${5-usage} days and get upgraded to confident trader`};
            return { num:5, name:'junior trader', msg: `Trade today and get upgraded to rising trader`};
        },
        push_options(state){
            return state.notification_settings;
        },
        user_avatar(state){
            return state.user?.avatar;
        },
    }
}
