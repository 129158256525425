import {createStore} from 'vuex'
import { get, set, entries, clear } from 'idb-keyval';

const persistedMutations = {
    symbols : 'saveAllSymbols',
    data_meta : 'setDataMeta',
    stock_prices : 'setStockPrices'
}

import {
    updateChartPeriod,
    getChartRangeForApi,
    getPortfolioStockValue,
    calculatePortfolioHistoricValues,
    calculateCurrentPortfolioStockValue, getCurrentNYtime, getCashHistory
} from "@/assets/js/chart_functions";

import {
    addCurrentToHistory, adventureImagePath,
    getClosePrice, getCurrentStockAmount,
    getLastDeal,
    getLastObjValue,
    isEmpty,
    isPortfolioEmpty,
    isToday, resolveObj, setObjPathValue, stockPricesMetaKey, timeDifference
} from "@/assets/js/helper_functions";


import {userStore} from "@/store/userStore";
import {getCurrentCash} from "@/assets/js/portfolio_functions";
import {onBoardingSteps} from "@/store/onBoardingSteps";
import {iap} from "@/store/iap";
import {Capacitor} from "@capacitor/core";


function isLocalDev(){
    if (location.hostname === "tradernet.test") return true;
    return ((location.hostname === "localhost" || location.hostname === "127.0.0.1" ) && location.port == "8080");
}

function getApiURL(){
    if (isLocalDev()) return 'http://localhost:8000/';
    if (location.hostname === "test.gain4.fun") return 'https://test.gain4.fun/';
    return 'https://gain4.fun/'
}

const initialState = {
    is_local_dev:    isLocalDev(),
    is_loading:      false,
    loading_message:      null,
    portfolio_value: {'r1mm': null, 'r2y': null, 'current':null},
    stock_prices:    {},
    stock_value:    {},
    data_meta: {portfolio_deals:0}, // portfolio_deals ==0 - means the data is not yet loaded.
    chart_symbol:    'My Portfolio',
    chart_range:     '1m',
    chart_info:     'Price',
    symbols: {},
    money_history: {'r1mm': null, 'r2y': null},
    money: null, // Cash Money available
    initialCapital: 100000, // Initial money
    portfolio_deals: {},
    extra_cash: {},
    stockSearch: '',
    current_stock_prices: {},
    company_info:{},
    profit_loss:{all:{}, day:{} },
    rest_api_url:      getApiURL(),
    info_msg:          {msg: null, time: 7000, iconClass: 'success'},
    guide_bubble:      { active: false, target: {x: 120,y:50}, msg:'This is a bubble test'},
    tutorial:          {search:0, buy:0},
    trading_times:     {},
    onboarding:        onBoardingSteps,
    onboarding_active:  1,
    onboarding_status: [0,0,0,0,0,0,0,0,0,0, 0,0,0,0,0,0,0],
    firebaseIsSupported: false,
    persist:           persistedMutations,
}

export default createStore({
    state:     initialState,
    mutations: {
        setStockSearch(state,val){
            state.stockSearch = val;
        },
        setFirebaseIsSupported(state, val){
            state.firebaseIsSupported = val;
        },
        setTradingTimes(state,val){
            state.trading_times=val;
        },
        setMarketOpen(state,val){
            state.trading_times.open = val;
        },
        activeGuideBubble(state,val){
            state.guide_bubble.active=val;
        },
        setTutorial(state,{key,val}){
            state.tutorial[key]=val;
        },
        tutorialDone(state,key){
            state.tutorial[key]=1;
        },
         setGuideBubble(state,val){
            state.guide_bubble={...val};
        },
        setInfoMsg(state, {msg, time , iconClass}){
            time = time ?? 7000;
            iconClass = iconClass ?? 'success';
            state.info_msg = {msg, time, iconClass};
        },
        toggleChartInfo(state){
            if (state.chart_info == 'Price') state.chart_info = 'Value';
            else state.chart_info = 'Price';
        },
        setChartInfo(state, val) {
            state.chart_info = val;
        },
        setProfitLoss(state, {val, period}) {
            state.profit_loss[period] = val;
        },
        setDataMeta(state, {key, val}){
            val = val || new Date().toISOString();
            if (val=='plus') val = state.data_meta[key]+1 || 1; // Increment meta when val is PLUS
            state.data_meta[key] = val;
            // console.log(key, val);
            set('data_meta', {...state.data_meta}); // Persist
        },
        setPortfolioValue(state, {val, range}) {
            let r = 'r'+range;
            state.portfolio_value[r] = val;

            // Set meta data for portfolio used for sync and loading optimization
            let version  = state.data_meta.portfolio_deals;
            let metaKey = 'portfolio_value.'+range;
            this.commit('setDataMeta',{key:metaKey+'.v',val:version});
        },
        setCurrentPortfolioValue(state, val){
            state.portfolio_value.current = val;
        },
        setChartRange(state, val){
            state.chart_range = val;
        },

        stockPriceUpdate(state, {symbol, data}){
            // In case of API error we use available data
            if (!data['companyName']) data['api_error'] = true;
            data['symbol'] = data['symbol'] || symbol;
            data['close'] = data['close'] ?? (getLastObjValue(state?.stock_prices[symbol]?.r1mm) || 0);
            data['companyName'] = data['companyName'] || 'API error - stale data';

            state.current_stock_prices[symbol] = data;
        },
        portfolioBatchStockPricesUpdate(state, data){
            // In case of API error we use vailable data
            if (!data) return;
            for (let symbol in data){
                state.current_stock_prices[symbol] = data[symbol].quote;
            }
        },
        setStockPrices(state, {symbol, range, data}){
            let key = 'r' + range;
            state.stock_prices[symbol] = state.stock_prices[symbol] || {};
            state.stock_prices[symbol][key] = data;
            let metaKey = stockPricesMetaKey(symbol, range);
            this.commit('setDataMeta',{key:metaKey,val:null});
            this.commit('setStockValues',{symbol, range});
            set(metaKey, data);
        },
        setCompanyInfo(state, data){
            let symbol = data.symbol;
            state.company_info[symbol] = data;
            set('company_info.' + symbol , data);
        },
        setStockValues(state, {symbol, range}){
            if (!state.portfolio_deals[symbol]) return;
            let version  = state.data_meta.portfolio_deals;
            let metaKey = 'stock_values.'+symbol+'.'+range+'.v';
            state.stock_value[symbol] = state.stock_value[symbol] || {};
            if (state.data_meta[metaKey] == version && !isEmpty(state.stock_value[symbol]) && !isEmpty(state.stock_value[symbol]['r'+range])) return;
            // console.log('calculate stock values for ', symbol, ' and ' , range);
            state.stock_value[symbol]['r'+range] = getPortfolioStockValue(symbol, state.stock_prices, state.portfolio_deals, range)
            this.commit('setDataMeta',{key:metaKey,val:version});
        },
        setChartSymbol(state, symbol){
            state.chart_symbol = symbol;
            set('chart_symbol', symbol);
        },
        setLoadingState(state, {active, message}){
            state.is_loading = active;
            state.loading_message = message;
        },
        saveAllSymbols(state, val){
            state.symbols = val;
            set('symbols',val).then(()=>console.log('Saved symbols'));
            this.commit('setDataMeta',{key:'symbols',val:null});
        },
        addDeal(state,{symbol, deal, dealTime}){
            let date = dealTime || new Date().toISOString();
            if (!state.portfolio_deals[symbol]) {
                state.portfolio_deals[symbol] = {};
            }
            state.portfolio_deals[symbol][date] = deal;
            // Set meta data for sync and optimization
            this.commit('setDataMeta',{key:'portfolio_deals',val:'plus'});
        },
        setUserDeals(state, deals){
            state.portfolio_deals = deals.portfolio_deals;
            state.extra_cash = deals.extra_cash;
            this.commit('setDataMeta',{key:'portfolio_deals',val: 'plus'});
        },
        setMoney(state, val){
            state.money = val;
        },
        addMoney(state, val){
            state.money += val;
        },
        removeMoney(state, val){
            state.money -= val;
        },
        setMoneyHistory(state, {money_history, range}){
            state.money_history['r'+range] = money_history;
        },
        resetPortfolioData(state){
            state = initialState;
        },
        setPersisted(state, {key,val}){
            setObjPathValue(state, key, val);
        },
        nextOnboardingStep(state){
            this.commit('activeGuideBubble', false);
            localStorage.onboarding = false;
            let key = state.onboarding_status.findIndex(el=>el==0);
            if (key != -1){
                state.onboarding_status[key] = 1;
                // set('onboarding_status' , [...state.onboarding_status]);
            }
            let nextKey = state.onboarding_status.findIndex(el=>el==0);
            if (nextKey == -1){
                state.onboarding_active = 0;
            }
        },
        exitOnboarding(state){
            // state.onboarding_status =  [1,1,1,1,1,1,1,1,1,1, 1,1,1,1,1,1,1];
            state.onboarding_active = 0;
            // set('onboarding_status' , [...state.onboarding_status]);
        },
        resetOnboarding(state){
            if (!state.onboarding_status.includes(0)){
                state.onboarding_status =  [0,0,0,0,0,0,0,0,0,0, 0,0,0,0,0,0,0];
            }
            state.onboarding_active = 1;
            localStorage.onboarding = false;
            // set('onboarding_status' , [...state.onboarding_status]);
        },
        setOnboarding(state, val){
            if (val.steps) state.onboarding_status =  val.steps;
            state.onboarding_active = val.active ?? 1;
            // set('onboarding_status' , [...state.onboarding_status]);
        }
    },
    actions:   {
        async restApiRequest({dispatch,state},{apiUrl,params,extra}){
            if (!navigator.onLine){
                await dispatch('noInternet');
                return null;
            }
            let options = {
                method:  'POST', // *GET, POST, PUT, DELETE, etc.
                credentials: 'include',
                headers: {
                    'Content-Type': 'text/plain',
                },
                body:    JSON.stringify(params) // body data type must match "Content-Type" header
            };
            if (extra) options = {...options, ...extra}; // Adding extra options if available
            const response = await fetch(apiUrl, options);
            let data;
            try {
                let textData = await response.text();
                // console.log('response text ',textData);
                data = textData ? JSON.parse(textData) : {};
            } catch (error) {
                console.warn(params,extra);
                console.error(error);
                data = {};
            }
            return data;
        },

        async stockApiRequest({state,dispatch}, params ){
            let apiUrl = state.rest_api_url + 'rest/stock/';
            let data = await dispatch('restApiRequest', {apiUrl,params});
            return data;
        },

        async userApiRequest({state,dispatch}, params ){
            let apiUrl = state.rest_api_url + 'rest/user/';
            // let extra = {credentials: 'include'};
            let data = await dispatch('restApiRequest', {apiUrl,params});
            return data;
        },

        async getCurrentStockData({state,dispatch, commit}, symbol ){
            let params = {
                'mode':      'getCurrentStockPrice',
                'symbol': symbol,
            }

            let data = await dispatch('stockApiRequest',params);
            commit('stockPriceUpdate',{symbol, data })
            return data;
        },

        async getBatchCurrentStockData({state,dispatch, commit}, symbols){
            let params = {
                'mode':      'getBatchCurrentStocksPrice',
                'symbols': symbols,
            }
            if (symbols.length == 0)  return;
            let data = await dispatch('stockApiRequest',params);
            commit('portfolioBatchStockPricesUpdate', data);
        },

        async getCompanyInfo({state,dispatch, commit}, symbol){
            let params = {
                'mode':      'getCompanyInfo',
                'symbol': symbol,
            }
            if (!symbol)  return;
            let data = await dispatch('stockApiRequest',params);
            commit('setCompanyInfo', data);
        },

        async getStockPrices({dispatch, commit, state}, {symbol,range} ){
            if (symbol == 'My Portfolio') {
                await dispatch('getPortfolioValue', range);
                return;
            }

            symbol = symbol || state.chart_symbol;
            range = getChartRangeForApi(range); // Transform 1w,1m to 1mm else 2y

            let params = {
                'mode':      'getStockPrices',
                'symbol': symbol,
                'range': range,
            }

            let metaKey = stockPricesMetaKey(symbol, range);

            if (isToday(state.data_meta[metaKey]) && !isEmpty(state.stock_prices[symbol]) && !isEmpty(state.stock_prices[symbol]['r'+range])) {
                // console.log(['getStockPrices',symbol, range, state.stock_prices[symbol]]);
                commit('setStockValues', {symbol, range}); // We set the stock value even if stock_prices don't change
                return;
            }

            let data = await dispatch('stockApiRequest',params);
            commit('setStockPrices', {symbol, range ,data});
        },
        async getAllPortfolioStockValues({dispatch, state}){

            let symbols = Object.keys(state.portfolio_deals);

            for (let symbol of symbols){
                await dispatch('getStockPrices',{symbol,range:'1m'});
                await dispatch('getStockPrices',{symbol,range:'1y'});
            }

            if (isEmpty(symbols)) { // Loading prices for the default symbol to calculate tradingDays
                let symbol = 'IBM';
                await dispatch('getStockPrices',{symbol,range:'1m'});
                await dispatch('getStockPrices',{symbol,range:'1y'});
            }

        },
        async getAllSymbols({dispatch, commit, state}, force) {
            // console.log('DATA META',state.data_meta);
            let lastRequestDate = state.data_meta.symbols;
            // console.log(lastRequestDate, timeDifference(lastRequestDate));
            if (
                !force
                && lastRequestDate
                && timeDifference(lastRequestDate).days < 10
                && !isEmpty(state.symbols)
            ) return; // If symbols data is newer than 10 days we do not request

            let params = {
                'mode': 'getSymbols',
            }
            let data = await dispatch('stockApiRequest', params);
            commit('saveAllSymbols', data);
        },

        /**
         *  Updates the current stock prices for all the stock available in portfolio
         *  Used in StockTable
         * @returns {Promise<void>}
         */
        async portfolioStockPricesUpdate({dispatch, state}){
            let symbols = Object.keys(state.portfolio_deals);
            // todo maybe remove the stocks that were sold and have zero amount at the moment?
            await dispatch( 'getBatchCurrentStockData', symbols);
        },

        /**
         *  Get historic portfolio values
         * @param range
         * @returns {Promise<void>}
         */
        async getPortfolioValue({dispatch, commit, state}, range) {
            range = getChartRangeForApi(range);
            await dispatch('getAllPortfolioStockValues');
            if (state.data_meta.portfolio_deals == 0) return;
            // Checking if something changed else return
            let metaKey = 'portfolio_value.'+range;
            // console.log('portfolio_deals META: ',state.data_meta.portfolio_deals);
            let sameVersion  = ( state.data_meta.portfolio_deals == state.data_meta[metaKey+'.v'] );
            if (sameVersion && !isEmpty(state.portfolio_value['r'+range])) return;

            // Returns cash history, checks if there are no deals
            let money_history = getCashHistory(state.initialCapital, state.portfolio_deals, state.extra_cash, state.stock_prices, range);
            if (money_history) {
                let val = calculatePortfolioHistoricValues(state.stock_value, state.portfolio_deals, range, money_history);
                commit('setMoneyHistory', {money_history, range});
                commit('setPortfolioValue', {val, range});
            }
        },
        async getCurrentPortfolioValue({dispatch, state, commit}) {
            // console.log('get Current P Value');
            if (isEmpty(state.current_stock_prices) ) {
                await dispatch('portfolioStockPricesUpdate');
            }

            let val = (isPortfolioEmpty(state.portfolio_deals)) ? 0 : calculateCurrentPortfolioStockValue(state.current_stock_prices, state.portfolio_deals);

            commit('setCurrentPortfolioValue',{stock:val, total:val+state.money});
            return val;
        },
        async showOnChart({dispatch, commit, state}, symbol){
            // console.log('Show on Chart : ', symbol);
            let range = state.chart_range;

            let prices = {};
            commit('setChartSymbol', symbol);
            commit('setLoadingState', {active:true,message:null});
            if (symbol == 'My Portfolio'){
                await dispatch('getPortfolioValue', range);
                prices = state.portfolio_value;
                prices = addCurrentToHistory(state.portfolio_value, null, state.portfolio_value.current?.total);
            }
            else {
                await dispatch('getStockPrices', {symbol, range});
                // let cDate = new Date().toISOString();
                let stockInfo = state.current_stock_prices[symbol];
                let currentDate, currentPrice;
                if (stockInfo) {
                    let timestamp = stockInfo.closeTime ?? stockInfo.iexCloseTime ?? stockInfo.lastTradeTime;
                    currentDate = new Date(timestamp).toISOString();
                    currentPrice = getClosePrice(stockInfo);
                }
                if (state.chart_info == 'Price' || !state.portfolio_deals[symbol]) {
                    prices = (currentDate) ? addCurrentToHistory(state.stock_prices[symbol], currentDate, currentPrice) : state.stock_prices[symbol];
                }
                else{
                    let amount = getCurrentStockAmount(state.portfolio_deals, symbol);
                    // prices = state.stock_value[symbol];
                    prices = (currentDate) ? addCurrentToHistory(state.stock_value[symbol], currentDate, amount*currentPrice) : state.stock_value[symbol];
                }
            }
            let label = (state.chart_info == 'Price') ? 'Price' : 'Net Worth';
            updateChartPeriod(state.chart_range, window.myChart, prices, symbol, label);
            commit('setLoadingState', {active:false,message:null});
        },
        async setChartRange({dispatch,commit,state}, val){
            commit('setChartRange', val);
        },
        /**
         *  Saves the deal on the server and adds it to the portfolio_deals
         * @param symbol
         * @param deal
         * @returns {Promise<void>}
         */
        async createNewDeal({dispatch,commit,state}, {symbol, deal}){
            let dealData = {
                price :  deal.price,
                amount: deal.amount,
                final_amount: deal.finalAmount,
                type: deal.dealType,
                symbol: symbol,
            };

            let params = {
                'mode':      'addPortfolioDeal',
                'symbol': symbol,
                'deal_data': dealData,
            }

            let data = await dispatch('stockApiRequest',params);
            let dealTime = data.deal_date + 'T' + data.deal_time;
            commit('addDeal', {symbol, deal, dealTime});
            // commit('setStockValues',{symbol, range: state.chart_range});
        },
        /**
         *  Get user portfolio from the server, load all user deals and save them to state.portfolio_deals
         * @param dispatch
         * @param commit
         * @param state
         * @param symbol
         * @param deal
         * @returns {Promise<void>}
         */
        async loadUserPortfolio({dispatch,commit,state}){
            let params = {
                'mode':      'getUserPortfolio',
            }
            let deals = await dispatch('stockApiRequest',params);
            if (!deals) return;
            commit('setUserDeals', deals);
            commit('setMoney', getCurrentCash(deals, state.initialCapital));
        },
        async getTradingTimes({dispatch,commit}){
            let params = {
                'mode':      'isTradingOpen',
            }
            let result = await dispatch('stockApiRequest',params);
            commit('setTradingTimes', result);
        },
        async noInternet({commit}){
            let msg = {
                msg:       `<h2>No connection to the server.</h2>
                Please check your internet connection.
                <br>If the problem is on our side, we fix it as soon as possible.
                <br>Sorry for the inconveniences.`,
                time:      0,
                iconClass: ''
            };

            commit('setInfoMsg', msg);
        },
        async loadPersisted({commit, state}){
            let data = await entries();
            // console.log('LoadPEristed');
            for (let [key,val] of data){
                // console.log(key,val);
                if (val) commit('setPersisted', {key,val});
            }
        },
        async saveOnboarding({dispatch, state}){
            let params = {
                'mode': 'setOnboardingStatus',
                'onboarding_status': {steps: state.onboarding_status, active:state.onboarding_active},
            }

            let data = await dispatch('userApiRequest', params);
            return data;
        },
        async loadOnboarding({dispatch, commit}){
            localStorage.onboarding = false;
            let params = {
                'mode': 'getOnboardingStatus',
            }

            let data = await dispatch('userApiRequest', params);
            commit('setOnboarding', data)
        }
    },
    getters: {
        allDeals(state) {
            return {portfolio_deals: state.portfolio_deals, extra_cash: state.extra_cash};
        },
        noPushNotify(state){
            if (Capacitor.getPlatform() !== 'web') return false;
            let canNotify = ('Notification' in window) && state.firebaseIsSupported;
            return !canNotify;
        }
    },
    modules:   {
        userStore,
        iap,
    },
    // plugins: [vuexLocal.plugin, metaStorage.plugin, symbolsStorage.plugin],
})
