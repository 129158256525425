<template>
    <div class="close_button" >
        <fa-icon :icon="['fas', 'times']"/>
    </div>
</template>

<script>
export default {
    name:    "AppCloseButton",
    props:   {
        target: String
    },
    methods: {
        closeAction() {
            console.log('Clicked close')
        }
    }
}
</script>

<style lang="scss">
.close_button {
    //padding: 5px;
    position: absolute;
    right: 5px;
    top: 5px;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    font-size: 18px;
    //background-color: #941b1b;
    z-index: 200;
    transition: all 0.2s;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: var(--safe-top);

    &.fixed{
        z-index: 100;
    }

    &.sticky{
        position: sticky;
    }

    &:active{
        background-color: #e51d1d;
    }
}

</style>