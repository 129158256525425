<template>
    <div :class="['profile_view__status',current]">
        <div class="status_col1">
            <div :class="[rank, 'status_bar']"></div>
        </div>
        <div class="status_col2"><span :class="rank">{{ status }}</span></div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {avatarPath} from "@/assets/js/helper_functions";

export default {
    name: "UserProfileStatus",
    props: {
        status: String,
        rank: String,
    },
    computed:{
        ...mapGetters(['activity_status']),
        current(){
            let currentRank = 'rank'+this.activity_status.num;
            return (currentRank == this.rank) ? 'current' : '';
        },
    }
}
</script>

<style lang="scss">

</style>