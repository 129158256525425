<template>
    <div class="row"><b>{{ label }}</b> <i>
        <slot>0</slot>
    </i></div>
</template>

<script>
export default {
    name:  "StockDetailsInfo",
    props: {
        label: String,
    }
}
</script>

<style lang="scss">
.stock_info {
    text-align: left;
    padding-top: 10px;
    display: flex;

    >div{
        display: inline-block;
        width: 100%;
        max-width: 50%;
        box-sizing: border-box;
    }

    .row {
        width: 100%;
        display: inline-flex;
        box-sizing: border-box;
        padding: 8px 10px 2px;
        align-items: center;

        @media screen and ( min-width: 720px){
            padding: 15px 30px;
        }

        b {
            display: inline-block;
            min-width: 60px;
            font-size: 0.9rem;
            font-weight: normal;
            color: $base_color_dim;
            flex-grow: 1;
        }

        i {
            display: inline-block;
            text-align: right;
            //width: 100%;
            font-style: normal;
            font-weight: 500;
        }
    }

}
</style>