<template>
    <div class="leaderboard">
        <div class="leaderboard__header">
        <app-close-button @click="$router.replace('/')" class="fixed"/>
        <h1>Leaderboard</h1>
        </div>
        <leader-table/>
    </div>
</template>

<script>
// @ is an alias to /src
import AppMenu from "@/components/AppMenu";
import {mapActions, mapGetters, mapState} from "vuex";
import {userStore} from "@/store/userStore";
import LeaderTable from "@/components/LeaderTable";
import AppCloseButton from "@/components/AppCloseButton";

export default {
    name:       'Leaderboard',
    components: {AppCloseButton, LeaderTable},
    computed:{

      ...mapGetters(['']),

    },
    methods:    {
        ...mapActions(['getLeaderboardData']),
    },
    async mounted() {
        await this.getLeaderboardData();
    }
}
</script>
<style lang="scss">
.leaderboard{
    &__header{
        position: sticky;
        top: 0;
        background-color: $bg_color;
        padding: 10px 0 0 0;
        z-index: 5;
    }

    .toggle_menu{
        position: sticky;
        top: 61px;
    }

}
</style>