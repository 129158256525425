<template>
    <div class="toggle_menu">
        <div class="toggle__options">
            <app-toggle-option
                    v-for="option in options"
                    :key="option"
                    :class="{active:isActive(option)}"
                    @click="toggleAction(option)"
            >{{ option }}
            </app-toggle-option>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import AppToggleOption from "@/components/AppToggleOption";

export default {
    name:       'AppToggle',
    props:      {
        options:      Object,
        toggleAction: Function,
        isActive:     Function,
    },
    components: {
        AppToggleOption,
    }
}
</script>

<style lang="scss">
.toggle_menu {
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    //width: 100%;
    background-color: $bg_color;
    z-index: 45;
    width: 100%;

    .toggle__options {
        //background-color: $bg_color_lighter;
        border-radius: 8px;
        padding: 5px;
        flex-grow: 1;
        display: flex;
        justify-content: center;
    }
}

</style>