<template>
    <div id="stock_table">
        <app-toggle :options="toggleOptions" :toggle-action="toggleAction" :is-active="isToggleActive" class="valueToggle"/>
        <div class="header">
            <div class="stock" @click="setSort('symbol')" >
                <fa-icon v-if="sortBy=='symbol'" :icon="sortIcon"/>
                Stock
            </div>
            <div class="value" @click="setSort(tableMode)" >
                <fa-icon v-if="sortBy==tableMode" :icon="sortIcon"/>
                {{ tableMode }}
            </div>
            <div class="chart">{{ chart_info }}</div>
        </div>
        <div class="body">
            <PortfolioTableRow :item="myPortfolio" @click="$emit('show')" :table-mode="tableMode" v-show="false"/>
            <PortfolioTableRow v-for="item in portfolio_current_stock_prices" :key="item['symbol']" :item="item"
                               @click="showStock()" :table-mode="tableMode"/>
        </div>
    </div>
</template>

<script>
import {
    getClosePrice,
    getCurrentStockAmount,
    getLastObjValue,
    getPortfolioUnrealizedPeriodPL,
    stringCompare
} from "@/assets/js/helper_functions";
import PortfolioTableRow from "@/components/PortfolioTableRow";
import {mapActions, mapMutations, mapState} from "vuex";
import {calculateCurrentPortfolioStockValue} from "@/assets/js/chart_functions";
import AppToggle from "@/components/AppToggle";

export default {
    name: "PortfolioTable",
    props:{
        portfolioValue: Number,
    },
    data(){
        return {
            sortBy: 'default',
            sortDirection: 'asc',
            toggleOptions: {price:'Price',value:'Value', profitloss:'Gain'},
            tableMode: 'Price',
        }
    },
    components: {
        AppToggle,
        PortfolioTableRow,
    },
    methods: {
        ...mapMutations(['setChartInfo','setProfitLoss']),
        ...mapActions(['portfolioStockPricesUpdate']),
        toggleAction(option){
            this.tableMode = option;
            let val = (option != 'Gain') ? option : 'Value'; // If the selected option is
            this.$FirebaseAnalytics.logEvent({name: 'MAIN_metrics_switch'});
            this.setChartInfo(val);
        },
        setSort(sortBy,direction){
            if (this.sortBy == sortBy ) this.toggleSortDirection();
            else this.sortBy = sortBy;
        },
        toggleSortDirection(){
            if (this.sortDirection == 'asc') this.sortDirection = 'desc';
            else this.sortDirection = 'asc';
        },
        isToggleActive(option){
            // console.log(option);
            return this.tableMode == option;
        },
        showStock(){
            this.$FirebaseAnalytics.logEvent({name: 'MAIN_stock_tap'});
            this.$emit('show');
        }
    },
    async mounted() {
        this.tableMode = this.chart_info;
        await this.portfolioStockPricesUpdate();
    },
    computed: {
        ...mapState(['money','chart_symbol', 'portfolio_value', 'portfolio_deals', 'stock_prices', 'current_stock_prices', 'is_loading', 'chart_range', 'chart_info', 'profit_loss']),
        myPortfolio: function () {
            return {
                symbol: 'My Portfolio',
                companyName: '',
                close: this.portfolioValue+this.money,
                amount: 1,
                profitLoss: this.unrealizedRangePL.total,
                value: this.portfolioValue+this.money,
            }
        },
        directionMultiplier(){
            return (this.sortDirection == 'asc') ? 1 : -1;
        },
        sortIcon(){
            return (this.sortDirection == 'asc') ? 'sort-up' : 'sort-down';
        },
        unrealizedRangePL(){
            let period = this.chart_range;
            let dayPL = getPortfolioUnrealizedPeriodPL(this.portfolio_deals, this.current_stock_prices, this.stock_prices, period);
            this.setProfitLoss({val:dayPL, period: 'r'+period});
            return dayPL;
        },
        portfolio_current_stock_prices() {
            // console.log('Get portfolio_current_stock_prices', this.current_stock_prices);
            let result = [];
            let symbols = Object.keys(this.portfolio_deals); // Get all the available symbols in portfolio
            for (let s of symbols) {
                let availableStockAmount = getCurrentStockAmount(this.portfolio_deals, s);
                if (!this.current_stock_prices[s]) return 0; // If we don't have current stock data we return 0
                if (!availableStockAmount) continue; // Skip if the amount is 0;
                let stockData = {...this.current_stock_prices[s]};
                let stockValue = availableStockAmount * getClosePrice(stockData);
                stockData['amount'] = availableStockAmount;
                stockData['value'] = stockValue;
                stockData['profitLoss'] = this.profit_loss['r'+this.chart_range][s];
                result.push(stockData);
            }
            // console.log('result', result);

            let sortBy = this.sortBy;
            let direction = this.directionMultiplier;
            // console.log(direction);

            result.sort(function(a, b) {
                if (sortBy == 'symbol') return stringCompare(a.symbol, b.symbol)*direction;
                if (sortBy == 'Price') {
                    return (getClosePrice(a) - getClosePrice(b))*direction;
                }
                if (sortBy == 'Value') {
                    return (a.value - b.value)*direction;
                }
                if (sortBy == 'Gain') {
                    return (a.profitLoss - b.profitLoss)*direction;
                }
            });
            return result;
        }
    }
}
</script>

<style lang="scss">
#stock_table {
  overscroll-behavior: contain;
  display: flex;
  flex-grow: 1;
  flex-flow: column;
  //background-color: red;
  min-height: 0;

  .header, .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 20px;
    box-sizing: border-box;
      position: relative;

    .chart{
      min-width: 100px;
    }
  }

  .header {
    margin-top: 12px;
    position: sticky;
    //top: 59px;
    top: 100px;
    background-color: $bg_color;
    border-bottom: 2px solid #ccc;
    z-index: 51;
  }

  .body {
    overflow: hidden;
    flex-grow: 1;
    padding-bottom: 50px;
    //background-color: #04b604;
  }
}


.valueToggle {
    //max-width: 200px;
    margin: 5px auto;
    padding-top: 0;
    position: sticky;
    top: 59px;
}
</style>