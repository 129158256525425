<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "CantBuyPlatform",
    methods:{
        ...mapActions(['buyPremium']),
        testPremiumPurchase(){
            let transaction = {
                className:      "Transaction",
                isAcknowledged: true,
                isPending:      false,
                nativePurchase: {
                    accountId:        "",
                    acknowledged:     true,
                    autoRenewing:     true,
                    developerPayload: "",
                    getPurchaseState: 1,
                    orderId:          "TEST-GPA.3319-8471-3785-66147",
                    packageName:      "app.gain4.fun",
                    productId:        "android_gain4fun_pro_subscription",
                    productIds:       ["android_gain4fun_pro_subscription"],
                    profileId:        "",
                    purchaseState:    0,
                    purchaseTime:     1685267852392,
                    purchaseToken:    "hiejbkdebhbaaklagkfghdkb.AO-J1OwYAH6FiAgfoFjM_Qbyu3Bzgjwjj_ZdBFhWeUPK_A1LJsjSJSOp_XfRlPmcFcDPNq5bK7NOlm3BTf26NDlB6oQHxSzLLg",
                    quantity:         1,
                    receipt:          `{
                        "orderId":       "TEST-GPA.3319-8471-3785-66147",
                        "packageName":   "app.gain4.fun",
                        "productId":     "android_gain4fun_pro_subscription",
                        "purchaseTime":  1685267852392,
                        "purchaseState": 0,
                        "purchaseToken": "hiejbkdebhbaaklagkfghdkb.AO-J1OwYAH6FiAgfoFjM_Qbyu3Bzgjwjj_ZdBFhWeUPK_A1LJsjSJSOp_XfRlPmcFcDPNq5bK7NOlm3BTf26NDlB6oQHxSzLLg",
                        "quantity":      1,
                        "autoRenewing":  true,
                        "acknowledged":  true
                    }`,
                    signature:        "URSpEMpO1rwctBBDHyuGSLjXEmeEg0MRtUrYlaauJAckHKGWTxO/YxUc1KU4d/C7KGPAPAfHVr9o/33yDUrPdxs3+VUXT9Hm/97pxEdpNL7Z/kbOUr8xnviyEykh7ahJcCiVTu8Z+ikF+IqpvNArCqh5/aOFI760Sa7JcO06UwfBh93LVds5yzJZltv219by0sxaJc5Q8XIbzvXFPelFpMZXXXlLOXHlMza3Nc63JxREGU+yjlZQzfPBQ6OeNI3qgwX/hBuOae5vLQh0WOG8okBhqPWdL4AIx6yEcLJqa7cn6hFeHonoW1s3XYGTz8CUf/89qmjNrtQeQxnZUUMOzw==",
                },
                platform:       "android-playstore",
                purchaseId:     "hiejbkdebhbaaklagkfghdkb.AO-J1OwYAH6FiAgfoFjM_Qbyu3Bzgjwjj_ZdBFhWeUPK_A1LJsjSJSOp_XfRlPmcFcDPNq5bK7NOlm3BTf26NDlB6oQHxSzLLg",
                renewalIntent:  "Renew",
                state:          "approved",
                transactionId:  "TEST-GPA.3319-8471-3785-66147"
            };
            this.buyPremium({transaction, premiumType:'pro', productType: "paid subscription"});
        }
    },
    computed:{
        ...mapState(['is_local_dev']),
    }
}
</script>

<template>
    <div>
        <h1>Currently it's only possible to buy premiums in Android app</h1>
        <p>The premiums purchased on android will be available on all platforms if you are logged in with the same account.</p>
        <div v-if="is_local_dev" @click="testPremiumPurchase" class="button green">Test Premium Purchase</div>
    </div>
</template>

<style scoped lang="scss">

</style>