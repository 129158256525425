<template>
    <div class="chartContainer">
        <div class="info">
            <div class="title"> {{ getCurrentTitle }}</div>
            <div class="price">${{ financial(getCurrentPrice, true) }}</div>
            <div class="date">{{ date }}</div>
        </div>
        <div class="chart-container">
            <canvas id="stocksChart" :class="{hidden:is_loading}"></canvas>
        </div>
        <AppLoadingMsg v-if="$store.state.is_loading">Chart Data Loading</AppLoadingMsg>
    </div>
</template>

<script>
import * as ch from "@/assets/js/chart_functions.js";
import moment from "moment";
import {financial} from "@/assets/js/filters";
import {mapMutations, mapActions, mapState} from "vuex/dist/vuex.mjs";
import {getClosePrice, getLastObjValue} from "@/assets/js/helper_functions";
import AppLoadingMsg from "@/components/AppLoadingMsg";


export default {
    name:       'Chart',
    components: {AppLoadingMsg},
    props:{
        item: Object,
    },
    data() {
        return {
            date: moment().format("MMM DD, YYYY"),
            dataLoaded: false,
        }
    },
    methods:  {
        ...mapMutations(['setLoadingState']),
        ...mapActions(['showOnChart', 'getPortfolioValue', 'getStockPrices']),
        financial,
        initChart(symbol) {
            // let chartData = ch.extractIntervals(val, 4);
            let chartData = [];
            if (ch.objIsChart(window.myChart)) window.myChart.destroy();
            window.myChart = ch.initChart(chartData, symbol);
        }
    },
    computed: {
        ...mapState(['chart_symbol', 'chart_range', 'portfolio_value', 'stock_prices', 'stock_value', 'current_stock_prices', 'is_loading','chart_info']),
        getCurrentPrice() {
            if (this.chart_symbol == 'My Portfolio') return this.item.value;
            if (this.chart_info=='Price') return this.item.price;
            return this.item.value;
        },
        getCurrentTitle() {
            if (this.chart_symbol == 'My Portfolio') return 'Net Worth';
            return this.chart_symbol;
        },
        dataChage(){
            return [this.chart_range, this.chart_info];
        }
    },
    // components: {
    //
    // }
    async mounted() {
        this.initChart(this.chart_symbol);
        if (!this.portfolio_value.r1mm) {
            this.getPortfolioValue('1m');
        }
        if (!this.portfolio_value.r2y) {
            this.getPortfolioValue('2y');
        }
        // setTimeout(()=>{
        //     this.showOnChart(this.chart_symbol);
        // }, 100);

    },
    watch: {
        dataChage: {
            async handler(newVal, oldVal){
                console.log('handler', oldVal, newVal);
                await this.showOnChart(this.chart_symbol);
            },
            immediate: true,
        },
    }
};

</script>

<style lang="scss">

#stocksChart {
    //background-color: #000;
    width: 100%;

    &.hidden {
        visibility: hidden;
    }
}

.chartContainer {
    min-height: 200px;
    height: 200px;
    max-height: 250px;
    width: 100%;
    max-width: 1000px;
    position: relative;
    background-color: $bg_color_lighter;
    border-radius: 8px;
    overflow: hidden;
    //padding-top: 50px;
    box-sizing: border-box;
    //flex-grow: 1;
    margin: 15px 0;
    @media screen and (min-width: 768px) {
        height: 250px;
        min-height: 250px;
    }

    .info {
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 50;
        text-align: left;

        .price {
            font-weight: 700;
            font-size: 1.2em;
        }

        .title {
            font-size: 0.8em;
        }

        .date {
            font-size: 0.7em;
        }
    }
}

.chart-container {
    width: 100%;
    //min-height: 100%;
    box-sizing: border-box;
    position: relative;
    //background-color: aqua;
    height: 200px;
    @media screen and (min-width: 768px) {
        height: 250px;
    }
}

</style>