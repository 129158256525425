<template>
    <div class="buy_premium_view">
        <div class="header buy_view">
            <app-close-button @click="closeWindow()" class="fixed"/>
            <h1>Buy Premium</h1>
        </div>
        <template v-if="canBuyPlatform">
            <div class="buy_premium_info" v-if="plan=='pro'">
                <div>
                    <b>{{productProTrader?.description ?? 'Become a PRO fallback'}}</b>
                    <ul class="descr">
                        <li>Trade premium stocks</li>
                        <li>Get a PRO avatar</li>
                        <li>Trade outside stock exchange business hours</li>
                    </ul>
                </div>
                <b>$10 / week</b>
            </div>
           <div class="buy_premium_info" v-if="plan=='god'">
                <div>
                    <b>GOD mode</b>
                    <ul class="descr">
                        <li>See Portfolios of Elite Club traders</li>
                        <li>Analyze their trade history</li>
                        <li>Elite Club trading notification settings</li>
                    </ul>
                </div>
                <b>$100 / week</b>
            </div>
            <div class="flex center">
                <buy-button-simple
                        v-if="!user_premium"
                        @click="buyPremiumNow()"
                        btnClass="green gray"
                        :btnDisabled="dealAmount == 0"
                        btn-text="Buy"
                />

                <buy-button-simple
                        v-if="user_premium"
                        @click="manageSubs()"
                        btnClass="yellow gray wider"
                        :btnDisabled="dealAmount == 0"
                        btn-text="Manage Subscription"
                />
            </div>
        </template>
        <cant-buy-platform v-if="canBuyPlatform==false" />
    </div>
</template>

<script>
// @ is an alias to /src
import AppButton from "@/components/AppButton";
import {mapMutations, mapState} from "vuex/dist/vuex.mjs";
import {mapActions, mapGetters} from "vuex";
import {getClosePrice, getLastDeal, moneyRound, onBoarding, upOrDown} from "@/assets/js/helper_functions";
import {financial, capitalize, abbreviateNumber, addDollarSign} from "@/assets/js/filters";
import {popups} from "@/assets/js/popupsData";
import BuyButton from "@/components/BuyButton";
import AppCloseButton from "@/components/AppCloseButton";
import CantBuyPlatform from "@/components/CantBuyPlatform.vue";
import BuyButtonSimple from "@/components/BuyButtonSimple.vue";

export default {
    name:  'BuyPremium',
    data() {
        return {
            ranges:     ['1w', '1m', '3m', '6m', '1y', '2y'],
            dealAmount: 10000,
            zeroAmountAnimation: false,
        }
    },
    components: {
        BuyButtonSimple,
        CantBuyPlatform,
        AppCloseButton,
        BuyButton,
        AppButton,
    },
    methods:    {
        capitalize,
        financial,
        abbreviateNumber,
        addDollarSign,
        ...mapActions(['buyPremium', 'getAllSymbols', 'purchaseProTraderSubscription','manageSubs']),
        ...mapMutations(['addMoney', 'removeMoney','setInfoMsg','setGuideBubble','tutorialDone','nextOnboardingStep']),
        async buyPremiumNow(){
            console.log('Buying premium');
            await this.purchaseProTraderSubscription();
        },
        closeWindow(){
            let url = localStorage.redirect ?? '/';
            localStorage.removeItem('redirect');
            this.$router.replace(url);
        },
    },
    computed:   {
        ...mapState(['is_loading', 'money']),
        ...mapGetters(['productProTrader','canBuyPlatform','user_premium']),
        totalCost() {
            return this.productProTrader?.pricing?.price ?? '$10.00';
        },
        plan(){
            return this.$route.params.plan || 'pro';
        }
    },
    async mounted() {
        console.log('this.productProTrader',this.productProTrader);
        console.log('this.$store.iap.product_pro_trader',this.$store.state.iap.product_pro_trader);
    },
    watch: {
        dealAmount: function () {
            if (this.dealAmount > 0) {
                console.log('dealAmount changed');
            }
        }
    }
}
</script>

<style lang="scss">

.buy_premium_view {
    padding: 20px 15px;
    text-align: left;
    max-width: 460px;
    @media screen and (min-width: 520px){
        margin: auto;
    }


    .header {
        background-color: transparent;
        padding: 5px 5px 5px 80px;
        position: relative;
        font-size: 14px;

        h1 {
            margin: 0;
        }

        .company_name{
            line-height: 100%;
            font-size: 0.9em;
        }
    }

    > div {
        padding: 5px;

        > div {
            display: inline-block;
        }
    }

    label {
        min-width: 80px;
        display: inline-block;
        font-size: 1em;
        height: 24px;
    }

    input {
        min-width: 180px;
        flex-grow: 1;
    }

    .stock_cost {
        font-weight: 600;
        font-size: 1.2em;
    }

    .cash_row{
        position: relative;
        i.button_attention{
            position: relative;
            background-color: $bg_color_lighter;
            border-radius: 5px;
            padding: 0 5px;
            transition: all 0.5s;
        }
    }

    .amount_input {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        button {
            padding: 3px 12px;
            margin: 3px;
            min-width: 40px;
            flex-grow: 0;
            font-size: 1.2em;
            display: flex;
            justify-content: center;
            background-color: $bg_color;
            color: #fff;
            border: none;
            svg{
                display: block;
                padding: 0;
            }

            &.max{
                font-size: 0.8em;
            }

            &:disabled, [disabled] {
                border: none;
                background-color: #555;
                color: #aaa;
            }
        }

        input#stock_amount {
            flex-grow: 4;
            background-color: $bg_color;
            color: #fff;
            border: none;
            max-width: 100px;
            min-width: 0;
            font-size: 1.2em;
            font-weight: bold;
            line-height: 100%;
            height: 44px;
        }
    }
    .flex{
        justify-content: space-between;
        padding: 12px 15px 4px;
        button{
            //max-width: 100px;
        }
        &.center{
            justify-content: center;
        }
    }

    .buy_premium_info {

        background-color: $bg_color;
        color: $base_color_dim;
        font-size: 1em;
        padding: 20px 20px;
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        b {
            display: inline-block;
            color: $base_color;
            margin-right: 10px;
            font-weight: 600;
        }

        //> div {
        //    display: flex;
        //    justify-content: flex-start;
        //    height: 30px;
        //}

        .descr {
            font-size: 0.8em;
            margin-top: -1px;
        }
    }
}



</style>