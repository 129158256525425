export const popups = {
    notEnoughCash: { // 1
        active: true,
        action() {},
        msg: `<img src="/images/adventure/07_storefront.png" alt="Success plane" style="position: relative">
                <span>You don't have enough cash to complete the trade.</span> 
                <br>
                <b>Would you like to buy more cash?</b>
                <br>
                <div class="flex">
                    <button class="button">No, thank you</button>
                    <button class="button" onclick="window.location.hash='/buycash'">Tell me more</button>
                </div>`,
        img: "",
        target: "center",
        // targetAttention: true,
        // targetSelector:  'div.cash_row > i',
        w:               window.innerWidth < 400 ? 300 : 380,
        h:               420,
        // top:             false,
        center:          true,
        delay:           500,
        routeName:       'stockdetails',
        guideStyle: {'z-index':1000, position: 'fixed',  'max-width': '820px', left: '50%', transform: 'translateX(-50%)' },
    },
    onlyProAvatar: { // 1
        active: true,
        action() {},
        msg: `<img src="/images/adventure/success.png" alt="Success plane" style="position: relative">
                <span>This Avatar is available for PRO traders only.</span> 
                <br><br>
                <b>Want to become a PRO?</b>
                <br>                
                <br>
                <div class="flex">
                    <button class="button">No, thank you</button>
                    <button class="button" onclick="window.location.hash='/buypremium'">Tell me more</button>
                </div>`,
        img: "",
        target: "center",
        w:               300,
        h:               420,
        delay:           500,
        routeName:       'avatars',
        guideStyle: {'z-index':1000, position: 'fixed',  'max-width': '820px', left: '50%', transform: 'translateX(-50%)' },
    },
    onlyProSymbol: { // 1
        active: true,
        action() {},
        msg: `<img src="/images/adventure/success.png" alt="Success plane" style="position: relative">
                <span>This symbol is available for PRO traders only.</span>
                <br><br>
                <b>Want to become a PRO?</b>
                <br>
                <div class="flex">
                    <button class="button">No, thank you</button>
                    <button class="button" onclick="window.location.hash='/buypremium'">Tell me more</button>
                </div>`,
        img: "",
        target: "center",
        w:               300,
        h:               420,
        delay:           500,
        routeName:       'avatars',
        guideStyle: {'z-index':1000, position: 'fixed',  'max-width': '820px', left: '50%', transform: 'translateX(-50%)' },
    },
}