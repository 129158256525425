<template>
    <div>
        <transition name="modal">
            <app-modal v-if="showModal" @close="showModal = false"  class="gray">
                <div v-if="deleteUser" class="confirm__container">
                    <h2>Delete User Profile?</h2>
                    <div>
                        All your settings and trading data will be lost.
                    </div>
                    <div class="confirm__actions">
                    <buy-button
                            @buyAction="deleteProfile()"
                            :class="{red:true}"
                            btn-text="Delete"
                    />
                    </div>
                </div>
                <div v-if="resetUser" class="confirm__container">
                    <h2>Reset User Profile?</h2>
                    <div>Your settings and trading data will be lost.</div>
                    <div class="confirm__actions">
                    <buy-button
                            @buyAction="resetProfile()"
                            :class="{red:true}"
                            btn-text="Reset"
                    />
                    </div>
                </div>
            </app-modal>
        </transition>
        <div class="profile_view">
            <app-close-button @click="$router.replace('/')"/>
            <h1>User Profile</h1>
            <user-avatar @click="$router.push('avatars')" />
            <div><b>{{ user_name }}</b> UID {{ user_id }}</div>
            <div>{{ user_email }}</div>
            <div v-if="unverified"  class="text-danger">
                <a href="#" @click.prevent="sendVerifyEmailLink()">Verify your email</a> to be able to restore your account.
                <div v-html="emailSent"></div>
            </div>



            <template v-if="is_logged_in || true">

                <div class="profile_view__status_container">
                    What trader you are currently:
                    <user-profile-status status="guru" rank="rank1"/>
                    <user-profile-status status="master" rank="rank2"/>
                    <user-profile-status status="confident" rank="rank3"/>
                    <user-profile-status status="rising" rank="rank4"/>
                    <user-profile-status status="junior" rank="rank5"/>
                </div>

                <div class="products">
                    <product-card type="extraCash"/>
<!--                    <product-card type="proTrader"/>-->
                </div>

                <div class="profile_view__actions">
                <buy-button-simple @click="openLogoutPage()"
                                   btnClass="yellow small"
                                   btn-text="Logout"
                />
                <buy-button-simple @click="showDeleteProfileModal()"
                                   btnClass="red small"
                                   btn-text="Delete profile"
                />
<!--                <buy-button-simple @click="showResetProfileModal()"-->
<!--                                   btnClass="red small"-->
<!--                                   btn-text="Reset profile"-->
<!--                />-->

                <buy-button-simple @click="startTutorial()"
                                   btnClass="yellow small"
                                   :btn-text="onboarding_status.includes(0) ? 'Continue Tutorial' : 'Restart Tutorial'"
                />
                </div>

                <template v-if="!noPushNotify">
                    <h2>Notifications</h2>
                    <p>Your notification settings</p>
                    <div class="notification__settings">
                        <template v-for="(value,key) in push_options" :key="key">
                            <div class="push_setting">
                            <app-switch :isActive="!!value" :toggleAction="toggleNotificationSetting" :actionParams="{topic:key}"/>
                            {{getPushTitles(key)}}
                            </div>
                        </template>
                    </div>
                </template>
<!--                <h2>History</h2>-->
<!--                <p>Show all user transactions here</p>-->
            </template>
            <template v-else>
                <app-button @click="$router.push('login')">Login</app-button>
            </template>
            <div class="profile_view__footer">
                <router-link to="/page/tos" @click="gaTos()">Terms of Use</router-link>
                <router-link to="/page/privacy" @click="gaPrivacy()">Privacy Policy</router-link>
                <router-link to="/page/support" @click="gaSupport()">Support</router-link>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

import AppModal from "@/components/AppModal";
import {mapActions, mapState, mapMutations, mapGetters} from "vuex";
import {abbreviateNumber, addDollarSign, financial} from "@/assets/js/filters";
import AppButton from "@/components/AppButton";
import UserAvatar from "@/components/UserAvatar";
import AppCloseButton from "@/components/AppCloseButton";
import AppSwitch from "@/components/AppSwitch";
import BuyButtonSimple from "../components/BuyButtonSimple";
import BuyButton from "@/components/BuyButton";
import router from "@/router";
import {getPushTitles, onBoarding} from "@/assets/js/helper_functions";
import UserProfileStatus from "@/components/UserProfileStatus.vue";
import ProductCard from "@/components/ProductCard.vue";

export default {
    name: 'UserProfile',
    data() {
        return {
            showModal: false,
            deleteUser: false,
            resetUser: false,
            emailSent: '',
            unverified: +localStorage.unverified,
        }
    },
    components: {
        UserProfileStatus,
        BuyButton,
        BuyButtonSimple,
        AppCloseButton,
        UserAvatar,
        AppButton,
        AppModal,
        AppSwitch,
        ProductCard,
    },
    methods:    {
        getPushTitles,
        ...mapMutations(['setProfitLoss','resetOnboarding','setChartRange','setChartSymbol','setGuideBubble']),
        ...mapActions(['getPortfolioValue','getFcmToken','resetProfileRequest','deleteProfileRequest','restApiRequest','userApiRequest','saveOnboarding','loadUserData']),
        financial,
        abbreviateNumber,
        addDollarSign,
        showDeleteProfileModal(){
            this.$FirebaseAnalytics.logEvent({name: 'USER_delete'});
            this.showModal = true;
            this.deleteUser = true;
            this.resetUser = false;
        },
        showResetProfileModal(){
            this.$FirebaseAnalytics.logEvent({name: 'USER_reset'});
            this.showModal = true;
            this.deleteUser = false;
            this.resetUser = true;
        },
        gaTos(){
            this.$FirebaseAnalytics.logEvent({name: 'USER_eula'});
        },
        gaPrivacy(){
            this.$FirebaseAnalytics.logEvent({name: 'USER_privacy'});
        },
        gaSupport(){
            this.$FirebaseAnalytics.logEvent({name: 'USER_support'});
        },
        async deleteProfile() {

            let response = await this.deleteProfileRequest();
            console.log('Delete Profile', response);
            if (response.msg == 'Success'){
                localStorage.clear();
                window.location.href='/';
            }
        },
        async resetProfile() {
            let response = await this.resetProfileRequest();
            console.log('Reset Profile', response);
            if (response.msg == 'Success'){
                localStorage.clear();
                window.location.href='/';
            }
        },
        startTutorial(){
            this.resetOnboarding();
            this.saveOnboarding();
            // this.$router.replace('/');
            // let key = this.onboarding_status.findIndex((el)=>!el);
            // let step = this.onboarding[key];
            onBoarding(this);
        },

        openLogoutPage(){
            this.$FirebaseAnalytics.logEvent({name: 'USER_logout'});
            this.$router.push('logout');
        },
        async toggleNotificationSetting(params){
            this.$FirebaseAnalytics.logEvent({name: 'USER_notifications_switch'});
            let response;
            if (params.checked)
            {
                response = await this.getFcmToken({action:'subscribeToFCMtopic', extra: {topic: params.topic}});
            }
            else {
                response = await this.getFcmToken({action:'unsubscribeFromFCMtopic', extra: {topic: params.topic}});
            }
            console.log('toggle notification',params);
            return response;
        },
        async sendVerifyEmailLink() {
            let url = this.rest_api_url + 'pages/authemail/verify_again.php';
            let data = await this.restApiRequest({apiUrl:url, params: {email:this.user_email} });
            if (data.result === 'success') {
                this.emailSent = data.message;
            } else {
                this.emailSent = data.message;
            }
            console.log(data);
        },
        async isVerified(){
            if (+localStorage.unverified) {
                let data = await this.userApiRequest({mode: 'isVerified'});
                console.log(data);
                localStorage.unverified = this.unverified = +!data.verified;
            }
            else{
                this.unverified = +localStorage.unverified;
            }
            return this.unverified;
        }
    },
    computed:   {
        ...mapState(['chart_info', 'chart_symbol', 'chart_range', 'data_meta', 'portfolio_deals', 'money', 'portfolio_value', 'current_stock_prices', 'stock_prices', 'info_msg','rest_api_url','onboarding_status','onboarding','onboarding_active']),
        ...mapGetters(['user_email', 'user_name', 'is_logged_in', 'user_id','push_options','noPushNotify']),
        dealsVersion() {
            return this.data_meta.portfolio_deals;
        },
        portfolioLoaded() {
            return this.dealsVersion > 0;
        },
        portfolioEmpty() {
            let num = Object.keys(this.portfolio_deals).length;
            return num == 0;
        },
    },
    async created(){
        await this.isVerified();
    },
    async mounted() {
        if (!this.chart_range) this.setChartRange('1m');
        if (!this.chart_symbol) this.setChartSymbol('My Portfolio');
        await this.loadUserData();
    }
}
</script>

<style lang="scss">
    .push_setting{
        display: flex;
        align-items: center;
        justify-items: flex-start;
        gap: 20px;
        padding: 10px;
        .switch{
            margin-right: 10px;
        }
    }

    .profile_view {
        &__actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        &__footer {
            display: flex;
            align-items: center;
            padding: 30px 10px;
            gap: 10px;
            justify-content: center;

            a {
                display: block;
                margin: 10px;
            }
        }

        &__status_container{
            margin: 10px;
        }


        &__status{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .status_bar{
                min-width: 60px;
                min-height: 15px;
                width: 60px;
                height: 15px;
            }

            .status_col1{
                display: flex;
                justify-content: flex-end;
                flex-grow: 1;
                flex-basis: 50%;
            }

            .status_col2{
                justify-content: flex-start;
                flex-grow: 1;
                flex-basis: 50%;
                text-align: left;

                span{
                    padding: 0 5px;
                    &.rank5 {
                        border-color: #E94209;
                        background: transparent;
                    }

                    &.rank4 {
                        border-color: #FF7C17;
                        background: transparent;
                    }

                    &.rank3 {
                        border-color: #F1C101;
                        background: transparent;
                    }

                    &.rank2 {
                        border-color: #8ACE01;
                        background: transparent;
                    }

                    &.rank1 {
                        border-color: #00B507;
                        background: transparent;
                    }

                }
            }

            &.current{
                .status_bar{
                    min-width: 100px;
                    min-height: 25px;
                    width: 100px;
                    height: 25px;
                }

                span{
                    border-bottom: 1px solid;
                }

                font-size: 25px;
            }
        }


        .products{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    .notification__settings{
        @media screen and (min-width: 800px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    .confirm{
        &__container{
            margin: 20px 10px;
        }

        &__actions{
            padding: 10px;
        }
    }
</style>