<template>
    <button class='range_button' @click="btnAction()"
            :class="{active:$store.state.chart_range==range}"
    >{{ range }}
    </button>
</template>

<script>
export default {
    name:    "ChartRangeButton",
    props:   ['range'],
    methods: {
        btnAction() {
            this.$store.dispatch('setChartRange', this.range);
            let analyticsEvent = this.$route.name == 'stockdetails' ? 'BUY_period_switch' : 'MAIN_period_switch';
            this.$FirebaseAnalytics.logEvent({name: analyticsEvent});
        }
    }
}
</script>

<style lang="scss">
.range_button {
    padding: 8px;
    font-size: 14px;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
    border: none;
    box-sizing: border-box;
    text-transform: uppercase;
    flex-grow: 1;
    max-width: 36px;
    font-weight: 600;

    &.active {
        font-weight: bold;
        background-color: $highlight_color;
    }
}
</style>