import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import wb from "./registerServiceWorker";
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import {faPlus, faMinus, faTimes, faEnvelope, faSortUp, faSortDown, faCheckCircle, faRedo, faArrowRight, faSearch, faBell} from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faFacebook, faApple } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faTimes, faPlus, faMinus, faEnvelope, faGoogle, faFacebook, faApple, faSortUp, faSortDown, faCheckCircle, faRedo, faArrowRight, faSearch, faBell)

import { initializeApp } from 'firebase/app';
import { getMessaging } from "firebase/messaging";
import {Capacitor} from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

const firebaseConfig = {
    apiKey: "AIzaSyA4R1FqAbBC3vpoksvrZX7OSDbFCwx8G-s",
    authDomain: "gain4fun.firebaseapp.com",
    projectId: "gain4fun",
    storageBucket: "gain4fun.appspot.com",
    messagingSenderId: "626918561342",
    appId: "1:626918561342:web:2971d2c7a5eb6c8f66236f",
    measurementId: "G-GS3CYZJ5CC"
};
const firebaseApp = initializeApp(firebaseConfig);

const app = createApp(App);
app.config.globalProperties.$workbox = wb;
if (!Capacitor.isNativePlatform()) {
    FirebaseAnalytics.initializeFirebase(firebaseConfig);
    app.config.globalProperties.$messaging = store.$messaging = getMessaging(firebaseApp);
}
else{
    // app.config.globalProperties.$iap = store.$iap = InAppPurchase2;
}
app.config.globalProperties.$FirebaseAnalytics = FirebaseAnalytics;
app.use(store).use(router).component('fa-icon', FontAwesomeIcon);
app.mount('#app')
