<template>
    <div class="ios_padding_top">
        <transition name="modal">
            <app-modal v-if="showModal" @close="showModal = false">
                <stock-details></stock-details>
            </app-modal>
        </transition>

        <div class="portfolio_view" v-if="portfolioLoaded">
            <h1>My Portfolio</h1>
            <div class="portfolio_view__header">
                <div>
                    <user-avatar @click="openProfile()"/>
                    <div class="user_name">{{ user_name }} UID {{ user_id }}</div>
                    <app-button @click="$router.push('login')" v-if="isAnonymous">Login</app-button>
                </div>
                <div>
                    <div class="donutCharts" v-if="!isEmpty(portfolio_deals)">
                        <chart-doughnut :size=43 :max="total_users" :val="rank_overall" title="Overall" @click="openLeaderboard('Overall')" :ranking=true />
                        <chart-doughnut class="center_donut" :size=50 :max="total_users" :val="rank_gain" title="Gain" @click="openLeaderboard('Gain')" :ranking=true />
                        <chart-doughnut :size=43 :max="total_users" :val="rank_week" title="Weekly" @click="openLeaderboard('Weekly')" :ranking=true />
                    </div>
                    <div class="trading_time">
                      Trading {{tradingStartsEnds}} in <b>{{ secondsToDhm(timeLeft) }}</b>
                    </div>
                    <buy-button-simple
                            v-if="showTradingOpenNotify"
                            @click="notifyWhenTradingOpens()"
                            btn-class="green small"
                            btn-text="Notify me"
                    />
                    <buy-button-simple
                            v-if="showTradingCloseNotify"
                            @click="notifyWhenTradingCloses()"
                            btn-class="red small wide"
                            btn-text="Notify me 30 min before closure"
                    />
                </div>
            </div>

            <div class="stock_info">
                <div class="border-right">
                    <stock-details-info :label="'Stocks'">{{ addDollarSign(portfolioStockValue) }}</stock-details-info>
                    <stock-details-info :label="'Cash'">{{ addDollarSign(money) }}</stock-details-info>
                </div>
                <div>
                    <stock-details-info :label="'Gain'">{{ addDollarSign(unrealizedPL.total) }}</stock-details-info>
                    <stock-details-info :label="'Daily Gain'">{{
                            addDollarSign(unrealizedDailyPL.total)
                        }}
                    </stock-details-info>
                </div>
            </div>

            <my-stock-info desc="Total Balance" :f-num="'$'+financial(totalBalance)" :pl-percent="unrealizedProfitLossPercent" @click="showPortfolioDetails()" />

            <chart-range :showSearchBtn="true"/>

            <PortfolioTable
                    @show="$router.push('stockdetails')"
                    v-if="!portfolioEmpty"
                    :portfolioValue="portfolioStockValue"/>
            <div v-else class="emptyMsg">You have no stocks yet<br><br> Press + to buy stocks</div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import PortfolioTable from "@/components/PortfolioTable";
import ChartRange from "@/components/ChartRange";
import StockDetails from "@/views/StockDetails";
import AppModal from "@/components/AppModal";
import {mapActions, mapState, mapMutations, mapGetters} from "vuex";
import StockDetailsInfo from "@/components/StockDetailsInfo";
import {
    getPnlPercents,
    getPortfolioUnrealizedPeriodPL,
    getPortfolioUnrealizedPL, isEmpty, isPortfolioEmpty, moneyRound, onBoarding, scrollToBottom, secondsToDhm
} from "@/assets/js/helper_functions";
import {abbreviateNumber, addDollarSign, financial} from "@/assets/js/filters";
import AppButton from "@/components/AppButton";
import UserAvatar from "@/components/UserAvatar";
import MyStockInfo from "@/components/MyStockInfo";
import ChartDoughnut from "@/components/ChartDoughnut";
import BuyButtonSimple from "@/components/BuyButtonSimple";
import {isSupported} from "firebase/messaging";

export default {
    name: 'Portfolio',
    data() {
        return {
            showModal: false,
            timeLeft: 0,
            richedBottom: 0,

        }
    },
    components: {
        ChartDoughnut,
        MyStockInfo,
        UserAvatar,
        AppButton,
        StockDetailsInfo,
        AppModal,
        StockDetails,
        ChartRange,
        PortfolioTable,
        BuyButtonSimple,
    },
    methods: {
        isEmpty,
        financial,
        secondsToDhm,
        abbreviateNumber,
        addDollarSign,
        ...mapMutations(['setProfitLoss', 'setChartRange', 'setChartSymbol', 'setMarketOpen', 'setGuideBubble']),
        ...mapActions(['getTradingTimes', 'getFcmToken']),
        showPortfolioDetails() {
            this.$FirebaseAnalytics.logEvent({name: 'MAIN_balance'});
            this.setChartSymbol('My Portfolio');
            this.$router.push({name: 'portfolio_details'});
        },
        timeToMarket() {
            let t = this.trading_times;
            if (!t) return;
            clearInterval(timerInterval);
            var timerInterval = setInterval(async () => {
                let currentTime = new Date().getTime();
                let closeTime = new Date(t.nextClose).getTime();
                let openTime = new Date(t.nextOpen).getTime();
                // console.log(s,t);
                if (currentTime > closeTime && currentTime > openTime) {
                    await this.getTradingTimes();
                    clearInterval(timerInterval);
                    this.timeToMarket();
                }

                if (t.open) {
                    this.timeLeft = closeTime - currentTime;
                    if (this.timeLeft < 0) this.setMarketOpen(false);
                } else {
                    this.timeLeft = openTime - currentTime;
                    if (this.timeLeft < 0) this.setMarketOpen(true);
                }

                // console.log('Time left', this.timeLeft);
            }, 1000);
        },
        async notifyWhenTradingOpens() {
            await this.getFcmToken({action: 'subscribeToFCMtopic', extra: {topic: 'trading_opening'}});
        },
        async notifyWhenTradingCloses() {
            await this.getFcmToken({action: 'subscribeToFCMtopic', extra: {topic: 'trading_closes'}});
        },
        openProfile() {
            this.$FirebaseAnalytics.logEvent({name: 'MAIN_profile'});
            this.$router.push('profile');
        },
        openLeaderboard(leadersPage){
            this.$FirebaseAnalytics.logEvent({name: 'MAIN_tops_tap'});
            this.$router.push('/leaderboard/'+leadersPage);
        },
    },
    computed:   {
        ...mapState(['chart_symbol', 'chart_range', 'data_meta', 'portfolio_deals', 'money',
                     'portfolio_value', 'current_stock_prices', 'stock_prices', "trading_times",
                     'onboarding_status','onboarding_active','onboarding','firebaseIsSupported','rest_api_url']),
        ...mapGetters(['user_email', 'user_name', 'is_logged_in', 'user_id','rank_week','rank_gain','rank_overall','total_users','push_options','noPushNotify']),
        dealsVersion() {
            return this.data_meta.portfolio_deals;
        },
        portfolioLoaded() {
            return this.dealsVersion > 0;
        },
        portfolioEmpty() {
            return isPortfolioEmpty(this.portfolio_deals);
        },
        portfolioStockValue() {
            return this.portfolio_value.current?.stock;
        },
        isAnonymous() {
            return !this.is_logged_in;
        },
        unrealizedPL() {
            let uPL = getPortfolioUnrealizedPL(this.portfolio_deals, this.current_stock_prices);
            this.setProfitLoss({val: uPL, period: 'all'});
            return uPL;
        },
        unrealizedDailyPL() {
            let period = 'day';
            let dayPL = getPortfolioUnrealizedPeriodPL(this.portfolio_deals, this.current_stock_prices, this.stock_prices, period);
            this.setProfitLoss({val: dayPL, period: period});
            return dayPL;
        },
        totalBalance(){
            return this.portfolioStockValue + this.money;
        },
        unrealizedProfitLossPercent(){
            return moneyRound(getPnlPercents(this.unrealizedPL.total, this.portfolioStockValue));
        },
        tradingStartsEnds(){
            return (this.trading_times?.open) ? 'ends':'starts';
        },
        showTradingCloseNotify(){
            if (this.noPushNotify) return false; // This should hide the notify me button in browsers that do not support PUSH API TODO test in safari
            return this.trading_times?.open && this.push_options.trading_closes==0;
        },
        showTradingOpenNotify(){
            if (this.noPushNotify) return false;
            return !this.trading_times?.open && this.push_options.trading_opening==0;
        },


    },
    async mounted() {
        console.log('REST API URL',this.rest_api_url);
        if (!this.chart_range) this.setChartRange('1m');
        if (!this.chart_symbol) this.setChartSymbol('My Portfolio');
        if (!this.noPushNotify) {
            await this.getFcmToken({action: 'getUserSubscriptionsByToken'});
        }
        await this.getTradingTimes();
        this.timeToMarket();
        onBoarding(this);
        scrollToBottom(this, 'MAIN_scroll');
    }
}
</script>

<style lang="scss">
.portfolio_view {
    //height: 100%;
    //display: flex;
    //flex-flow: column;
    //justify-content: stretch;
    padding-bottom: 100px;
    .trading_time{
        b{
            display: inline-block;
            min-width: 110px;
            text-align: left;
            font-weight: 500;
            //background-color: red;
        }
    }

    &__header {
        .user_name{
            margin-top: 10px;
        }

        @media screen and (min-width: 720px) {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            &>div{
                min-width: 300px;
            }
        }
    }
}

.donutCharts{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 5px;
    .donut{
        margin: 8px;
    }
}

.emptyMsg {
    padding: 50px 10px;
    font-size: 1.4em;
}

.totalBalance{
    font-size: 1.2em;
    font-style: normal;
    text-align: left;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 5px 35px;
    @media screen and (min-width: 768px) {
        max-width: 50%;
    }

    b{
        min-width: 200px;
        display: inline-block;
        flex-grow: 1;
    }
    i{
        font-style: normal;
        text-align: right;
    }
}

.border-right{
    border-right: 1px solid $base_color_dim;
    padding-right: 3px;
}
</style>