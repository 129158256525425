import {Capacitor} from "@capacitor/core";
// import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2';
import {addDollarSign} from "@/assets/js/filters";
import 'cordova-plugin-purchase';
import router from '../router';

async function getPrice(product_id){
    return CdvPurchase.store?.get(product_id)?.pricing?.price;
}

export const iap = {
    state:{
        platform_ids:  {
            Android_PRO_TRADER: 'android_gain4fun_pro_subscription',
            Android_EXTRA_CASH: 'android_gain4fun_buy_cash',
            iOS_PRO_TRADER:     'ios_gain4fun_pro_subscription',
            iOS_EXTRA_CASH:     'ios_gain4fun_buy_cash100k',
            WEB_PRO_TRADER:     'web_gain4fun_pro_subscription',
            WEB_EXTRA_CASH:     'web_gain4fun_buy_cash',
        },
        PRO_TRADER_ID: '',
        EXTRA_CASH_ID: '',
        product_pro_trader: {},
        product_extra_cash: {},
        platform:      'test',
        IAPinitialized: 0,
        transactionPending: false,
    },
    mutations: {
        prepareProducts(state, Platform){
            if (Capacitor.getPlatform() === 'android'){
                state.PRO_TRADER_ID = state.platform_ids.Android_PRO_TRADER;
                state.EXTRA_CASH_ID = state.platform_ids.Android_EXTRA_CASH;
                state.platform = Platform.GOOGLE_PLAY;
            }

            if (Capacitor.getPlatform() === 'ios'){
                state.PRO_TRADER_ID = state.platform_ids.iOS_PRO_TRADER;
                state.EXTRA_CASH_ID = state.platform_ids.iOS_EXTRA_CASH;
                state.platform = Platform.APPLE_APPSTORE;
            }

            if (Capacitor.getPlatform() === 'web'){
                state.PRO_TRADER_ID = state.platform_ids.WEB_PRO_TRADER;
                state.EXTRA_CASH_ID = state.platform_ids.WEB_EXTRA_CASH;
                // state.platform = 'test';
            }
        },
        getUpdatedProds(state){
            if (Capacitor.getPlatform() === 'web') return;
            let trader = CdvPurchase.store.get(state.PRO_TRADER_ID);
            // console.log('trader',trader);
            state.product_pro_trader = trader;
            state.product_extra_cash = CdvPurchase.store.get(state.EXTRA_CASH_ID);
        },
        countIAPinits(state){
          state.IAPinitialized = state.IAPinitialized + 1;
          // console.log('IAPinitialized + 1', state.IAPinitialized);
        },
        updateTransaction(state,{pending}){
            state.transactionPending = pending;
        }

    },
    actions:   {
        async initializeIAP({state, dispatch, rootState, commit}) {
            if (Capacitor.getPlatform() === 'web') return; //We exit if not native //todo implement web IAPs
            const {store, ProductType, Platform} = CdvPurchase;
            commit('prepareProducts', Platform);
            // console.log("Initializing IAP: ", state.PRO_TRADER_ID, state.EXTRA_CASH_ID);
            // console.log(store)
            //IAP
            // store.verbosity = store.DEBUG;
            store.validator = {
                url: 'https://validator.iaptic.com',
                timeout: 30000, // in milliseconds
            }
            // console.log("[IAP] verbosity: " + store.verbosity);
            dispatch('registerProducts');
            await store.initialize([state.platform]);
            await  store.update();
            dispatch('setupListeners');
            // await store.restorePurchases();
            commit('getUpdatedProds');
            commit('countIAPinits');
        },

        registerProducts({state, dispatch, rootState, commit}){
            const {store, ProductType, Platform} = CdvPurchase;
            store.register({
                id: state.EXTRA_CASH_ID,
                type: ProductType.CONSUMABLE,
                platform: state.platform
            });

            store.register({
                id: state.PRO_TRADER_ID,
                type: ProductType.PAID_SUBSCRIPTION,
                platform: state.platform
            });
        },

        setupListeners({state, dispatch, rootState, commit}){
            const {store, ProductType, Platform} = CdvPurchase;

            // General query to all products
            store.when()
                .approved(async (transaction) => {
                    // Handle the product deliverable

                    let result = null;
                    if (transaction.state === 'finished') {
                        // console.log('Finished transaction',transaction);
                        return;
                    }
                    await transaction.verify();
                    console.log('Approved transaction',transaction);
                    for (let product of transaction.products) {
                        if (product.id === state.PRO_TRADER_ID) {
                            //Set Premium to true
                            // console.log('Just purchased premium', product);
                            result = await dispatch('buyPremium', {transaction, premiumType: 'pro', productType: ProductType.PAID_SUBSCRIPTION});
                        }
                        else if (product.id === state.EXTRA_CASH_ID) {
                            // Add extra cash
                            // console.log('Extra cash just purchased and should be added to portfolio', product);
                            result = await dispatch('addExtraCashToUser', {transaction, productType: ProductType.CONSUMABLE});
                        }
                    }

                    if (result) {
                        // await transaction.verify();
                        await transaction.finish();
                        commit('getUpdatedProds');
                    }

                });

            //
            // store.when().verified(receipt => console.log('Verified Receipt',receipt));
            // store.when().unverified(receipt => console.log('UNVERIFIED',receipt));

            // store.when().productUpdated(p=>{
            //     console.log('UPDATED PRODUCT', p);
            //     if(p.id === state.PRO_TRADER){
            //         if(p.owned){
            //             console.log('User owns premium from IAP');
            //         }
            //     }
            // });

            store.when().receiptUpdated(async function(receipt) {
                // console.log('Receipt',receipt);
                const transaction = receipt.lastTransaction();

                if ( transaction.isPending && transaction.state == 'initiated') {
                    // Transaction started but is not finished
                    commit('updateTransaction', {pending:true});
                    setTimeout(()=>{
                        commit('updateTransaction', {pending:false})
                    },150000);
                }
                else if(transaction.isAcknowledged && transaction.state == 'finished'){
                    commit('updateTransaction', {pending:false});
                }

                let product_id = transaction.products[0];

                if (product_id != state.PRO_TRADER_ID) return;

                const product_subscription = store.get(state.PRO_TRADER_ID) || {};

                // console.log('product_subscription', product_subscription);
                let status = 'Please subscribe below';
                if (product_subscription.owned)
                    status = 'Subscribed';
                else if (product_subscription.state === 'approved')
                    status = 'Processing...';
                else{
                    // If no subscription we reload user data to see if PRO status expired on DB

                    await dispatch('loadUserData');
                    commit('getUpdatedProds');
                }

                // console.log('PRO TRADER status:', status);
            });

            // Specific query for one ID
            // store.when(state.PRO_TRADER)
            //     .owned((p) => {
            //         //Set Premium to true if not true and
            //         // Update DB with expiration time
            //         console.log('Checked if user owns premium from IAP');
            //     }).expired((p) => {
            //         console.log('Subscription Expired from IAP');
            //         console.log(p);
            //     }
            // );

        },

        async purchaseExtraCache({state, dispatch, rootState, commit}) {
            return await dispatch('iapCheckout', state.EXTRA_CASH_ID);
        },

        async purchaseProTraderSubscription({state, dispatch, rootState, commit}) {
            return await dispatch('iapCheckout', state.PRO_TRADER_ID);
        },

        async iapCheckout({state},productId) {
            const {store, ProductType, Platform} = CdvPurchase;
            try {
                let product = await store.get(productId);
                product.getOffer().order();
            } catch (err) {
                console.log('Error Ordering ' + JSON.stringify(err));
            }
        },

        manageSubs(){
            // if (Capacitor.getPlatform() === 'web') return;
            const {store, ProductType, Platform} = CdvPurchase;
            store.manageSubscriptions();
        },
        
        // registerHandlersForPurchase({state},productId) {
        //     const {store, ProductType, Platform} = CdvPurchase;
        //     let self = store;
        //     store.when(productId).updated(function (product) {
        //         if (product.loaded && product.valid && product.state === self.APPROVED && product.transaction != null) {
        //             product.finish();
        //         }
        //     });
        //     store.when(productId).registered((product) => {
        //         // alert(` owned ${product.owned}`);
        //     });
        //     store.when(productId).owned((product) => {
        //         // alert(` owned ${product.owned}`);
        //         product.finish();
        //     });
        //     store.when(productId).approved((product) => {
        //         // alert('approved');
        //         product.finish();
        //     });
        //     store.when(productId).refunded((product) => {
        //         // alert('refunded');
        //     });
        //     store.when(productId).expired((product) => {
        //         // alert('expired');
        //     });
        // },

        async addExtraCashToUser({state, dispatch, commit}, {transaction, productType}){
            // console.log('Adding Extra Cash To User Account');
            commit('setLoadingState',{active:true,message:null});
            let data = await dispatch('addCash', {cash:100000, transaction, productType});
            commit('setLoadingState',{active:false,message:null});

            if (data.msg == 'Success') {
                let cost = state.product_extra_cash?.pricing?.price;
                let msgAmount = `<b class="buy">$100 000</b>`;
                let msg = `<h2>Transaction successful.</h2>
                        You’ve purchased ${msgAmount} game cash for <b>${cost}</b>.`;
                commit('setInfoMsg',{msg});
                await dispatch('loadUserPortfolio');
                setTimeout(()=>{
                    let url = localStorage.redirect ?? '/';
                    localStorage.removeItem('redirect');
                    router.replace(url);
                },3000);
                return true;
            }
            else if (data.status == 'applied'){
                console.log(data.msg);
            }
            else{
                let msg = `<h2>Something went wrong!!!</h2>
                        Please contact our support team if you paid but did not receive the game cache.`;
                commit('setInfoMsg',{msg});
                return false;
            }
        },
        async addCash({dispatch,commit,state}, {cash, transaction, productType}){
            let params = {
                'mode':      'addCash',
                'platform': state.platform ?? transaction['platform'],
                'product_id': state.EXTRA_CASH_ID || 'test_gain4fun_buy_cash',
                'product_type': productType,
                'nativePurchase': transaction['nativePurchase'],
                'cash': cash,
            }

            let data = await dispatch('stockApiRequest',params);
            return data;
        },
        async buyPremium({state, dispatch, commit}, {transaction, premiumType, productType}){
            // console.log(transaction);

            let params = {
                'mode': 'buyPremium',
                'platform': state.platform ?? transaction['platform'],
                'product_id': state.PRO_TRADER_ID,
                'product_type': productType,
                'nativePurchase': transaction['nativePurchase'],
                'premium': premiumType,
            }

            commit('setLoadingState',{active:true,message:null});
            let data = await dispatch('userApiRequest', params);
            commit('setLoadingState',{active:false,message:null});
            // console.log('Purchase data', data);

            if (data.msg == 'Success') {
                let msg = `<h2>Transaction successful.</h2>
                        You’ve purchased the ${state.product_pro_trader?.title?.toUpperCase()} subscription.`;
                commit('setInfoMsg',{msg});
                await dispatch('loadUserData');
                setTimeout(()=>{
                    let url = localStorage.redirect ?? '/';
                    localStorage.removeItem('redirect');
                    router.replace(url);
                },3000);
                return true;
            }
            else if (data.status == 'applied'){
                // console.log(data.msg);
                await dispatch('loadUserData');
            }
            else{
                let msg = `<h2>Something went wrong!!!</h2>
                        Please contact our support team if you paid but did not get the subscription.`;
                commit('setInfoMsg',{msg});
                return false;
            }
        },
    },
    getters:   {
        productProTrader(state){
            return state.product_pro_trader;
        },
        productExtraCash(state){
            return state.product_extra_cash;
        },
        canBuyPlatform() {
            let p = Capacitor.getPlatform();
            return p === 'android' || p === 'ios'
        }
    }
}
