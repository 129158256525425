<template>
    <div class="chart_menu">
        <div id="periods">
            <ChartRangeButton
                    v-for="r in ranges"
                    :range="r"
                    :key="r"
            ></ChartRangeButton>
        </div>
        <div>
            <div class="search_stock_button" @click="goToSearch" v-if="showSearchBtn" ref="srcButton">
                <fa-icon icon="plus"/>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import ChartRangeButton from "@/components/ChartRangeButton";
import {mapMutations, mapState} from "vuex";

export default {
    name:  'ChartRange',
    props: {
        showSearchBtn: Boolean,
    },
    data() {
        return {
            ranges: ['1w', '1m', '3m', '6m', '1y', '2y'],
        }
    },
    components: {
        ChartRangeButton
    },
    computed:{
        ...mapState(['tutorial','info_msg','onboarding_active']),
    },
    methods:{
        ...mapMutations(['setGuideBubble','activeGuideBubble','tutorialDone']),
        goToSearch(){
            this.activeGuideBubble(false);
            this.tutorialDone('search');
            this.$FirebaseAnalytics.logEvent({name: "MAIN_buy"});
            this.$router.push('searchstock');
        },
    },
    mounted() {
        if (this.showSearchBtn && !this.onboarding_active && !this.info_msg.msg) {
            this.$refs.srcButton.classList.add("button_attention");
        }
    }
}
</script>

<style lang="scss">
.chart_menu {
    padding: 6px 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: sticky;
    top: -1px;
    background-color: $bg_color;
    z-index: 52;

    #periods {
        background-color: $bg_color_lighter;
        border-radius: 8px;
        padding: 5px;
        flex-grow: 1;
        display: flex;
        justify-content: space-evenly;
    }

    .search_stock_button {
        background: linear-gradient(135deg, #FBB622 0%, #FB9B02 100%);
        min-width: 42px;
        height: 42px;
        margin-left: 10px;
        border-radius: 8px;
        font-size: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &::after {
            content: 'buy';
            display: block;
            position: absolute;
            bottom: -19px;
            left: 0;
            width: 100%;
            transform: translate3d(0, 0, 0);
            height: 20px;
            font-size: 12px;
            text-align: center;

        }

        &.button_attention{
            &::after {
                animation: buy_animation $attention_animation_time ease-in-out infinite;
            }
        }
    }
}

@keyframes buy_animation {
    0%, 50%, 70%, 95%, 100%{ transform: scale(1) translate3d(0, 0, 0);}
    60%, 80% { transform: scale(1.2) translate3d(0, 3px, 0);}
}


</style>