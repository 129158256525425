<template>
    <button class='option_button'>
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "AppToggleOption",
}
</script>

<style lang="scss">
.option_button {
    padding: 8px;
    font-size: 13px;
    background-color: transparent;
    color: #fff;
    border: none;
    box-sizing: border-box;
    flex-grow: 1;
    border-bottom: 1px solid $bg_color_lighter;
    position: relative;
    letter-spacing: 0.04em;
    max-width: 80px;
    min-width: 80px;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -1px;
        left: 0;
        opacity: 0;
    }

    &.active {
        color: $highlight_color;
        border-bottom: 1px solid $highlight_color;
        font-weight: 600;


        &::after{
            transition: opacity 0.5s ease;
            opacity: 1;
            background-color: $highlight_color;
            box-shadow: 0 0 4px 1px $highlight_color;
        }

    }
}
</style>