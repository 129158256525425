<template>
    <div class="loginPage">
        <h1 class="loginPage__title">Sign In</h1>
        <img class="loginPage__image" src="/images/adventure/signin.png" alt="Sign In">
        <div class="loginPage__description">Let's start. Proceed to your account</div>
        <div class="loginButtons">
        <app-button @click="google()" class="btn-google" :icon="['fab','google']">{{ btns['google'].text }}</app-button>
<!--        <app-button @click="facebook()" class="btn-facebook" :icon="['fab','facebook']">{{ btns['facebook'].text }}</app-button>-->
        <app-button @click="email()" class="btn-email" :icon="['fas','envelope']">{{ btns['email'].text }}</app-button>
        <app-button @click="apple()" class="btn-apple" v-if="ios" :icon="['fab','apple']">{{ btns['apple'].text }}</app-button>
        </div>
        <transition name="modal">
            <app-modal v-show="emailLogin" @close="emailLogin=false">
                <email-sign-up  :email-login="emailLogin"></email-sign-up>
            </app-modal>
        </transition>
    </div>
</template>

<script>
// @ is an alias to /src
import {auth} from "@/assets/js/auth";
import AppButton from "@/components/AppButton";
import AppModal from "@/components/AppModal";
import EmailSignUp from "@/components/EmailSignUp";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import {Capacitor} from "@capacitor/core";

export default {
    name:       'Success',
    beforeRouteEnter: (to, from, next) => {
        next(vm => {
            // access any getter/action here via vm.$store
            // avoid importing the store singleton and thus creating hard dependencies
        })
    },
    components: {
        EmailSignUp,
        AppModal,
        AppButton,
    },
    data() {
        return {
            ios: Capacitor.getPlatform() === 'ios',
            emailLogin: false,
            btns:       auth.buttonsCollection,
        }
    },
    methods: {
        ...mapActions(['getCurrentUser','signInRequest']),
        ...mapMutations(['setLoadingState']),
        google:   async function () {
            this.setLoadingState({active:true,message:'Checking credentials...'});
            try {
                const result = await FirebaseAuthentication.signInWithGoogle();
                if (result.user) {
                    console.log('signInWithGoogle');
                    console.log(result);
                }
                let user = await this.getCurrentUser(); // Get current user via FireBase
                this.setLoadingState({active:false,message:null});

                if (user) {
                    let token = await FirebaseAuthentication.getIdToken();
                    if (!token) {
                        console.log('Failed getting the user token');
                        return;
                    }
                    let data = await this.signInRequest(token.token);
                    if (data.result === 'success') {
                        window.location.href = '/';
                    } else {
                        this.loginError = true;
                        this.errorMsg = data.message;
                    }
                } else {
                    console.log('Google login failed :(');
                }
            }
            catch(err){
                console.log(err);
                this.setLoadingState({active:false,message:null});
            }
            // console.log('TOKEN', token.token);

            // console.log('user Data',userData);
            // auth.signIn('google', this.rest_api_url);
        },
        facebook: async function () {
            let userData = await this.signInRequest('Yo ce faci');
            console.log(userData);
            // await FirebaseAuthentication.signInWithFacebook();
            // auth.signIn('facebook');
        },
        email:    function () {
            this.emailLogin = true;
        },
        apple: async function () {
            await FirebaseAuthentication.signInWithApple();
          let user = await this.getCurrentUser();
          if (user) {
            let token = await FirebaseAuthentication.getIdToken();
            if (!token) {
              console.log('Failed getting the user token');
              return;
            }
            let data = await this.signInRequest(token.token);
            if (data.result === 'success') {
              window.location.href = '/';
            } else {
              this.loginError = true;
              this.errorMsg = data.message;
            }
          }
        },
        goHomeIfLoggedIn(){
            if (this.is_logged_in) {
                this.$router.replace('/');
            }
        }
    },
    computed:{
        ...mapState(['rest_api_url']),
      ...mapGetters(['is_logged_in']),
    },
    mounted() {
        console.log(this.is_logged_in);
        this.goHomeIfLoggedIn();

    },
    watch: {
        is_logged_in: async function(){
            this.goHomeIfLoggedIn();
        }
    }
}
</script>

<style lang="scss">
.loginPage {
    box-sizing: border-box;

    &__image{
        max-height: 200px;
    }

    &__description{
        margin-bottom: 5px;
    }

    &__title{
        margin-bottom: 7px;
    }
}
.loginButtons{
    margin: auto;
    //background-color: #de0b0b;
    display: flex;
    flex-flow: column;
    max-width: 300px;
}

.emailLoginFrame {
    margin: 10px 0;
    width: 100%;
    background-color: transparent;
    height: 100%;
    min-height: 550px;
    border: none;
    border-radius: 15px;
}
</style>