<template>
    <div class="leaders">
        <app-toggle :options="toggleOptions" :toggle-action="toggleAction" :is-active="isToggleActive"/>
        <div class="leaders__body">
            <template v-for="(item,index) in leader_data" :key="index">
                <hr class="divider" v-if="item['divider']">
                <leader-table-row v-if="!item['divider']" :item="item" @click="$emit('show')" :table-mode="tableMode"/>
            </template>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import AppToggle from "@/components/AppToggle";
import LeaderTableRow from "@/components/LeaderTableRow";

export default {
    name:  "LeaderTable",
    props: {
        portfolioValue: Number,
    },
    data() {
        return {
            toggleOptions: {
                overall: 'Overall',
                gain: 'Gain',
                weekly: 'Weekly',
            },
            tableMode:     this.$route.params.param,
        }
    },
    components: {
        LeaderTableRow,
        AppToggle,
    },
    methods:    {
        toggleAction(option) {
            this.$router.replace('/leaderboard/' + option);
            // this.tableMode = option;
        },
        isToggleActive(option) {
            return this.tableMode == option;
        },
    },
    computed:   {
        ...mapGetters(['user_id']),
        ...mapState({
            leaderboard: state => state.userStore.leaderboard,
        }),
        leader_data() {
            let result = [];
            if (!this.leaderboard) return result;
            let sortBy = this.tableMode;
            for (let s of this.leaderboard) {
                let userData = {
                    id:    s.user_id,
                    name:  s.name ?? 'Trader' + s.user_id,
                    photo: s.avatar ?? '',
                    own:   s.user_id == this.user_id
                };
                switch (sortBy) {
                    case "Weekly":
                        userData['gain'] = s.gain_week;
                        userData['profitability'] = s.profitability_week;
                        userData['srt'] = s.profitability_week;
                        userData['rank'] = s.week_rank;
                        break;
                    case 'Gain':
                        userData['gain'] = s.gain_year;
                        userData['profitability'] = s.profitability_year;
                        userData['srt'] = s.gain_year;
                        userData['rank'] = s.gain_rank;
                        break;
                    case 'Overall':
                        userData['gain'] = s.gain_year;
                        userData['profitability'] = s.profitability_year;
                        userData['srt'] = s.overall;
                        userData['rank'] = s.overall_rank;
                        break;
                }

                result.push(userData);
            }
            // console.log('result', result);

            result.sort(function (a, b) {
                return (a.rank - b.rank);
            });

            /**
             *  Sorting first by srt parameter then by user id
             *  obsolete since for consistency we sort by ranking
             */
            // result.sort(function (a, b) {
            //     if ((b.srt - a.srt) != 0) return (b.srt - a.srt);
            //     return (a.id - b.id);
            // });

            // Set rankings for already sorted array
            // for (let index in result) {
            //     result[index]['rank'] = +index + 1;
            // }

            let limit = 100;
            let neighbours = 10;
            let sortedLeaders = result.slice(0, limit);
            let pos = result.findIndex(obj => obj.id == this.user_id); // Index position of the current user in the sorted array
            if (pos > limit) {
                let startIndex = Math.max(pos - neighbours, limit); // If position is close to limit we start from the limit end to prevent duplicate rows.
                sortedLeaders = [...sortedLeaders, {divider: true}, ...result.slice(startIndex, pos + neighbours)];
            }
            return sortedLeaders;
        }
    },
    async mounted() {


    },
    watch: {
        $route(to, from) {
            this.tableMode = to.params.param;
        }
    }
}
</script>

<style lang="scss">
.leaders {
    overscroll-behavior: contain;
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    //background-color: red;
    min-height: 0;

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 20px;
        box-sizing: border-box;

        .chart {
            min-width: 100px;
        }
    }


    &__body {
        overflow: hidden;
        flex-grow: 1;
        padding-bottom: 50px;
        //background-color: #04b604;
    }
}

</style>