<template>
    <div class="smallChartContainer">
        <canvas :id="chartId" :class="{hidden:isLoading, c:true}" style="max-height: 60px"></canvas>
        <app-loading-msg  v-if="isLoading">Loading</app-loading-msg>
    </div>
</template>

<script>
import * as ch from "@/assets/js/chart_functions.js";
import moment from "moment";
import {mapMutations, mapActions, mapState} from "vuex/dist/vuex.mjs";
import {getLastObjValue} from "@/assets/js/helper_functions";
import {getChartRangeForApi, initSmallChart, getSmallChartPeriod, objIsChart} from "@/assets/js/chart_functions.js";
import AppLoadingMsg from "@/components/AppLoadingMsg";


export default {
    name:       'ChartSmall',
    components: {AppLoadingMsg},
    props: {
        symbol: String,
    },
    methods:  {
        ...mapActions(['getPortfolioValue', 'getStockPrices']),
        getCtx(){
            return document.getElementById(this.chartId)?.getContext('2d');
        },
        async initChart() {
            if (!this.symbol) return;  // no symbol means we did not get the data from api
            // if (window[this.chartId]) {
            //   this.updateChart();
            //   return;
            // }
            await this.getStockPrices({symbol:this.symbol, range:this.chart_range});

            let cData = ch.getSmallChartPeriod(this.chart_range, this.chartData);
            if (ch.objIsChart(window[this.chartId])) window[this.chartId].destroy();
            window[this.chartId] = ch.initSmallChart(cData, this.getCtx(), this.symbol);
        },
        async updateChart(){
            if (!this.symbol) return; // no symbol means we did not get the data api
            await this.getStockPrices({symbol:this.symbol, range:this.chart_range});
            ch.updateSmallChartData(this.chart_range, this.chartData, window[this.chartId], this.symbol, this.getCtx())
        }
    },
    computed: {
        ...mapState(['chart_range', 'chart_info', 'portfolio_value', 'stock_prices', 'stock_value', 'current_stock_prices']),
        chartId(){
            if (this.symbol == 'My Portfolio') return 'stocksChartTotal';
            return 'stocksChart'+this.symbol;
        },
        chartData(){
            let stockData = (this.chart_info == 'Price') ? this.stock_prices[this.symbol] : this.stock_value[this.symbol];
            return this.symbol=='My Portfolio' ? this.portfolio_value : stockData;
        },
        isLoading(){
            // console.log('is loading chartData: ',this.chartData);
            let apiRange = 'r' + getChartRangeForApi(this.chart_range);
            if (!this.chartData || !this.chartData[apiRange]) return true;
            return false;
        },
        dataChanged(){
            return [this.chart_range, this.chart_info, this.chartData];
        }
    },
    async created() {
        await this.initChart();
    },
    watch: {
        dataChanged: async function(){
            await this.updateChart();
        }
    }

};

</script>

<style lang="scss">


.smallChartContainer {
    min-height: 50px;
    height: 60px;
    max-height: 60px;
    width: 100%;
    max-width: 100px;
    position: relative;
    //background-color: $bg_color_lighter;
    box-sizing: border-box;

    .smallChart {
        //background-color: #000;
        width: 100%;
        height: 100%;
        max-height: 60px;

        &.hidden {
            visibility: hidden;
        }
    }
}



</style>