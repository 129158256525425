import {
    calculateStockPL,
    getClosePrice,
    getFlatDeals,
    getNdaysBackCloseTime, getPreviousClosePrice,
    isEmpty,
    moneyRound
} from "@/assets/js/helper_functions";

export function getStockBook(stockDeals, endDate){
        let totalBuyCost = 0;
        let totalBuyQuantity = 0;
        let deal;
        for (let date in stockDeals){
            if (endDate && date > endDate) break;
            deal = stockDeals[date];

            if (deal.dealType == 'buy'){

                totalBuyQuantity += deal.amount;
                totalBuyCost += deal.amount*deal.price;
            }
            else{
                totalBuyCost = (totalBuyQuantity-deal.amount)*totalBuyCost/totalBuyQuantity;
                totalBuyQuantity -= deal.amount;
            }
        }

        return totalBuyCost;
}

export function getStockAmount(stockDeals, endDate){
    let amount = 0;
    for (let date in stockDeals){
        if (endDate && date > endDate) break;
        let deal =  stockDeals[date];
        amount = deal.finalAmount;
    }
    return amount;
}

export function getStockValue(stockDeals, stockPrice, endDate){
    return getStockAmount(stockDeals, endDate) * stockPrice;
}

/**
 *  Gets the portfolio cash on a specific date;
 * @param allDeals - is an object containing {portfolio_deals, extra_cash}
 * @param initialCash - the initial amount of cash it is usually 100000;
 * @param endDate - the date on wich to finish the calculation of cash;
 * @returns {number}
 */
export function getCurrentCash(allDeals, initialCash ,endDate){
    let money = initialCash || 100000;
    let deals = getFlatDeals(allDeals.portfolio_deals ?? allDeals);
    let dates = Object.keys(deals);
    dates.sort();
    // console.log(deals)
    // console.log(dates)
    for (let d of dates){
        if (endDate && d > endDate) break;
        let deal = deals[d];
        if (deal.dealType == 'buy') money -= deal.price*deal.amount;
        if (deal.dealType == 'sell') money += deal.price*deal.amount;
    }
    return money + getAddedCash(allDeals.extra_cash ?? {} , endDate);
}

/**
 *  Calculate unrealized stock gain
 * @param stockDeals - all deals for this stock
 * @param stockPrice - stock price on endDate
 * @param endDate - date for wich to calculate the unrealized gain
 * @returns {number}
 */
export function getUnrealizedStockGain(stockDeals, stockPrice, endDate){
    let stockValue  = getStockValue(stockDeals, stockPrice, endDate);
    let stockBook = getStockBook(stockDeals, endDate);
    return stockValue - stockBook;
}


export function getTotalStocksValue(allPortfolioDeals, stockPrices, endDate){
    let totalValue = 0;
    for (let symbol in allPortfolioDeals){
        let stockDeals = allPortfolioDeals[symbol];
        let stockPrice = (!endDate)? getClosePrice(stockPrices[symbol]) : getPreviousClosePrice(endDate, stockPrices[symbol]['r2y']);
        // console.log('stockPrices', stockPrices);
        // console.log('stockPrice', stockPrice, endDate);
        let stockValue  = getStockValue(stockDeals, stockPrice, endDate);
        totalValue += stockValue;
    }
    return totalValue;
}


export function getTotalBook(allPortfolioDeals, endDate){
    let totalBook = 0;
    for (let symbol in allPortfolioDeals){
        let stockDeals = allPortfolioDeals[symbol];
        let stockValue  = getStockBook(stockDeals, endDate);
        totalBook += stockValue;
    }
    return totalBook;
}


export function getUnrealizedTotalGain(allPortfolioDeals, stockPrices, endDate){
    let totalSharesValue = getTotalStocksValue(allPortfolioDeals, stockPrices, endDate);
    let totalBook = getTotalBook(allPortfolioDeals, endDate);
    return totalSharesValue - totalBook;
}

export function getAddedCash(extraCashDeals, endDate){
    let extraCash = 0;
    for (let date in extraCashDeals){
        if (endDate && endDate < date) return extraCash;
        let ecDeal = extraCashDeals[date];
        extraCash = extraCash + ecDeal.amount;
    }
    return  extraCash;
}

export function getTotalGain(allDeals, stockPrices, endDate, initialCash){
    let totalSharesValue = getTotalStocksValue(allDeals.portfolio_deals, stockPrices, endDate);
    let cash = getCurrentCash(allDeals, initialCash ,endDate);
    let addedMoney = getAddedCash(allDeals.extra_cash, endDate);
    return (totalSharesValue + cash) - initialCash - addedMoney;
}


export function getTotalRealizedGain(allDeals, stockPrices, endDate, initialCash){
    let totalGain = getTotalGain(allDeals, stockPrices, endDate, initialCash);
    let unrealizedGain = getUnrealizedTotalGain(allDeals.portfolio_deals, stockPrices, endDate);
    return  totalGain - unrealizedGain;
}


export function getGainPeriod(allDeals, stockPrices, startDate, endDate, initialCash){
    let startGain = getTotalGain(allDeals, stockPrices, startDate, initialCash);
    let endGain = getTotalGain(allDeals, stockPrices, endDate, initialCash);
    // console.log(startGain, endGain, endGain - startGain);
    return  endGain - startGain;
}


export function getUnrealizedGainPeriod(allDeals, stockPrices, startDate, endDate){
    let startGain = getUnrealizedTotalGain(allDeals, stockPrices, startDate);
    let endGain = getUnrealizedTotalGain(allDeals, stockPrices, endDate);
    console.log(startGain, endGain, endGain - startGain);
    return  endGain - startGain;
}

