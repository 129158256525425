<template>
    <button :class="['buy_button', btnClass, btnDisabled ? 'disabled': '']"
            @touchstart.prevent="delayedAction()"
            @mousedown="delayedAction()"
            @touchend.prevent="cancelAction()"
            @mouseup="cancelAction()"
            ref="buyBtn"
    >
        <div class="buy_button__margins">
            <span>{{ btnText }}</span>
            <div class="stripe" :class="{'active':active}"><span>{{ btnText }}</span></div>
        </div>
    </button>
</template>

<script>

import {mapMutations, mapState} from "vuex";

export default {
    name:  "BuyButton",
    props: {
        btnClass:    String,
        btnText:     String,
        btnDisabled: Boolean,
    },
    data() {
        return {
            active: false,
            done:   false,
            timer:  null,
        }
    },
    computed: {
        ...mapState(['tutorial']),
        hasIcon() {
            return this.icon && this.icon != [];
        }
    },
    methods:  {
        ...mapMutations(['setGuideBubble']),
        delayedAction() {
            if (this.btnDisabled) {
                console.log('buttonDisabled');
                this.$emit('buttonDisabled');
                return;
            }
            console.log('Started Timeout', this.active, this.timer);
            if (this.active) return;
            let timeDelay = 800;
            this.active = true;
            let action = () => {
                if (this.active) {
                    this.done = true;
                    this.$emit('buyAction');
                }
            }
            this.timer = setTimeout(function () {
                    action();
                }
                , timeDelay);


        },
        cancelAction() {
            if (this.btnDisabled || this.done) return;
            this.active = false;
            clearTimeout(this.timer);
            console.log('Cleared Timeout', this.active, this.timer);
            this.$FirebaseAnalytics.logEvent({name: 'BUY_purchase_tap'});
            if (this.tutorial.buy) {
                if (!this.$refs.buyBtn) return false;
                let t = this.$refs.buyBtn.getBoundingClientRect();
                console.log(t);
                this.setGuideBubble({
                    active: true,
                    target: t,
                    msg:    'Press and hold',
                    center: true,
                    top:    false,
                    w:      260,
                    h:      50
                });
            }
        },
    },
}
</script>

<style lang="scss">

$width: 130px;

.buy_button {
    border: none;
    appearance: none;
    position: relative;
    background-color: $bg_color;
    color: $base_color;
    min-height: 48px;
    min-width: 156px;
    max-width: 156px;
    padding: 8px 12px;
    flex-grow: 1;
    margin: 10px;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box !important;
    //overflow: hidden;
    border: none;
    transition: all 0.5s ease;

    &__margins {
        position: absolute;
        border-radius: 8px;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        box-sizing: border-box !important;
        overflow: hidden;
        left: -1px;
        top: -1px;
        bottom: -1px;
        right: -1px;
    }

    span {
        display: block;
        position: absolute;
        top: 50%;
        left: 78px;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-weight: bold;
    }

    .stripe {
        position: absolute;
        width: 8px;
        left: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        transition: width 0.3s cubic-bezier(.47, 1.64, .41, .8) 0.2s;

        &.active {
            transition: width 0.8s linear;
            width: 100%;
        }
    }

    &.gray{
        background-color: $bg_color_lighter;
    }

    &.red .stripe {
        background-color: $down_color;
        color: $base_color;
    }

    &.green .stripe {
        background-color: $up_color;
        color: $base_color;
    }

    &.disabled {
        background-color: #555;
        color: #aaa;

        .stripe {
            transition: width 0.5s ease;
            width: 0;
        }
    }
}

</style>