<template>
    <Transition name="guide" mode="out-in" appear>
        <app-guide-bubble v-if="!offline && guide_bubble.active" @click="nextOnboardingStep()"/>
    </Transition>
    <Transition name="loading" mode="out-in" appear>
        <AppLoadingAnimation  v-if="is_loading">{{loading_message ?? 'Loading...'}}</AppLoadingAnimation>
    </Transition>
    <exit-tutorial-button @click="finishTutorial()" v-if="showExitTutorialButton" ></exit-tutorial-button>
    <transition name="info">
        <app-info-window v-if="info_msg.msg != null" :timeout="info_msg.time" :iconClass="info_msg.iconClass">
            <div v-html="info_msg.msg"></div>
        </app-info-window>
    </transition>
    <transition name="fade">
        <div v-if="appNotLoaded" class="app_logo">
            <img src="~@/assets/logo.png" alt="App Logo">
            <AppLoadingAnimation class="transparent big">{{'Loading...'}}</AppLoadingAnimation>
        </div>
    </transition>
    <router-view v-if="!offline" v-slot="{ Component, route }">
        <!-- Use any custom transition and fallback to `fade` -->
        <transition :name="route.meta.transition || 'fade'" >
            <component :is="Component" class="view_container" v-if="!appNotLoaded"/>
        </transition>
    </router-view>
    <app-menu v-if="onboarding_status[15] || !onboarding_active" />
</template>


<script>
// @ is an alias to /src
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import AppMenu from "@/components/AppMenu";
import AppInfoWindow from "@/components/AppInfoWindow";
import AppGuideBubble from "@/components/AppGuideBubble";
import {Capacitor} from "@capacitor/core";
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications'
import { getToken, isSupported } from "firebase/messaging";
import {hasAvatar, hasUsername, isPwa, onBoarding} from "@/assets/js/helper_functions";
import AppButton from "@/components/AppButton";
import AppLoadingAnimation from "@/components/AppLoadingAnimation";
import ExitTutorialButton from "@/components/ExitTutorialButton";
// const messaging = getMessaging(this.firebase);
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';


export default {
    name:       'Gain4Fun',
    components: {
        AppButton,
        AppGuideBubble,
        AppInfoWindow,
        AppMenu,
        AppLoadingAnimation,
        ExitTutorialButton,
    },
    data(){
      return {
          appNotLoaded: true,
          onboardingLoaded: false,
          offline: false,
          // messagerul: getMessaging(this.firebaseApp)
          // fcm: new FCM(),
      }
    },
    computed:{
        ...mapState(['info_msg','guide_bubble','data_meta','persist', 'onboarding_status', 'onboarding','onboarding_active','is_loading','loading_message']),
      ...mapGetters(['is_logged_in']),
        dealsVersion() {
            return this.data_meta.portfolio_deals;
        },
        showExitTutorialButton(){
            return !this.offline && this.guide_bubble.active && localStorage.onboarding=='active'
        }
    },
    methods:{
      ...mapMutations(['setInfoMsg','setGuideBubble','activeGuideBubble','tutorialDone','nextOnboardingStep','exitOnboarding','setFirebaseIsSupported']),
      ...mapActions(['getAllSymbols', 'loadUserData', 'loadUserPortfolio',
                     'getPortfolioValue','getCurrentPortfolioValue','noInternet','loadPersisted',
                     'getFcmToken','saveOnboarding','loadOnboarding','getTradingTimes','initializeIAP']),
        noInternetMsg(){
            window.ononline = (event) => {
                this.offline = false;
                this.setInfoMsg({msg:null});
                window.location.reload();
            };

            window.onoffline = (event) => {
                this.offline = true;
                this.noInternet();
            };
        },
        finishTutorial(){
            this.exitOnboarding();
            this.activeGuideBubble(false);
            localStorage.onboarding = false;
            this.saveOnboarding();
        },
        async checkInstalledApp(){
            if (Capacitor.getPlatform() !== 'web' || isPwa()) return;
            //check if browser version supports the api
            if ('getInstalledRelatedApps' in window.navigator === false) return;
            const relatedApps = await navigator.getInstalledRelatedApps();
            let infoMsg = {
                msg:       `You can use the installed app: <br><br><b><a href="https://gain4.fun" target="_blank">Gain 4 Fun</a></b>`,
                time:      25000,
                iconClass: ''
            }

            if (relatedApps.length > 0){
                this.setInfoMsg(infoMsg);
            }
        },
      async requestAttPermission() {
        if (Capacitor.getPlatform() !== 'ios') return null;
        let attStatus = await this.getAttStatus();
        if (!attStatus) return null;
        if (attStatus.status != 'notDetermined'){
            return attStatus;
        }

        const response = await AppTrackingTransparency.requestPermission();
        console.log(response);
        // { status: 'authorized' } for example
        return response;
      },
      async getAttStatus(){
        if (Capacitor.getPlatform() !== 'ios') return null;
        const response = await AppTrackingTransparency.getStatus();

        console.log(response);
        // { status: 'authorized' } for example

        return response;
      }

    },
    async created() {
        document.title = "Gain4fun";
        console.log('App started');
        this.noInternetMsg();
    },
    async mounted(){
        // console.log('Firebase messaging is supported', await isSupported());
        this.checkInstalledApp();
        this.setFirebaseIsSupported(await isSupported());
        let attStatus = await this.requestAttPermission();
        await this.loadPersisted();
        await this.loadUserData(); // Gets user authentication data like token and UID
        if (this.is_logged_in) {
            await this.loadOnboarding();
            this.onboardingLoaded = true;

            await this.loadUserPortfolio(); // Loads user portfolio from server
            await this.getAllSymbols();
            await this.getCurrentPortfolioValue();
            this.appNotLoaded = false;
            await this.getTradingTimes();

            document.addEventListener("deviceready", this.initializeIAP, false);
        }
        else{
            this.appNotLoaded = false;
        }
    },
    watch: {
        dealsVersion: {
            async handler(newVal, oldVal) {
                // console.log(newVal, oldVal);
                await this.getPortfolioValue('1m');
                await this.getPortfolioValue('2y');
                await this.getCurrentPortfolioValue();
            },
        },
        onboarding_status: {
            handler(newVal, oldVal) {
                // console.log('watch onboarding', this.onboardingLoaded);
                if (!this.onboardingLoaded) return;
                onBoarding(this);
                this.saveOnboarding();
            },
            deep: true,
        },
        // $route (to, from){
        //     this.onBoarding();
        // }
    }
}


</script>

<style lang="scss">
@import "~@/scss/_fonts.scss";

html {
  --safe-top: env(safe-area-inset-top);
  --safe-bottom: env(safe-area-inset-bottom);
  --safe-left: env(safe-area-inset-left);
  --safe-right: env(safe-area-inset-right);

    height: 100%;
    overscroll-behavior-y: contain;
    @media screen and (min-width: 768px) {
        overflow-y: scroll;
    }
}

.ios_padding_top{
  padding-top: env(safe-area-inset-top);
}

.ios_padding_bottom{
  padding-bottom: env(safe-area-inset-bottom);
}

body {
    background-color: $bg_color;
    box-sizing: border-box;
    margin: 0;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: stretch;
    height: 100%;
    touch-action: manipulation;
    font-family: Poppins, Roboto, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media screen and (min-width: 768px) {
        background-color: #1c1e2e;
    }
    overscroll-behavior-y: contain;
}

h1 {
    font-size: 1.75em;
    font-weight: 600;
}

canvas, div, button, span, p, h1, h2, h3, h4, b, i {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}

#app {

    text-align: center;
    color: #fff;
    background-color: $bg_color;
    width: 100%;
    min-height: 100%;
    max-width: 100%;
    box-sizing: border-box;
    position: relative;

    a{
        text-decoration: none;
        color: #fff;
    }

    .app_logo{
        width: 100%;
        max-width: 50%;
        padding-bottom: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        box-sizing: border-box;

        img{
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            animation: opacity_animate_inverse 3.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        }
    }

    @media screen and (min-width: 768px) {
        margin: auto;
        max-width: 820px;
    }


    .view_container{
        padding: 0 10px 100px 10px;
        width: 100%;
        min-height: 100%;
        max-width: 100%;
        box-sizing: border-box;
        background-color: $bg_color;
        //transform: translate3d(10px, 0 ,0);
        position: absolute;
        top: 0;
        left: 0;
        padding-top: env(safe-area-inset-top);

        @media screen and (min-width: 768px) {
            padding: 40px 40px 80px 40px;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
  position: absolute;
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: opacity 0.6s ease 0.2s, transform 0.8s ease;
}

.slideUp-enter-from{
  opacity: 0;
  transform: translateY(40%);
  position: absolute;
}

.slideUp-leave-to {
  opacity: 0;
  transform: translateY(-40%);
  position: absolute;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}




.button_attention{
    &.search_stock_button {
        background-color: $highlight_color;
    }

    animation: glowing2 $attention_animation_time linear infinite;
    border: 1px solid #fff7;
    svg{
        filter: drop-shadow(0px 0 7px rgba(255, 255, 255, 0.4));
    }

    &:before {
        content: '';
        background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #fba812, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        position: absolute;
        top: -3px;
        left:-3px;
        background-size: 400%;
        z-index: -1;
        filter: blur(10px);
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        animation: gradient_glowing $attention_animation_time linear infinite;
        //animation: gradient_opacity 30s linear infinite;
        opacity: 1;
        transition: opacity .4s ease-in-out;
        border-radius: 10px;
        transform: translate3d(0,0,0);
    }
}


.guide-enter-active{
    transition: opacity 0.2s ease;
    .guide__popup {
        transition: opacity 0.3s 0.2s, transform 0.5s cubic-bezier(0.2, 1.4, 0.5, 1) 0.2s;
        //animation: bubble-bounce 1s infinite;
    }
    .guide__circle{
        transition: all 0.1s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }
}
.guide-leave-active {
    transition: opacity 0.2s ease;

    .guide__popup {
        transition: opacity 0.2s ease 0.3s, transform 0.5s cubic-bezier(.47,1.64,.8,.8);
        //animation: bubble-bounce 1s infinite;
    }
    .guide__circle{
        transition: all 0.1s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }
}


.guide-enter-from,
.guide-leave-to {
    opacity: 0;
    //z-index: 500;
    //transform: translate3d(0,0,0);
    .guide__popup {
        transform: scale(3, 0.001) !important;
        opacity: 0;
        //transition: opacity 0.1s ease 0.2s, transform 0.2s cubic-bezier(.47,1.64,.41,.8) 0.2s;
    }

    .guide__circle{
        opacity: 0;
        transform: scale(0.1,0.1);
        //transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }
}

.guide-leave-from,
.guide-enter-to {
    //z-index: auto;
    .guide__popup {
        //transform: translate(-50%, -50%);
        //animation: bubble-bounce 2s ease;
    }
}

@keyframes bubble-bounce {
    0%   { transform: scale(2,0.1)     }
    10%  { transform: scale(0.1, 3) }
    30%  { transform: scale(1.5,0.4)}
    50%  { transform: scale(0.9,1.5) }
    57%  { transform: scale(1.1,0.9)}
    64%  { transform: scale(0.95,1.1)}
    100% { transform: scale(1,1)}
}

@keyframes opacity_animate_inverse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.1;
    }
    100%{
        opacity: 1;
    }
}


</style>
