<template>
    <div class="logoutPage">
        <h1>Signing Out</h1>
    </div>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name:       'LogoutPage',
    components: {
    },
    data() {
        return {
            ios: false,
            emailLogin: false,
        }
    },
    methods: {
        ...mapActions(['signOutRequest','loadUserPortfolio']),
        ...mapMutations(['resetPortfolioData']),
        goHome(){
            if (!this.is_logged_in) {
                this.$router.replace('/');
            }
        }
    },
    computed:{
      ...mapGetters(['is_logged_in']),
    },
    async mounted() {
        console.log(this.is_logged_in);
        await this.signOutRequest();
        this.resetPortfolioData();
        localStorage.clear();
        window.location.href = '/';
    },
    watch: {
        is_logged_in: async function(){
            this.goHome();
        }
    }
}
</script>

<style lang="scss">
.loginPage {
    box-sizing: border-box;
}

.emailLoginFrame {
    margin: 10px 0;
    width: 100%;
    background-color: transparent;
    height: 100%;
    min-height: 550px;
    border: none;
    border-radius: 15px;
}
</style>