<template>
    <div class="username">
        <h1>Choose Username</h1>
        <div>
            <input type="text" v-model="userName">
        </div>
        <buy-button-simple @click="saveUserName" btnText="Accept"/>
    </div>
</template>

<script>
import router from "@/router";
import BuyButtonSimple from "@/components/BuyButtonSimple";
import {mapActions, mapGetters} from "vuex";

export default {
    name:       "UserName",
    components: {BuyButtonSimple},
    data() {
        return {
            userName: null,
        }
    },
    computed: {
        ...mapGetters(['user_name']),
    },
    methods:  {
        ...mapActions(['updateUsername']),
        saveUserName() {
            localStorage.hasUsername = true;
            this.updateUsername(this.userName);
            this.$router.push('/');
        },
    },
    mounted() {
        this.userName = this.user_name;
    }
}
</script>

<style scoped>

</style>